import React from "react";
import {Row, Col, Typography, Divider, Switch, Select, DatePicker, Space} from 'antd';
import Header from "./inc/Header";
import FIl from "./inc/FIl"
import './css/step.css'
import Recap from "./inc/Recap";
import {StickyContainer, Sticky} from 'react-sticky';
import Questions from "./questions/Questions";
import DocsInscr from "./documents/DocsInscr";
import Accompagnateur from "./questions/Accompagnateur";
import axios from 'axios';
import {API_URI} from "../../constant/Config";
import {FooterApps} from "./inc/FooterApps";

const {Title, Text} = Typography;
const {Option} = Select;

class Step2 extends React.Component {

    constructor(props) {
        super(props);

        this.handleStep = this.handleStep.bind(this);

        this.state = {
            paramHeader: {},
            stepValue : false,
        }
    }

    async componentDidMount() {
        let getJson = await this.getFirstQuestion();
        var count = Object.keys(getJson.data).length;

        //let totalQuest = await JSON.parse(getJson.data);
        //alert(totalQuest)
    }

    handleStep(stepValue) {
        this.setState({stepValue: stepValue});
    }

    getFirstQuestion = () => {
        let data = axios.get(API_URI + '/api_member/liste_questions_first');
        return data
    }

    render() {

        return (
            <div>
                <Header paramHeader={this.state.paramHeader}/>
                <StickyContainer>
                    <Row>
                        <Col lg={6} id="stepScreen" className="contentStep">

                            <div className="sideBar">
                                <Sticky>
                                    {({
                                          style,
                                          isSticky,
                                          wasSticky,
                                          distanceFromTop,
                                          distanceFromBottom,
                                          calculatedHeight
                                      }) => (
                                        <div style={style}>
                                            <FIl step={1}/>
                                        </div>
                                    )}
                                </Sticky>
                            </div>

                        </Col>
                        <Col md={24} lg={12} className="contentStep">

                            <div className="centerTxt">
                                <Title level={2}>Etape 2/3</Title>
                                <Text type="secondary">Nous avons besoin de votre connaitre un peu mieux</Text>
                            </div>
                            <Divider/>

                            <Questions changeStepSend={this.state.stepValue} />

                            <Divider dashed/>

                            <DocsInscr changeStepSend={this.state.stepValue} />

                            <Divider dashed/>

                            <Accompagnateur />

                            <Divider dashed/>
                            <FooterApps />

                        </Col>
                        <Col lg={6} id="RecapScreen" className="contentStep">
                            <div className="sideBar">
                                <Sticky>
                                    {({
                                          style,
                                          isSticky,
                                          wasSticky,
                                          distanceFromTop,
                                          distanceFromBottom,
                                          calculatedHeight
                                      }) => (
                                        <div style={style}>
                                            <Recap
                                                construct={false}
                                                prev="/order/step1"
                                                next="/order/check"
                                                valStep="2"
                                                authNextStep ={false}
                                                stepChange = {this.handleStep}
                                            />
                                        </div>
                                    )}
                                </Sticky>

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col id="RecapScreenMobile" span={24} className="center">
                            <Recap
                                construct={false}
                                prev="/order/step1"
                                next="/order/check"
                                valStep="2"
                                authNextStep ={false}
                            />
                        </Col>
                    </Row>
                </StickyContainer>
            </div>
        )
    }

}

export default Step2