import React from 'react';
import MenuDashboard from "./Menu";
import HeaderMember from "./HeaderMember";
import "./css/dashboard.css";
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fr';
import {API_URI} from "../../constant/Config"

import logoGLob from "./../../img/logoglob.png"
import {
    UserOutlined,
    SettingFilled,
    SmileOutlined,
    SyncOutlined,
    LoadingOutlined,
    SolutionOutlined,
    RadiusUprightOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';

import {
    Form,
    Input,
    Button,
    Checkbox,
    Row,
    Col,
    Typography,
    Divider,
    Steps,
    Select,
    DatePicker,
    Space,
    Layout,
    notification,
} from 'antd';
import {FooterApps} from "../order/inc/FooterApps";


const {Title, Text, Link} = Typography;
const {Step} = Steps;
const layout = {
    labelCol: {span: 5},
    wrapperCol: {span: 19},
};
const tailLayout = {
    wrapperCol: {offset: 5, span: 19},
};
const {Option} = Select;


export default class Profil extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dataMembre: false,
            passeInsert: false,
        }
    }

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    componentDidMount = async () => {

        var token = localStorage.getItem('membre_token');
        await axios.get(API_URI + '/api_member/data_member', {
            headers: {
                'Authorization': token
            }
        })
            .then(res => {
                this.setState({dataMembre: res.data.data_membre});
            })
    }

    onFinish = async (value) => {
        var token = localStorage.getItem('membre_token');
        const res = await axios.post(API_URI + '/api_member/update_member', value, {
            headers: {
                'Authorization': token
            }
        }).then(res => {
            if (res.data.response === 'no') {
                notification.open({
                    message: res.data.title,
                    description: res.data.msg,
                    icon: <ExclamationCircleOutlined style={{color: '#108ee9'}}/>,
                });
            } else {
                notification.open({
                    message: res.data.title,
                    description: res.data.msg,
                    icon: <SmileOutlined style={{color: '#108ee9'}}/>,
                });
            }
        });


    }

    selectDate = async (e, dateString) => {
        console.log(dateString)
        this.setState({
            age: e,
        })
    }

    testPassword = (password) => {
        this.setState({passeInsert: true});
        var passValue = password.target.value;

        if(passValue.length >= 8) {
            return true;
        }else{
            return false;
        }
    }

    render() {

        if (this.state.dataMembre) {
            //console.log(this.state.dataMembre.ageString)
            return (
                <Layout>
                    <HeaderMember/>
                    <Layout>
                        <MenuDashboard/>
                        <Layout>
                            <div className="contentBox">
                                <Title>Vos informations</Title>
                                <Text>Vous pouvez modifier certaines informations vous concernant depuis cette page. Si
                                    vous souhaitez apporter une modification aux informations non modifiables, merci de
                                    nous contacter par mail. </Text>
                                <Divider/>
                                <Form
                                    {...layout}
                                    name="basic"
                                    initialValues={{remember: true}}
                                    onFinish={this.onFinish}
                                    onFinishFailed={this.onFinishFailed}
                                    className="formIdent"
                                >

                                    <Form.Item
                                        label="Civilité"
                                        name="cv"
                                        rules={[{required: true, message: 'Please input your username!'}]}
                                        initialValue={this.state.dataMembre.cv}
                                    >
                                        <Select style={{width: 120}}>
                                            <option value="mr" selected="selected">Monsieur</option>
                                            <option value="mme">Mme.</option>
                                            <option value="mr">M.</option>
                                        </Select>

                                    </Form.Item>

                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[{type: 'email', required: true, message: 'Information manquante'}]}
                                        initialValue={this.state.dataMembre.email}
                                    >
                                        <Input disabled/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Nom"
                                        name="nom"
                                        rules={[{required: true, message: 'Information manquante'}]}
                                        initialValue={this.state.dataMembre.nom}
                                    >
                                        <Input disabled/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Prénom"
                                        name="prenom"
                                        rules={[{required: true, message: 'Information manquante'}]}
                                        initialValue={this.state.dataMembre.prenom}
                                    >
                                        <Input disabled/>
                                    </Form.Item>

                                    <Form.Item
                                        name="password"
                                        label="Mot de passe"

                                        rules={[
                                            {
                                                required: this.state.passeInsert,
                                                message: 'Merci de renseigner votre mot de passe.',
                                            },
                                            (this.state.passeInsert?({getFieldValue}) => ({
                                                validator(_, value) {
                                                    if (value.length > 7) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject(new Error('Votre mot de passe doit contenir au minimum 8 caractères.'));
                                                },
                                            }):false)
                                        ]}
                                        hasFeedback
                                    >
                                        <Input.Password onChange={(e) => {
                                            this.testPassword(e)
                                        }}/>
                                    </Form.Item>

                                    <Form.Item
                                        name="confirm"
                                        label="Confirmer le mot de passe"
                                        dependencies={['password']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: this.state.passeInsert,
                                                message: 'Merci de confirmer votre mot de passe.',
                                            },
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject(new Error('Merci de renseigner le même mot de passe en confirmation.'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Date de naissance"
                                        name="age"
                                        rules={[{required: true, message: 'Information manquante'}]}
                                        //initialValue={(this.state.dataMembre.ageString) ? moment(this.state.dataMembre.ageString, 'YYYY-MM-DD') : false}
                                        //initialValue={(this.state.dataMembre.ageString) ? moment(this.state.dataMembre.ageString+'T01:00:00', 'YYYY-MM-DDTHH:mm:ss') : false}
                                        initialValue={moment.unix(this.state.dataMembre.ageTs)}
                                    >
                                        <DatePicker
                                            format={'DD/MM/YYYY'}
                                            style={{width: "100%"}}

                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Nationalité"
                                        name="nation"
                                        rules={[{required: true, message: 'Information manquante'}]}
                                        initialValue={this.state.dataMembre.nation}
                                    >
                                        <Select style={{width: "100%"}}>
                                            <option value="Afghanistan">Afghanistan</option>
                                            <option value="Afrique du Sud">Afrique du Sud</option>
                                            <option value="Aland (Îles)">Aland (Îles)</option>
                                            <option value="Albanie">Albanie</option>
                                            <option value="Algérie">Algérie</option>
                                            <option value="Allemagne">Allemagne</option>
                                            <option value="Andorre">Andorre</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antarctique">Antarctique</option>
                                            <option value="Antigua et Barbuda">Antigua et Barbuda</option>
                                            <option value="Antilles néerlandaises">Antilles néerlandaises</option>
                                            <option value="Arabie Saoudite">Arabie Saoudite</option>
                                            <option value="Argentine">Argentine</option>
                                            <option value="Arménie">Arménie</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australie">Australie</option>
                                            <option value="Autriche">Autriche</option>
                                            <option value="Azerbaïdjan">Azerbaïdjan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahreïn">Bahreïn</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbade">Barbade</option>
                                            <option value="Belgique">Belgique</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Bénin">Bénin</option>
                                            <option value="Bermudes">Bermudes</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Biélorussie">Biélorussie</option>
                                            <option value="Bolivie">Bolivie</option>
                                            <option value="Bosnie-Herzégovine">Bosnie-Herzégovine</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Bouvet (Île)">Bouvet (Île)</option>
                                            <option value="Brésil">Brésil</option>
                                            <option value="Brunei">Brunei</option>
                                            <option value="Bulgarie">Bulgarie</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Caïmans (Îles)">Caïmans (Îles)</option>
                                            <option value="Cambodge">Cambodge</option>
                                            <option value="Cameroun">Cameroun</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Cap Vert">Cap Vert</option>
                                            <option value="Chili">Chili</option>
                                            <option value="Chine">Chine</option>
                                            <option value="Christmas (Îles)">Christmas (Îles)</option>
                                            <option value="Chypre">Chypre</option>
                                            <option value="Cocos">Cocos</option>
                                            <option value="Colombie">Colombie</option>
                                            <option value="Comores">Comores</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Congo, République démocratique du (Congo)">Congo, République démocratique du (Congo)</option>
                                            <option value="Cook (Îles)">Cook (Îles)</option>
                                            <option value="Corée du Nord">Corée du Nord</option>
                                            <option value="Corée du Sud">Corée du Sud</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                                            <option value="Croatie">Croatie</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Danemark">Danemark</option>
                                            <option value="Dépendances américaines du Pacifique">Dépendances américaines du Pacifique</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominique">Dominique</option>
                                            <option value="Égypte">Égypte</option>
                                            <option value="Émirats Arabes Unis">Émirats Arabes Unis</option>
                                            <option value="Équateur">Équateur</option>
                                            <option value="Érythrée">Érythrée</option>
                                            <option value="Espagne">Espagne</option>
                                            <option value="Estonie">Estonie</option>
                                            <option value="États-Unis">États-Unis</option>
                                            <option value="Éthiopie">Éthiopie</option>
                                            <option value="Faroe (Îles)">Faroe (Îles)</option>
                                            <option value="Fidji">Fidji</option>
                                            <option value="Finlande">Finlande</option>
                                            <option value="France">France</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambie">Gambie</option>
                                            <option value="Géorgie">Géorgie</option>
                                            <option value="Géorgie du Sud et îles Sandwich du Sud">Géorgie du Sud et îles Sandwich du Sud</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Grèce">Grèce</option>
                                            <option value="Grenade">Grenade</option>
                                            <option value="Groenland">Groenland</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guernesey">Guernesey</option>
                                            <option value="Guinée">Guinée</option>
                                            <option value="Guinée-Bissau">Guinée-Bissau</option>
                                            <option value="Guinée équatoriale">Guinée équatoriale</option>
                                            <option value="Guyane">Guyane</option>
                                            <option value="Guyane française">Guyane française</option>
                                            <option value="Haïti">Haïti</option>
                                            <option value="Heard et McDonald (Îles)">Heard et McDonald (Îles)</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong-Kong">Hong-Kong</option>
                                            <option value="Hongrie">Hongrie</option>
                                            <option value="Îles vierges britanniques">Îles vierges britanniques</option>
                                            <option value="Inde">Inde</option>
                                            <option value="Inde du Nord Delhi">Inde du Nord Delhi</option>
                                            <option value="Inde du Sud">Inde du Sud</option>
                                            <option value="Indonésie">Indonésie</option>
                                            <option value="Irak">Irak</option>
                                            <option value="Iran">Iran</option>
                                            <option value="Irlande">Irlande</option>
                                            <option value="Islande">Islande</option>
                                            <option value="Israël">Israël</option>
                                            <option value="Italie">Italie</option>
                                            <option value="Jamaïque">Jamaïque</option>
                                            <option value="Japon">Japon</option>
                                            <option value="Jersey">Jersey</option>
                                            <option value="Jordanie">Jordanie</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kirghizistan">Kirghizistan</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Koweït">Koweït</option>
                                            <option value="Laos">Laos</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Lettonie">Lettonie</option>
                                            <option value="Liban">Liban</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libye">Libye</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lituanie">Lituanie</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macao">Macao</option>
                                            <option value="Macédoine">Macédoine</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malaisie">Malaisie</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malouines (Îles)">Malouines (Îles)</option>
                                            <option value="Malte">Malte</option>
                                            <option value="Man (Île)">Man (Île)</option>
                                            <option value="Mariannes du Nord (Commonwealth des îles)">Mariannes du Nord (Commonwealth des îles)</option>
                                            <option value="Maroc">Maroc</option>
                                            <option value="Marshall (Îles)">Marshall (Îles)</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Maurice (Île)">Maurice (Île)</option>
                                            <option value="Mauritanie">Mauritanie</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexique">Mexique</option>
                                            <option value="Micronésie">Micronésie</option>
                                            <option value="Moldavie">Moldavie</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolie">Mongolie</option>
                                            <option value="Monténégro">Monténégro</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Namibie">Namibie</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Népal">Népal</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigéria">Nigéria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk (Île de)">Norfolk (Île de)</option>
                                            <option value="Norvège">Norvège</option>
                                            <option value="Nouvelle-Calédonie">Nouvelle-Calédonie</option>
                                            <option value="Nouvelle-Zélande">Nouvelle-Zélande</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Ouganda">Ouganda</option>
                                            <option value="Ouzbékistan">Ouzbékistan</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau">Palau</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papouasie-Nouvelle Guinée">Papouasie-Nouvelle Guinée</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Pays-Bas">Pays-Bas</option>
                                            <option value="Pérou">Pérou</option>
                                            <option value="Philippines">Philippines</option>
                                            <option value="Pitcairn (Île)">Pitcairn (Île)</option>
                                            <option value="Pologne">Pologne</option>
                                            <option value="Polynésie française">Polynésie française</option>
                                            <option value="Porto Rico">Porto Rico</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="République Centrafricaine">République Centrafricaine</option>
                                            <option value="République Dominicaine">République Dominicaine</option>
                                            <option value="République Tchèque">République Tchèque</option>
                                            <option value="Réunion (Île de la)">Réunion (Île de la)</option>
                                            <option value="Roumanie">Roumanie</option>
                                            <option value="Royaume-Uni">Royaume-Uni</option>
                                            <option value="Russie">Russie</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="Sahara oriental">Sahara oriental</option>
                                            <option value="Sainte-Hélène">Sainte-Hélène</option>
                                            <option value="Sainte-Lucie">Sainte-Lucie</option>
                                            <option value="Saint-Kitts-et-Nevis">Saint-Kitts-et-Nevis</option>
                                            <option value="Saint-Marin">Saint-Marin</option>
                                            <option value="Saint-Pierre-et-Miquelon">Saint-Pierre-et-Miquelon</option>
                                            <option value="Saint-Vincent et les Grenadines">Saint-Vincent et les Grenadines</option>
                                            <option value="Salvador">Salvador</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="Samoa américaines">Samoa américaines</option>
                                            <option value="São Tomé et Príncipe">São Tomé et Príncipe</option>
                                            <option value="Sénégal">Sénégal</option>
                                            <option value="Serbie">Serbie</option>
                                            <option value="Serbie et Monténégro">Serbie et Monténégro</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapour">Singapour</option>
                                            <option value="Slovaquie">Slovaquie</option>
                                            <option value="Slovénie">Slovénie</option>
                                            <option value="Solomon">Solomon</option>
                                            <option value="Somalie">Somalie</option>
                                            <option value="Soudan">Soudan</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="Suède">Suède</option>
                                            <option value="Suisse">Suisse</option>
                                            <option value="Surinam">Surinam</option>
                                            <option value="Svalbard et Jan Mayen">Svalbard et Jan Mayen</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Syrie">Syrie</option>
                                            <option value="Tadjikistan">Tadjikistan</option>
                                            <option value="Taïwan">Taïwan</option>
                                            <option value="Tanzanie">Tanzanie</option>
                                            <option value="Tchad">Tchad</option>
                                            <option value="Territoires britanniques de l'Océan Indien">Territoires britanniques de l'Océan Indien</option>
                                            <option value="Territoires d'Outre-mer (France)">Territoires d'Outre-mer (France)</option>
                                            <option value="Territoires palestiniens">Territoires palestiniens</option>
                                            <option value="Thaïlande">Thaïlande</option>
                                            <option value="Thaïlande - Ayutthaya">Thaïlande - Ayutthaya</option>
                                            <option value="Thaïlande Chiang Mai/Nong Khai">Thaïlande Chiang Mai/Nong Khai</option>
                                            <option value="Timor oriental">Timor oriental</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad et Tobago (Trinidad and Tobago)">Trinidad et Tobago (Trinidad and Tobago)</option>
                                            <option value="Tunisie">Tunisie</option>
                                            <option value="Turkménistan">Turkménistan</option>
                                            <option value="Turks et Caïcos (Îles)">Turks et Caïcos (Îles)</option>
                                            <option value="Turquie">Turquie</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Vatican">Vatican</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Vierges américaines (Îles)">Vierges américaines (Îles)</option>
                                            <option value="Vietnam">Vietnam</option>
                                            <option value="Vietnam Hanoi">Vietnam Hanoi</option>
                                            <option value="Vietnam Ho Chi Minh">Vietnam Ho Chi Minh</option>
                                            <option value="Wallis et Futuna">Wallis et Futuna</option>
                                            <option value="Yémen">Yémen</option>
                                            <option value="Zambie">Zambie</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                            <option value="Bolivie - humanitaire">Bolivie - humanitaire</option>
                                            <option value="Bolivie - éco-volontariat">Bolivie - éco-volontariat</option>
                                            <option value="Equateur - humanitaire">Equateur - humanitaire</option>
                                            <option value="Equateur - éco-volontariat">Equateur - éco-volontariat</option>
                                            <option value="Iles Galapagos">Iles Galapagos</option>
                                            <option value="Sri Lanka – Eléphants">Sri Lanka – Eléphants</option>
                                            <option value="Australie – écovolontariat">Australie – écovolontariat</option>
                                            <option value="Afrique du Sud - Soins animaliers">Afrique du Sud - Soins animaliers</option>
                                            <option value="Afrique du Sud - Faune sauvage">Afrique du Sud - Faune sauvage</option>
                                            <option value="Majorque">Majorque</option>
                                            <option value="Sri Lanka - Tortues">Sri Lanka - Tortues</option>
                                            <option value="Etats-Unis - chevaux">Etats-Unis - chevaux</option>
                                            <option value="Inde - Himalaya">Inde - Himalaya</option>
                                            <option value="Tanzanie - Zanzibar">Tanzanie - Zanzibar</option>
                                            <option value="Thaïlande - animaux">Thaïlande - animaux</option>
                                            <option value="Thaïlande - Udon Thani">Thaïlande - Udon Thani</option>
                                            <option value="Thaïlande - Bangkok/Chiang Mai">Thaïlande - Bangkok/Chiang Mai</option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Adresse"
                                        name="adresse"
                                        rules={[{required: true, message: 'Information manquante'}]}
                                        initialValue={this.state.dataMembre.adresse}
                                    >
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Ville"
                                        name="ville"
                                        rules={[{required: true, message: 'Information manquante'}]}
                                        initialValue={this.state.dataMembre.ville}
                                    >
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item
                                        label="CP"
                                        name="cp"
                                        rules={[{required: true, message: 'Information manquante'}]}
                                        initialValue={this.state.dataMembre.cp}
                                    >
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Pays"
                                        name="pays"
                                        rules={[{required: true, message: 'Please input your username!'}]}
                                        initialValue={this.state.dataMembre.pays}
                                    >
                                        <Select style={{width: "100%"}}>
                                            <option value="AF">Afghanistan</option>
                                            <option value="ZA">Afrique du Sud</option>
                                            <option value="AX">Aland (Îles)</option>
                                            <option value="AL">Albanie</option>
                                            <option value="DZ">Algérie</option>
                                            <option value="DE">Allemagne</option>
                                            <option value="AD">Andorre</option>
                                            <option value="AO">Angola</option>
                                            <option value="AI">Anguilla</option>
                                            <option value="AQ">Antarctique</option>
                                            <option value="AG">Antigua et Barbuda</option>
                                            <option value="AN">Antilles néerlandaises</option>
                                            <option value="SA">Arabie Saoudite</option>
                                            <option value="AR">Argentine</option>
                                            <option value="AM">Arménie</option>
                                            <option value="AW">Aruba</option>
                                            <option value="AU">Australie</option>
                                            <option value="AT">Autriche</option>
                                            <option value="AZ">Azerbaïdjan</option>
                                            <option value="BS">Bahamas</option>
                                            <option value="BH">Bahreïn</option>
                                            <option value="BD">Bangladesh</option>
                                            <option value="BB">Barbade</option>
                                            <option value="BE">Belgique</option>
                                            <option value="BZ">Belize</option>
                                            <option value="BJ">Bénin</option>
                                            <option value="BM">Bermudes</option>
                                            <option value="BT">Bhutan</option>
                                            <option value="BY">Biélorussie</option>
                                            <option value="BO">Bolivie</option>
                                            <option value="BA">Bosnie-Herzégovine</option>
                                            <option value="BW">Botswana</option>
                                            <option value="BV">Bouvet (Île)</option>
                                            <option value="BR">Brésil</option>
                                            <option value="BN">Brunei</option>
                                            <option value="BG">Bulgarie</option>
                                            <option value="BF">Burkina Faso</option>
                                            <option value="BI">Burundi</option>
                                            <option value="KY">Caïmans (Îles)</option>
                                            <option value="KH">Cambodge</option>
                                            <option value="CM">Cameroun</option>
                                            <option value="CA">Canada</option>
                                            <option value="CV">Cap Vert</option>
                                            <option value="CL">Chili</option>
                                            <option value="CN">Chine</option>
                                            <option value="CX">Christmas (Îles)</option>
                                            <option value="CY">Chypre</option>
                                            <option value="CC">Cocos</option>
                                            <option value="CO">Colombie</option>
                                            <option value="KM">Comores</option>
                                            <option value="CG">Congo</option>
                                            <option value="CD">Congo, République démocratique du (Congo)</option>
                                            <option value="CK">Cook (Îles)</option>
                                            <option value="KP">Corée du Nord</option>
                                            <option value="KR">Corée du Sud</option>
                                            <option value="CR">Costa Rica</option>
                                            <option value="CI">Côte d'Ivoire</option>
                                            <option value="HR">Croatie</option>
                                            <option value="CU">Cuba</option>
                                            <option value="DK">Danemark</option>
                                            <option value="UM">Dépendances américaines du Pacifique</option>
                                            <option value="DJ">Djibouti</option>
                                            <option value="DM">Dominique</option>
                                            <option value="EG">Égypte</option>
                                            <option value="AE">Émirats Arabes Unis</option>
                                            <option value="EC">Équateur</option>
                                            <option value="ER">Érythrée</option>
                                            <option value="ES">Espagne</option>
                                            <option value="EE">Estonie</option>
                                            <option value="US">États-Unis</option>
                                            <option value="ET">Éthiopie</option>
                                            <option value="FO">Faroe (Îles)</option>
                                            <option value="FJ">Fidji</option>
                                            <option value="FI">Finlande</option>
                                            <option value="FR">France</option>
                                            <option value="GA">Gabon</option>
                                            <option value="GM">Gambie</option>
                                            <option value="GE">Géorgie</option>
                                            <option value="GS">Géorgie du Sud et îles Sandwich du Sud</option>
                                            <option value="GH">Ghana</option>
                                            <option value="GI">Gibraltar</option>
                                            <option value="GR">Grèce</option>
                                            <option value="GD">Grenade</option>
                                            <option value="GL">Groenland</option>
                                            <option value="GP">Guadeloupe</option>
                                            <option value="GU">Guam</option>
                                            <option value="GT">Guatemala</option>
                                            <option value="GG">Guernesey</option>
                                            <option value="GN">Guinée</option>
                                            <option value="GW">Guinée-Bissau</option>
                                            <option value="GQ">Guinée équatoriale</option>
                                            <option value="GY">Guyane</option>
                                            <option value="GF">Guyane française</option>
                                            <option value="HT">Haïti</option>
                                            <option value="HM">Heard et McDonald (Îles)</option>
                                            <option value="HN">Honduras</option>
                                            <option value="HK">Hong-Kong</option>
                                            <option value="HU">Hongrie</option>
                                            <option value="VG">Îles vierges britanniques</option>
                                            <option value="IN">Inde</option>
                                            <option value="INN">Inde du Nord Delhi</option>
                                            <option value="INS">Inde du Sud</option>
                                            <option value="ID">Indonésie</option>
                                            <option value="IQ">Irak</option>
                                            <option value="IR">Iran</option>
                                            <option value="IE">Irlande</option>
                                            <option value="IS">Islande</option>
                                            <option value="IL">Israël</option>
                                            <option value="IT">Italie</option>
                                            <option value="JM">Jamaïque</option>
                                            <option value="JP">Japon</option>
                                            <option value="JE">Jersey</option>
                                            <option value="JO">Jordanie</option>
                                            <option value="KZ">Kazakhstan</option>
                                            <option value="KE">Kenya</option>
                                            <option value="KG">Kirghizistan</option>
                                            <option value="KI">Kiribati</option>
                                            <option value="KW">Koweït</option>
                                            <option value="LA">Laos</option>
                                            <option value="LS">Lesotho</option>
                                            <option value="LV">Lettonie</option>
                                            <option value="LB">Liban</option>
                                            <option value="LR">Liberia</option>
                                            <option value="LY">Libye</option>
                                            <option value="LI">Liechtenstein</option>
                                            <option value="LT">Lituanie</option>
                                            <option value="LU">Luxembourg</option>
                                            <option value="MO">Macao</option>
                                            <option value="MK">Macédoine</option>
                                            <option value="MG">Madagascar</option>
                                            <option value="MY">Malaisie</option>
                                            <option value="MW">Malawi</option>
                                            <option value="MV">Maldives</option>
                                            <option value="ML">Mali</option>
                                            <option value="FK">Malouines (Îles)</option>
                                            <option value="MT">Malte</option>
                                            <option value="IM">Man (Île)</option>
                                            <option value="MP">Mariannes du Nord (Commonwealth des îles)</option>
                                            <option value="MA">Maroc</option>
                                            <option value="MH">Marshall (Îles)</option>
                                            <option value="MQ">Martinique</option>
                                            <option value="MU">Maurice (Île)</option>
                                            <option value="MR">Mauritanie</option>
                                            <option value="YT">Mayotte</option>
                                            <option value="MX">Mexique</option>
                                            <option value="FM">Micronésie</option>
                                            <option value="MD">Moldavie</option>
                                            <option value="MC">Monaco</option>
                                            <option value="MN">Mongolie</option>
                                            <option value="ME">Monténégro</option>
                                            <option value="MS">Montserrat</option>
                                            <option value="MZ">Mozambique</option>
                                            <option value="MM">Myanmar</option>
                                            <option value="NA">Namibie</option>
                                            <option value="NR">Nauru</option>
                                            <option value="NP">Népal</option>
                                            <option value="NI">Nicaragua</option>
                                            <option value="NE">Niger</option>
                                            <option value="NG">Nigéria</option>
                                            <option value="NU">Niue</option>
                                            <option value="NF">Norfolk (Île de)</option>
                                            <option value="NO">Norvège</option>
                                            <option value="NC">Nouvelle-Calédonie</option>
                                            <option value="NZ">Nouvelle-Zélande</option>
                                            <option value="OM">Oman</option>
                                            <option value="UG">Ouganda</option>
                                            <option value="UZ">Ouzbékistan</option>
                                            <option value="PK">Pakistan</option>
                                            <option value="PW">Palau</option>
                                            <option value="PA">Panama</option>
                                            <option value="PG">Papouasie-Nouvelle Guinée</option>
                                            <option value="PY">Paraguay</option>
                                            <option value="NL">Pays-Bas</option>
                                            <option value="PE">Pérou</option>
                                            <option value="PH">Philippines</option>
                                            <option value="PN">Pitcairn (Île)</option>
                                            <option value="PL">Pologne</option>
                                            <option value="PF">Polynésie française</option>
                                            <option value="PR">Porto Rico</option>
                                            <option value="PT">Portugal</option>
                                            <option value="QA">Qatar</option>
                                            <option value="CF">République Centrafricaine</option>
                                            <option value="DO">République Dominicaine</option>
                                            <option value="CZ">République Tchèque</option>
                                            <option value="RE">Réunion (Île de la)</option>
                                            <option value="RO">Roumanie</option>
                                            <option value="GB">Royaume-Uni</option>
                                            <option value="RU">Russie</option>
                                            <option value="RW">Rwanda</option>
                                            <option value="EH">Sahara oriental</option>
                                            <option value="SH">Sainte-Hélène</option>
                                            <option value="LC">Sainte-Lucie</option>
                                            <option value="KN">Saint-Kitts-et-Nevis</option>
                                            <option value="SM">Saint-Marin</option>
                                            <option value="PM">Saint-Pierre-et-Miquelon</option>
                                            <option value="VC">Saint-Vincent et les Grenadines</option>
                                            <option value="SV">Salvador</option>
                                            <option value="WS">Samoa</option>
                                            <option value="AS">Samoa américaines</option>
                                            <option value="ST">São Tomé et Príncipe</option>
                                            <option value="SN">Sénégal</option>
                                            <option value="RS">Serbie</option>
                                            <option value="CS">Serbie et Monténégro</option>
                                            <option value="SC">Seychelles</option>
                                            <option value="SL">Sierra Leone</option>
                                            <option value="SG">Singapour</option>
                                            <option value="SK">Slovaquie</option>
                                            <option value="SI">Slovénie</option>
                                            <option value="SB">Solomon</option>
                                            <option value="SO">Somalie</option>
                                            <option value="SD">Soudan</option>
                                            <option value="LK">Sri Lanka</option>
                                            <option value="SE">Suède</option>
                                            <option value="CH">Suisse</option>
                                            <option value="SR">Surinam</option>
                                            <option value="SJ">Svalbard et Jan Mayen</option>
                                            <option value="SZ">Swaziland</option>
                                            <option value="SY">Syrie</option>
                                            <option value="TJ">Tadjikistan</option>
                                            <option value="TW">Taïwan</option>
                                            <option value="TZ">Tanzanie</option>
                                            <option value="TD">Tchad</option>
                                            <option value="IO">Territoires britanniques de l'Océan Indien</option>
                                            <option value="TF">Territoires d'Outre-mer (France)</option>
                                            <option value="PS">Territoires palestiniens</option>
                                            <option value="TH">Thaïlande</option>
                                            <option value="THB">Thaïlande - Ayutthaya</option>
                                            <option value="THC">Thaïlande Chiang Mai/Nong Khai</option>
                                            <option value="TL">Timor oriental</option>
                                            <option value="TG">Togo</option>
                                            <option value="TK">Tokelau</option>
                                            <option value="TO">Tonga</option>
                                            <option value="TT">Trinidad et Tobago (Trinidad and Tobago)</option>
                                            <option value="TN">Tunisie</option>
                                            <option value="TM">Turkménistan</option>
                                            <option value="TC">Turks et Caïcos (Îles)</option>
                                            <option value="TR">Turquie</option>
                                            <option value="TV">Tuvalu</option>
                                            <option value="UA">Ukraine</option>
                                            <option value="UY">Uruguay</option>
                                            <option value="VU">Vanuatu</option>
                                            <option value="VA">Vatican</option>
                                            <option value="VE">Venezuela</option>
                                            <option value="VI">Vierges américaines (Îles)</option>
                                            <option value="VN">Vietnam</option>
                                            <option value="VNH">Vietnam Hanoi</option>
                                            <option value="VNC">Vietnam Ho Chi Minh</option>
                                            <option value="WF">Wallis et Futuna</option>
                                            <option value="YE">Yémen</option>
                                            <option value="ZM">Zambie</option>
                                            <option value="ZW">Zimbabwe</option>
                                            <option value="BO1">Bolivie - humanitaire</option>
                                            <option value="BO2">Bolivie - éco-volontariat</option>
                                            <option value="EC1">Equateur - humanitaire</option>
                                            <option value="EC2">Equateur - éco-volontariat</option>
                                            <option value="IG1">Iles Galapagos</option>
                                            <option value="LKEL">Sri Lanka – Eléphants</option>
                                            <option value="AEVO">Australie – écovolontariat</option>
                                            <option value="ZA1">Afrique du Sud - Soins animaliers</option>
                                            <option value="ZA2">Afrique du Sud - Faune sauvage</option>
                                            <option value="IB">Majorque</option>
                                            <option value="LKETO">Sri Lanka - Tortues</option>
                                            <option value="EUCHV">Etats-Unis - chevaux</option>
                                            <option value="IHMAYA">Inde - Himalaya</option>
                                            <option value="TZANZI">Tanzanie - Zanzibar</option>
                                            <option value="THD">Thaïlande - animaux</option>
                                            <option value="THE">Thaïlande - Udon Thani</option>
                                            <option value="THBCM">Thaïlande - Bangkok/Chiang Mai</option>
                                        </Select>

                                    </Form.Item>

                                    <Form.Item
                                        label="Téléphone portable"
                                        name="telPort"
                                        rules={[{required: true, message: 'Information manquante'}]}
                                        initialValue={this.state.dataMembre.telPort}
                                    >
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Téléphone fixe"
                                        name="telFix"
                                        rules={[{required: false, message: 'Information manquante'}]}
                                        initialValue={this.state.dataMembre.telFix}
                                    >
                                        <Input/>
                                    </Form.Item>


                                    <Form.Item
                                        label="Situation professionnelle actuelle"
                                        name="situationPro"
                                        rules={[{required: true, message: 'Information manquante'}]}
                                        initialValue={this.state.dataMembre.situationPro}
                                    >
                                        <Input/>
                                    </Form.Item>
                                    {/**

                                     <Form.Item
                                     label="Formation"
                                     name="formation"
                                     rules={[{required: true, message: 'Information manquante'}]}
                                     initialValue={this.state.dataMembre.formation}
                                     >
                                     <Input/>
                                     </Form.Item>


                                     <Form.Item
                                     label="Niveau d'étude"
                                     name="nivEtude"
                                     rules={[{required: true, message: 'Information manquante'}]}
                                     initialValue={this.state.dataMembre.nivEtude}
                                     >
                                     <Input/>
                                     </Form.Item>

                                     <Form.Item
                                     label="Cursus spécialisé"
                                     name="cursusSpe"
                                     rules={[{required: true, message: 'Information manquante'}]}
                                     initialValue={this.state.dataMembre.cursusSpe}
                                     >
                                     <Input/>
                                     </Form.Item>
                                     **/}

                                    <Form.Item
                                        label="Facebook"
                                        name="gpMembrecolFb"
                                        rules={[{required: false, message: 'Information manquante'}]}
                                        initialValue={this.state.dataMembre.gpMembrecolFb}
                                    >
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Remarques"
                                        name="experienceUtile"
                                        rules={[{required: false, message: 'Information manquante'}]}
                                        initialValue={this.state.dataMembre.experienceUtile}
                                    >
                                        <Input/>
                                    </Form.Item>


                                    <Form.Item {...tailLayout}>
                                        <Button type="primary" htmlType="submit">
                                            Valider mes informations
                                        </Button>
                                    </Form.Item>
                                </Form>

                            </div>
                        </Layout>
                    </Layout>
                    <Divider dashed/>
                    <FooterApps/>
                </Layout>
            )
        } else {
            return (
                <Layout>
                    <HeaderMember/>
                    <Layout>
                        <MenuDashboard/>
                        <Layout>
                            <div className="contentBox">
                                chargement...
                            </div>
                        </Layout>
                    </Layout>
                </Layout>
            )
        }
    }
}



