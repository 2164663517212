import React from "react";
import {Col, Row, Typography} from "antd";
import BlockSejour from "./BlockSejour";

const {Title, Text} = Typography;
export default class ChoixSejour extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idSejourSelect: false,
            headerParam: false,
            fromSite: false,
            changePaysState : this.props.changePaysState
        }
        this.selectIdSejour = this.selectIdSejour.bind(this)
        this.changeHeader = this.changeHeader.bind(this)
    }

    async selectIdSejour(id) {
        await this.setState({idSejourSelect: id})
        await this.props.chageSejour(id)
    }

    changeHeader(headerParam) {
        this.props.paramHeader(headerParam)
    }

    componentDidMount() {
        if (localStorage.getItem('order_id_sejour')) {
            this.setState({idSejourSelect: localStorage.getItem('order_id_sejour')})
        }

        if (localStorage.getItem('order_formSite')) {
            this.setState({fromSite: true})
        }

    }

    render() {
        if (this.props.dataSejours) {
            var dataSejourliste = this.props.dataSejours;
            var selectIdSejour = this.selectIdSejour;
            var changeHeader = this.changeHeader;
            var idSelect = this.state.idSejourSelect;


            if (this.state.fromSite && this.props.changePaysState === false) {
                var listeSejour = Object.keys(dataSejourliste).map(function (key) {
                    if(key == idSelect){
                        return <BlockSejour changeHeader={changeHeader} idSelect={idSelect} selectIdsejour={selectIdSejour}
                                            dataSejour={dataSejourliste[key]}/>
                    }

                })
            } else {
                var listeSejour = Object.keys(dataSejourliste).map(function (key) {
                    return <BlockSejour changeHeader={changeHeader} idSelect={idSelect} selectIdsejour={selectIdSejour}
                                        dataSejour={dataSejourliste[key]}/>
                })
            }


        } else {
            var listeSejour = '';
        }


        if (this.props.resetListeSejour) {
            return (
                <div>
                </div>
            )
        } else {
            return (
                <div id="listeSejourBloc">
                    <div id="test" className="centerTxt mb10 mt10">
                        <Title level={2}>Choix du programme</Title>
                    </div>

                    {listeSejour}

                </div>
            )
        }

    }
}