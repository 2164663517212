import React, {Component} from 'react';
import {Upload, message} from 'antd';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import './../css/step.css'

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf' || file.type === 'application/msword'|| file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    if (!isJpgOrPng) {
        message.error('Il faut que le fichier soit au format PDF,JPEG,PNG, DOC ou DOCX (format Word)');
    }
    const isLt2M = file.size / 1024 / 1024 < 35;
    console.log(file.size / 1024 / 1024 );
    if (!isLt2M) {
        message.error('Ne doit pas dépasser 35Mo !');
    }
    return isJpgOrPng && isLt2M;
}

class DocBloc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imageUrl: false,
            typeDoc : this.props.typeDoc,
            fileNameFull: ""
        }
    }

    componentDidMount() {

        let nameImg = localStorage.getItem('order_doc_'+this.state.typeDoc)
        if (nameImg) {
            this.setState({
                imageUrl:'https://crm.globalong.com/content/person/'+nameImg
            })
        }
    }

    state = {
        loading: false,
    };

    handleChange = info => {

        // sauver l'etat de l'image
        if (info.file.status === 'uploading') {

            this.setState({loading: true});
            return;
        }

        if (info.file.response) {
            var nameFile = info.file.response.namefile
            localStorage.setItem("order_doc_" + this.props.typeDoc, nameFile);
        }

        // ajout de l'image dans l'encart
        if (info.file.status === 'done') {
            console.log(info.file)
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                    fileNameFull : info.file.name
                }),
            );
        }
    };

    render() {

        const uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined/> : <PlusOutlined/>}
                <div className="ant-upload-text">{this.props.lib}</div>
            </div>
        );
        const {imageUrl} = this.state;

        return (
            <div className="center">
                <Upload
                    accept=".pdf,.png,.jpeg,.jpg,.doc,.docx"
                    name="file"
                    listType="picture-card"
                    className="avatar-uploader uploadDocs"
                    showUploadList={false}
                    action="https://crm.globalong.com/api/upload_react_interface.php"
                    beforeUpload={beforeUpload}
                    onChange={this.handleChange}
                    data={
                        {
                            'exemple': 'jemet un exemple de data',
                        }
                    }
                >
                    {imageUrl ? <img src={imageUrl} alt={this.state.fileNameFull} style={{width: '100%'}}/> : uploadButton}
                </Upload>
            </div>
        );
    }
}

export default DocBloc;