import React from 'react';
import MenuDashboard from "./Menu";
import "./css/dashboard.css";
import logoGLob from "./../../img/logoglob.png"
import {API_URI} from "../../constant/Config"
import HeaderMember from "./HeaderMember";
import moment from 'moment';
import 'moment-timezone';
import {
    UserOutlined,
    SettingFilled,
    SmileOutlined,
    SyncOutlined,
    LoadingOutlined,
    SolutionOutlined,
    CreditCardOutlined,
} from '@ant-design/icons';

import {Form, Input, Button, Checkbox, Row, Col, Typography, Divider, Steps,Badge,Popover} from 'antd';
import axios from "axios";
import QuestionBloc from "../order/questions/QuestionBloc";
import {Link, Redirect} from 'react-router-dom';

const {Title, Text} = Typography;
const {Step} = Steps;

class ListeCmd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataCMd: false,
        }
    }

    async componentDidMount() {
        let dataCmd = await this.getlisteCmd()
        await this.setState({dataCMd: dataCmd})
    }

    getlisteCmd = async () => {
        var token = localStorage.getItem('membre_token');
        let dataCmd = await axios.post(API_URI + '/api_member/list_order', {
            membre_id: localStorage.getItem('membre_id'),
            filter:this.props.filter
        }, {
            headers: {
                'Authorization': token
            }
        });

        return dataCmd.data;
    }


    render() {

        if (this.state.dataCMd) {
            var cmdData = this.state.dataCMd.liste;

            var cmdDataFinale = [];

            var listePays = Object.keys(cmdData).map(function (key) {
                var idSelect = key + '-' + cmdData[key]['codePays']

                cmdDataFinale.push(
                    {
                        key: key,
                        id: cmdData[key]['id'],
                        ref: cmdData[key]['ref'],
                        titre: cmdData[key]['titre'],
                        cat: cmdData[key]['cat'],
                        pays: cmdData[key]['pays'],
                        total: cmdData[key]['total'],
                        dateStart: cmdData[key]['dateStart'],
                        dateEnd: cmdData[key]['dateEnd'],
                        statutId: cmdData[key]['statutId'],
                        statutLib: cmdData[key]['statutLib']['lib'],
                    }
                )
            });


            function compare(a, b) {
                if (a.id > b.id) {
                    return -1;
                }
                if (a.id < b.id) {
                    return 1;
                }
                return 0;
            }

            cmdDataFinale.sort(compare);




            return (
                <div>
                    <Title level={2}>{this.props.title}</Title>

                    {cmdDataFinale.map(cmdDataFinaleItem => {

                        const urCmd = "/ident/order/" + cmdDataFinaleItem.id
                        const urCmdResume = "/order/complete/" + cmdDataFinaleItem.id

                        if(cmdDataFinaleItem.statutId == 11){
                            var statutLib =<Popover content="Cliquez ici afin de valider la dernière étape de votre candidature." title="Informations complémentaires requises">
                                <Link className="btn btn-primary" to={urCmdResume}><Button danger type="text">{cmdDataFinaleItem.statutLib}</Button></Link>
                                </Popover>
                        }else{
                            var statutLib =<Text keyboard>{cmdDataFinaleItem.statutLib}</Text>
                        }


                        return <div>
                            <Link className="btn btn-primary" to={urCmd}>
                                <Title level={4}>Référence : {cmdDataFinaleItem.ref}</Title>
                            </Link>



                            <Text strong>Type de programme</Text> <Text>{cmdDataFinaleItem.cat}</Text>
                            <br/>
                            <Text strong>Pays</Text> <Text>{cmdDataFinaleItem.pays}</Text>
                            <br/>
                            <Text strong>Projet</Text> <Text>{cmdDataFinaleItem.titre}</Text>
                            <br/>
                            <Text strong>Date de début</Text> <Text
                            keyboard>{moment(cmdDataFinaleItem.dateStart.date).format('DD/MM/YYYY')}</Text>
                            <br />
                            <Text strong>Date de fin</Text> <Text
                            keyboard>{(cmdDataFinaleItem.dateEnd)?moment(cmdDataFinaleItem.dateEnd.date).format('DD/MM/YYYY'):'Non précisé'}</Text>

                            <br/>
                            <Text strong>Statut du dossier de candidature</Text> {statutLib}

                            <br/>
                            <br/>
                            <Link className="btn btn-primary" to={urCmd}>
                                <Button type="primary">Voir le détail</Button>
                            </Link>
                            <Divider dashed/>
                        </div>
                    })}
                        </div>
                        )
                    } else {
                    return (
                    <div>
                    </div>
                    )
                }


                    }
    }

    export
    default
    ListeCmd;