import React, {Component} from 'react';
import {Result, Button, Row, Col, Alert, Divider} from 'antd';
import Header from "./inc/Header";
import {Redirect, Link} from "react-router-dom";
import {FooterApps} from "./inc/FooterApps";

class InscrTermine extends Component {

    constructor(props) {
        super(props);
        this.state = {
            paramHeader: {},
            idOrder: localStorage.getItem('order_log_id'),
            orderIdCat: localStorage.getItem('order_idcat'),
        }
    }

    render() {
        var ref = localStorage.getItem('order_log_ref')

        if (this.state.orderIdCat == 3) {
            var message = <Row>
                <Col span={12} offset={6} style={{textAlign: 'left'}}>
                    <br/>
                    Bonjour {localStorage.getItem('membre_prenom')},
                    <br/><br/>
                    Vous venez d’ouvrir un dossier pour candidater pour un projet avec l’association Globalong et nous vous remercions pour votre confiance.
                    <br/><br/>
                    Veuillez valider votre candidature ci-dessous.
                    <br/><br/>
                    Vous recevrez une réponse sous 1 à 7 jours ouvrés. En attendant, contactez-nous si vous avez des questions.

                    <br/>
                    <br/>
                    <b>L'équipe Globalong</b><br/>
                    <a href="www.globalong.com">www.globalong.com</a><br/>
                    <a href="mailto:info@globalong.com">info@globalong.com</a>

                </Col>
            </Row>

        } else {
            var message = <Row>
                <Col span={12} offset={6} style={{textAlign: 'left'}}>
                    <br/>
                    Bonjour {localStorage.getItem('membre_prenom')},
                    <br/>
                    <br/>
                    Vous venez d’ouvrir un dossier pour candidater pour un projet avec l’association Globalong et nous
                    vous
                    remercions pour votre confiance.
                    <br/>
                    <br/>
                    Afin de finaliser la candidature, vous devez déposer un dossier de candidature en déposant les
                    documents
                    listés ci-dessus via votre espace membre.
                    <br/>
                    Votre dossier doit être constitué de :
                    <br/><br/>
                    1. Votre CV
                    <br/><br/>
                    2. Une lettre de présentation dans laquelle vous répondrez aux questions suivantes :<br/>
                    • Pourquoi souhaitez-vous partir ?<br/>
                    • Pourquoi avez-vous choisi ce pays et ce projet ?<br/>
                    • Quelles qualifications ou expériences en rapport avec la mission avez-vous ?<br/>
                    • Qu'attendez-vous de la mission ?
                    <br/><br/>
                    3. Pour les missions de bénévolat et stages : un extrait de casier judiciaire. Pour les
                    ressortissants
                    français, il est possible de le demander en ligne ici. (<a
                    href="https://casier-judiciaire.justice.gouv.fr/pages/accueil.xhtml"
                    target="_blank">https://casier-judiciaire.justice.gouv.fr/pages/accueil.xhtml</a> )
                    <br/><br/>
                    <Alert
                        message="La lettre et le CV doivent impérativement être rédigés :"
                        description={<div>
                            <ul>
                                <li>- en français pour le Togo, le Maroc, Madagascar et le Sénégal</li>
                                <li>- en espagnol pour les pays hispanophones (ou en anglais si vous êtes débutant en
                                    espagnol)
                                </li>
                                <li>- en anglais pour toutes les autres destinations</li>
                            </ul>
                        </div>}
                        type="warning"
                        showIcon
                        closable
                    />

                    <br/>
                    <br/>
                    <b>L'équipe Globalong</b><br/>
                    <a href="www.globalong.com">www.globalong.com</a><br/>
                    <a href="mailto:info@globalong.com">info@globalong.com</a>

                </Col>
            </Row>
        }


        return (

            <div>
                <Header paramHeader={this.state.paramHeader}/>

                <Result
                    status="success"
                    title="Ouverture de dossier confirmé"
                    subTitle={message}
                    extra={[
                        <Link
                            to={{
                                pathname: "/order/complete/" + this.state.idOrder,
                            }}
                        ><Button type="primary" key="console">
                            Finaliser ma candidature
                        </Button></Link>,
                        <Link
                            to={{
                                pathname: "/",
                            }}
                        ><Button key="console" onClick={() => window.location.href = "https://www.globalong.com"}>
                            Revenir sur le site
                        </Button></Link>,
                    ]}
                />

                <Divider dashed/>
                <FooterApps/>
            </div>
        );
    }
}

export default InscrTermine;