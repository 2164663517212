import React, {Component} from 'react';
import {Col, Divider, Row, Typography, Alert, Form, Input, Button, Radio} from "antd";
import {GoogleLogin} from 'react-google-login';
import FacebookAuth from 'react-facebook-auth';
import FormRegister from "./RegisterMember/FormRegister";
import MethodeReg from "./RegisterMember/MethodeReg";

const {Title, Text} = Typography;
const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

class Register extends Component {


    constructor(props) {
        super(props);
        this.state = {
            displayForm: false,
            displayMethode: true,
        }

        this.changeMeth = this.changeMeth.bind(this)
    }

    changeMeth = () => {
        this.setState({
            displayForm: true,
            displayMethode: false,
        })
    }


    render() {
        return (
            <div>
                {(this.state.displayMethode) ? <MethodeReg changeMethAction={this.changeMeth}/> : ''}
                <Row>
                    <Col span={24}>
                        {(this.state.displayForm) ? <FormRegister/> : ''}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Register;