import React from "react";
import "./css/dashboard.css";
import axios from "axios";
import {API_URI} from "../../constant/Config";
import {
    Typography,
    Divider,
    Timeline,
    Space,
    Alert,
    Button,
    Select,
    Radio,
    Collapse,
    Popconfirm,
    message,
    Result
} from 'antd';
import {ClockCircleOutlined, SmileOutlined} from '@ant-design/icons';

const {Title, Paragraph, Text, Link} = Typography;
const {Panel} = Collapse;
const {Option} = Select;

class OrderDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            dataCmd: false,
            dataForm: false,
            zone1: false,
            statut: false,
            soldeDu: 0,
        }
    }

    async componentDidMount() {

        await this.getFormCb();
        const dataCMD = await this.getDetailsCmd();

        // ancien calcul du solde à suprimer
        //var soldeDu = dataCMD.data.totalht - 200;

        // récupération du solde en déduisant les transactions déjà payées
        var soldeDu = dataCMD.soldeApayer;


        await this.setState({
            dataCmd: dataCMD,
            statut: parseInt(dataCMD.data.gpCommandescolStatut),
            zone1: this.stepDisplay(),
            soldeDu: soldeDu,
        })
        const displayStep = this.stepDisplay();
        await this.setState({
            zone1: displayStep,
        })

        console.log(this.state.statut)

    }

    getDetailsCmd = async () => {
        var token = localStorage.getItem('membre_token');
        let dataCmd = await axios.post(API_URI + '/api_member/details_order', {
            id: this.state.id,
        }, {
            headers: {
                'Authorization': token
            }
        })
        return dataCmd.data;
    }

    getFormCb = async () => {
        const formPayement = await axios.post(API_URI + '/api_member/payment', {id: this.state.id});
        await this.setState({dataForm: formPayement.data.data});
    }

    validePayement = async (typePayement, amount) => {
        const paramPost = {
            idCmd: this.state.id,
            setPaymentMeans: '',
            setCardNumber: '',
            setAmount: (amount),
            setTypepaiement: typePayement,
            setEtat: '0',
            setEtatAuto: '0',
            setGpTransactionNum: '',
        }
        const formPayement = await axios.post(API_URI + '/api_member/payment-validation', paramPost);
        await this.setState({zone1: this.confirmationPayement(typePayement)})
    }

    createMarkup = (html) => {
        return {__html: html};
    }

    modulePayement = (amount, type) => {
        if (this.state.dataForm) {
            var formCB = <div dangerouslySetInnerHTML={this.createMarkup(this.state.dataForm)}/>
        } else {
            var formCB = 'Chargement...';
        }

        if (type == 'prempaiement') {

            // déterminer si c'est une commande membre honoraire (premiere comande ou non)

            if (this.state.dataCmd.data.gpCommandescolWfCotisation == 1) {
                var title = <div>Action requise de votre part : Versement de la cotisation d'adhésion</div>
                var msg = <div>
                    <p>Nous sommes heureux de vous informer que votre candidature a &eacute;t&eacute; retenue.</p>
                    <p>&nbsp;</p>
                    <p>Afin de valider votre engagement, merci de verser la cotisation
                        d&rsquo;adh&eacute;sion &agrave; l&rsquo;association (200&euro;) pour devenir membre de
                        l&rsquo;association Globalong. L&rsquo;adh&eacute;sion est valable pendant 1 an, renouvelable
                        gratuitement pendant 9 ans.</p>
                    <p>&nbsp;</p>
                    <p>Une fois le versement effectu&eacute;, nous allons traiter votre inscription, et vous allez
                        recevoir tous les documents et informations n&eacute;cessaires &agrave; la pr&eacute;paration de
                        votre projet.</p>
                    Une tierce personne souhaite faire un don pour mon projet, comment faire ?<br/>
                    La majorité de nos bénévoles lèvent des fonds auprès de tierces personnes ou entreprises pour
                    financer leur projet. Chaque donateur peut prétendre à une déduction d’impôts et recevoir un reçu
                    fiscal. ATTENTION, le donateur doit faire le versement à l’association Globalong directement (il ne
                    peut pas vous verser une somme à vous). Cela peut se faire des manières suivantes :<br/><br/>

                    1. Par carte bancaire sur votre espace membre<br/><br/>

                    2. Par virement bancaire - dans ce cas, vous recevez notre RIB par mail et les donateurs peuvent
                    faire un ou plusieurs virements vers notre compte, en indiquant votre nom et prénom dans l’objet du
                    virement<br/><br/>

                    3. Par chèque, en indiquant dans le courrier que le don est pour votre dossier<br/><br/>

                    A chaque fois que nous recevons un versement à destination de votre projet, nous vous envoyons une
                    déclaration de donateur à remplir et à nous retourner afin que nous puissions envoyer le(s) reçu(s)
                    au(x) donateur(s).<br/>
                    <p>Le d&eacute;lai de traitement d&eacute;pendra du mode de versement choisi.</p>
                    <p>&nbsp;</p>
                    <p>Si vous avez des questions ou besoin d&rsquo;aide pour les &eacute;tapes suivantes, merci de nous
                        contacter par mail ou prendre un rendez-vous t&eacute;l&eacute;phonique <a target="_blank"
                                                                                                   href="https://appt.link/meet-with-globalong/">via
                            ce lien.</a></p>
                </div>
            } else {
                var title = <div>Action requise de votre part : Versement de la cotisation d'adhésion</div>
                var msg = <div>
                    <p>Nous sommes heureux de vous informer que votre candidature a &eacute;t&eacute; retenue.</p>
                    <p>&nbsp;</p>
                    <p>Afin de valider votre engagement, merci de verser une premi&egrave;re partie du don de
                        solidarit&eacute; (200&euro;) pour que nous puissions commencer l&rsquo;organisation de votre
                        projet. Ce montant sera d&eacute;duit de la somme totale que nous sommes charg&eacute;s de
                        r&eacute;colter pour votre projet.</p>

                    Une tierce personne souhaite faire un don pour mon projet, comment faire ?<br/>
                    La majorité de nos bénévoles lèvent des fonds auprès de tierces personnes ou entreprises pour
                    financer leur projet. Chaque donateur peut prétendre à une déduction d’impôts et recevoir un reçu
                    fiscal. ATTENTION, le donateur doit faire le versement à l’association Globalong directement (il ne
                    peut pas vous verser une somme à vous). Cela peut se faire des manières suivantes :<br/><br/>

                    1. Par carte bancaire sur votre espace membre<br/><br/>

                    2. Par virement bancaire - dans ce cas, vous recevez notre RIB par mail et les donateurs peuvent
                    faire un ou plusieurs virements vers notre compte, en indiquant votre nom et prénom dans l’objet du
                    virement<br/><br/>

                    3. Par chèque, en indiquant dans le courrier que le don est pour votre dossier<br/><br/>

                    A chaque fois que nous recevons un versement à destination de votre projet, nous vous envoyons une
                    déclaration de donateur à remplir et à nous retourner afin que nous puissions envoyer le(s) reçu(s)
                    au(x) donateur(s).<br/>


                    <p>&nbsp;</p>
                    <p>Il ne s&rsquo;agit pas de la cotisation d&rsquo;adh&eacute;sion &agrave; l&rsquo;association, que
                        vous avez déjà versé (et qui a déjà été déduite), mais d&rsquo;un versement partiel du don pour
                        ce
                        projet.</p>
                    <p>&nbsp;</p>
                    <p>Une fois le versement effectu&eacute;, nous allons traiter votre inscription, et vous allez
                        recevoir tous les documents et informations n&eacute;cessaires &agrave; la pr&eacute;paration de
                        votre projet.</p>
                    <p>&nbsp;</p>
                    <p>Le d&eacute;lai de traitement d&eacute;pendra du mode de versement choisi.</p>
                    <p>&nbsp;</p>
                    <p>Si vous avez des questions ou besoin d&rsquo;aide pour les &eacute;tapes suivantes, merci de nous
                        contacter par mail ou prendre un rendez-vous t&eacute;l&eacute;phonique <a target="_blank"
                                                                                                   href="https://appt.link/meet-with-globalong/">via
                            ce lien.</a></p>
                </div>
            }

            var message = <Alert
                message={title}
                description={msg}
                type="warning"
                showIcon
                closable
            />
        } else if (type == 'solde') {

            if (this.state.dataCmd.data.idCat == 3) {
                // ne pas afficher le message pour les prgrammes langues
                var description = <></>;
            } else {
                var description = <div>
                    Merci de verser le solde restant d'un montant de {amount / 100} € <br/><br/>

                    Une tierce personne souhaite faire un don pour mon projet, comment faire ?<br/>
                    La majorité de nos bénévoles lèvent des fonds auprès de tierces personnes ou entreprises pour
                    financer leur projet. Chaque donateur peut prétendre à une déduction d’impôts et recevoir un reçu
                    fiscal. ATTENTION, le donateur doit faire le versement à l’association Globalong directement (il ne
                    peut pas vous verser une somme à vous). Cela peut se faire des manières suivantes :<br/><br/>

                    1. Par carte bancaire sur votre espace membre<br/><br/>

                    2. Par virement bancaire - dans ce cas, vous recevez notre RIB par mail et les donateurs peuvent
                    faire un ou plusieurs virements vers notre compte, en indiquant votre nom et prénom dans l’objet du
                    virement<br/><br/>

                    3. Par chèque, en indiquant dans le courrier que le don est pour votre dossier<br/><br/>

                    A chaque fois que nous recevons un versement à destination de votre projet, nous vous envoyons une
                    déclaration de donateur à remplir et à nous retourner afin que nous puissions envoyer le(s) reçu(s)
                    au(x) donateur(s).<br/>
                </div>
            }

            var message = <Alert
                message="Action requise de votre part : Versement du solde frais d'inscription"
                description={description}
                type="warning"
                showIcon
                closable
            />
        }


        return <div>
            <Space direction="vertical">
                <div>
                    {message}
                </div>
                <Collapse accordion defaultActiveKey={['1']}>
                    <Panel header="Versement sécurisé par carte bancaire" key="1">
                        <div>
                            Pour un versement par carte bancaire, veuillez cliquer sur une des icônes ci-dessous.
                            Comptez entre 1 et 4 jours ouvrés pour la validation de votre versement par nos services.
                            Passé ce délai, si vous n’avez pas reçu de mail de confirmation de réception, merci de nous
                            contacter.
                        </div>
                        <div>{formCB}</div>
                    </Panel>
                    <Panel header="Versement par virement bancaire" key="2">
                        <div>
                            <div>
                                Afin d'effectuer un versement par virement bancaire, utilisez nos coordonnées bancaires,
                                que vous allez recevoir par mail, et procédez au virement via votre banque en ligne ou
                                dans votre agence bancaire.
                                <br/><br/>
                                Attention : il ne s'agit pas d'un prélèvement, vous devez effectuer la démarche auprès
                                de votre banque.
                                <br/><br/>
                                Vous recevrez un mail de confirmation dès réception de votre versement sur notre compte.
                                Cette opération peut durer quelques jours. Si vous n'avez pas reçu le mail de
                                confirmation de réception dans un délai de 7 jours, veuillez nous contacter.

                            </div>
                            <br/>
                            <Popconfirm placement="right" title="Désiez-vous confirmer votre choix ?"
                                        onConfirm={() => this.validePayement('virement', amount)} okText="Valider"
                                        cancelText="Annuler">
                                <Button type="primary">Valider mon versement par RIB</Button>
                            </Popconfirm>
                        </div>
                    </Panel>
                    <Panel header="Versement par chèque" key="3">
                        <div>
                            <div>
                                Si vous souhaitez faire un versement par chèque bancaire, vous disposez d’un délai de 15
                                jours pour l’envoi et la réception du chèque. Au-delà de ce délai, sans réception de
                                votre/vos chèque(s), le mode de versement sera annulé et il faudra recommencer.<br/>
                                Pour la validation de votre versement par chèque, comptez environ 15 jours après
                                réception de votre chèque. Vous recevrez ensuite un mail de confirmation de réception.
                                Si vous n'avez pas reçu de mail de confirmation passé un délai de 15 jours, veuillez
                                prendre contact avec nos services.<br/>
                                <br/>
                                <Text strong>
                                    Attention: le versement par chèque n'est pas possible si votre programme commence
                                    dans
                                    moins de 8 semaines. Dans ce cas, merci de faire le versement par carte bancaire ou
                                    par
                                    virement bancaire.
                                </Text>
                            </div>
                            <br/>
                            <Popconfirm placement="right" title="Désiez-vous confirmer votre choix ?"
                                        onConfirm={() => this.validePayement('cheque', amount)} okText="Valider"
                                        cancelText="Annuler">
                                <Button type="primary">Valider mon versement par chèque</Button>
                            </Popconfirm>
                        </div>
                    </Panel>
                </Collapse>
            </Space>
        </div>
    }

    reloadStep = () => {
        console.log(this.state.statut)
        this.setState({
            zone1: this.stepDisplay()
        })

    }

    handleChange = async (value) => {
        await await axios.post(API_URI + '/api_member/update-order-statut', {id: this.state.id, statut: value});
        await this.setState({statut: value})
        await this.setState({zone1: this.stepDisplay()})
    }

    confirmationPayement = (typePayement) => {

        // mise à jour du statut après paiement
        this.setState({
            statut: this.state.statut + 1,
        })

        if (typePayement == 'virement') {
            var title = <div>Versement en attente de validation</div>
            var msg = <div>
                Nous vous remercions pour votre don et votre confiance.<br/><br/>

                Dès réception de votre versement sur notre compte, nous validerons votre espace Globalong et vous
                enverrons
                un accusé de réception.<br/><br/>

                <div style={{color: "#b81556", textAlign: "center"}}>Versement par virement bancaire :</div>
                <br/><br/>

                <b>Il ne s'agit pas</b> d'un prélèvement - veuillez contacter votre établissement bancaire pour
                effectuer
                votre virement sur le compte dont les coordonnées (RIB) sont les suivantes :

                <br/><br/>
                <table border="2" style={{borderCollapse: "collapse", borderColor: "#b81556", width: "100%"}}>
                    <tr>
                        <th>Banque</th>
                        <th>Indicatif</th>
                        <th>Numéro de compte</th>
                        <th>Clé RIB</th>
                        <th>Domiciliation</th>
                    </tr>
                    <tr>
                        <td>30002</td>
                        <td>03050</td>
                        <td>0000070811N</td>
                        <td>27</td>
                        <td>CL PEROLS (0350)</td>
                    </tr>
                </table>

                <br/><br/>

                <i>Titulaire du compte :</i> <b>Globalong</b>
                <br/>
                <strong>
                    IDENTIFICATION INTERNATIONALE<br/>
                    IBAN : FR94 3000 2030 5000 0007 0811 N27<br/>
                    Code B.I.C : CRLYFRPP
                </strong>
                <br/><br/>
                Dans le libellé du virement bancaire, merci de noter votre nom, prénom ainsi que la destination et
                référence de votre projet.
                <br/><br/>
                Attention : si des frais liés au change ou à la transaction bancaire s'appliquent, vous devez les
                prendre à votre charge.
                <br/><br/>
                En cas de questions, contactez-nous par mail à l’adresse <a
                href="mailto:info@globalong.com">info@globalong.com</a>
            </div>
        } else if (typePayement == 'cheque') {
            var title = <div>Versement en attente de validation</div>
            var msg = <div>
                Nous vous remercions pour votre don et votre confiance.<br/><br/>

                Dès réception de votre versement sur notre compte, nous validerons votre espace Globalong et vous
                enverrons
                un accusé de réception.<br/><br/>

                <div style={{color: "#b81556", textAlign: "center"}}>Versement par chèque (chèques français uniquement)
                    :
                </div>
                <br/><br/>

                En cas de versement par chèque, veuillez envoyer votre/vos chèque(s), préalablement libellé(s) au nom de
                "Globalong", à l’adresse suivante :
                <br/><br/>
                <strong>Association GlobAlong<br/>
                    14 Rue Font Martin<br/>
                    34 470 Pérols</strong>
                <br/><br/>

                Veuillez noter que nous acceptons uniquement des chèques provenant d’un établissement bancaire français.
                Nous ne pouvons pas accepter les chèques étrangers. Attention : le versement par chèque n'est pas
                possible si votre programme commence dans moins de 8 semaines, à cause des délais de traitement. Dans ce
                cas, merci de faire le versement par carte bancaire ou par virement bancaire.

                <br/><br/>

                Pensez à marquer votre nom et prénom ainsi que la référence de votre dossier au dos du chèque.
                <br/><br/>
                En cas de questions, contactez-nous par mail à l’adresse <a
                href="mailto:info@globalong.com">info@globalong.com</a>
            </div>
        }


        return <div>
            <Result
                status="success"
                title={title}
                subTitle={msg}
                extra={[
                    <Button type="primary" key="console" onClick={this.reloadStep}>
                        Revenir aux étapes
                    </Button>,
                ]}
            />
        </div>
    }

    stepDisplay = () => {

        var zonePaiement1 = "Versement de la cotisation d'adhésion / Versement de l'acompte";

        if (this.state.statut == 4) {
            var zonePaiement1 = <div>
                Versement de la cotisation d'adhésion / Versement de l'acompte : <br/>
                <div>{this.modulePayement(20000, 'prempaiement')}</div>
            </div>;
        }

        var zonePaiement2 = "Versement du don restant";

        if (this.state.statut == 7) {
            var zonePaiement2 = <div>
                Versement du don restant : <br/>
                <div>{this.modulePayement((this.state.soldeDu * 100), 'solde')}</div>
            </div>;
        }

        return <div>
            <Timeline>
                <Timeline.Item color={(this.state.statut == 11) ? 'green' : 'gray'}>Candidature à
                    compléter</Timeline.Item>
                <Timeline.Item color={(this.state.statut == 2) ? 'green' : 'gray'}>Candidature en cours
                    d'étude</Timeline.Item>
                <Timeline.Item color={(this.state.statut == 3) ? 'green' : 'gray'}>Candidature acceptée</Timeline.Item>
                <Timeline.Item color={(this.state.statut == 4) ? 'green' : 'gray'}>{zonePaiement1}</Timeline.Item>
                <Timeline.Item color={(this.state.statut == 5) ? 'green' : 'gray'}>Versement de la cotisation
                    d’adhésion/de l'acompte en attente de validation par nos services (1 à 7 jours selon le mode de
                    versement) </Timeline.Item>
                <Timeline.Item color={(this.state.statut == 6) ? 'green' : 'gray'}>Premier versement effectué et validé
                    par nos services</Timeline.Item>
                <Timeline.Item color={(this.state.statut == 7) ? 'green' : 'gray'}>{zonePaiement2}</Timeline.Item>
                <Timeline.Item color={(this.state.statut == 8) ? 'green' : 'gray'}>Versement du don restant en attente
                    de validation par nos services (1 à 7 jours selon le mode de versement)</Timeline.Item>
                <Timeline.Item color={(this.state.statut == 9) ? 'green' : 'gray'}>Versement du don
                    effectué et validé par nos services</Timeline.Item>
                <Timeline.Item color={(this.state.statut == 10) ? 'green' : 'gray'}>Projet
                    finalisé</Timeline.Item>
            </Timeline>
        </div>
    }

    render() {

        if (this.state.dataCmd) {
            return (
                <div>
                    <Space direction="vertical">
                        <Title level={3}>Mes étapes</Title>
                        <Paragraph>
                            Statut de votre inscription & versement
                        </Paragraph>

                        <br/>


                        {this.state.zone1}

                    </Space>
                </div>
            );
        } else {
            return <div></div>
        }


    }
}

export default OrderDashboard;