import React from "react";
import {Row, Col, Typography, Divider, Switch, Select, DatePicker, Space} from 'antd';
import Header from "./inc/Header";
import FIl from "./inc/FIl"
import './css/step.css'
import Recap from "./inc/Recap";
import {StickyContainer, Sticky} from 'react-sticky';
import Register from "../member/Register";
import {FooterApps} from "./inc/FooterApps";

const {Title, Text} = Typography;
const {Option} = Select;

class Step3 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            paramHeader: {}
        }
    }

    render() {
        return (
            <div>
                <Header paramHeader={this.state.paramHeader}/>
                <StickyContainer>
                    <Row>
                        <Col id="stepScreen" span={6} className="contentStep">

                            <div className="sideBar">
                                <Sticky>
                                    {({
                                          style,
                                          isSticky,
                                          wasSticky,
                                          distanceFromTop,
                                          distanceFromBottom,
                                          calculatedHeight
                                      }) => (
                                        <div style={style}>
                                            <FIl step={2}/>
                                        </div>
                                    )}
                                </Sticky>
                            </div>
                        </Col>
                        <Col lg={12} className="contentStep">
                            <div className="centerTxt">
                                <Title level={2}>Création de mon compte</Title>
                                <Text type="secondary">Nous y sommes presque !</Text>
                            </div>
                            <Divider/>
                            <Register/>

                            <Divider dashed/>
                            <FooterApps />
                        </Col>
                        <Col lg={6} id="RecapScreen" className="contentStep">
                            <div className="sideBar">
                                <Sticky>
                                    {({
                                          style,
                                          isSticky,
                                          wasSticky,
                                          distanceFromTop,
                                          distanceFromBottom,
                                          calculatedHeight
                                      }) => (
                                        <div style={style}>
                                            <Recap
                                                construct={false}
                                                prev="/order/step1"
                                            />
                                        </div>
                                    )}
                                </Sticky>

                            </div>
                        </Col>
                    </Row>
                </StickyContainer>
            </div>
        )
    }

}

export default Step3