import React, {useState} from 'react';
import {SmileOutlined} from '@ant-design/icons';
import {
    Form,
    Input,
    Button,
    Alert,
    Typography,
    Collapse,
    notification,
    Checkbox,
} from 'antd';
import axios from "axios";
import {API_URI} from "../../constant/Config";

const {Panel} = Collapse;
const {TextArea} = Input;
const {Title, Text} = Typography;


class OrderFlightNoNecessary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataCmd: this.props.dataCmd,
            filghtType: this.props.filghtType,
            libTexte: false,
            datasLoad: false,
            checkboxVolInfo: false,
            colSqlCheckbox: false,
            openColspace: [],
        }
    }

    async componentDidMount() {


        if (this.state.filghtType === 0) {
            // vol aller
            this.state.libTexte = {
                'header': 'J\'arrive dans le pays avant la mission et n\'ai pas besoin d\'accueil à l\'aéroport',
                'infos': 'Information sur votre arrivée pour le vol aller. Merci de fournir les informations de vol.',
                'motifData': this.props.dataCmd.volsInfos.getVolAllerNonNecessaireMotif,
            }
            await this.setState({colSqlCheckbox: this.props.dataCmd.volsInfos.getVolAllerNonNecessaire});


        } else if (this.state.filghtType === 1) {
            //vol retour
            this.state.libTexte = {
                'header': 'Je reste dans le pays après la mission et n\'ai pas besoin d\'être raccompagné à l\'aéroport',
                'infos': 'Information sur votre arrivée pour le vol retour. Merci de fournir les informations de vol.',
                'motifData': this.props.dataCmd.volsInfos.getVolRetourNonNecessaireMotif,
            }
            await this.setState({colSqlCheckbox: this.props.dataCmd.volsInfos.getVolRetourNonNecessaire});
        }




        await console.log(this.state.colSqlCheckbox)

        if (this.state.colSqlCheckbox == '1') {
            await this.setState({
                checkboxVolInfo: true,
                openColspace : ['1'],
            })
        }

        await this.setState({datasLoad: true})
    }


    sendForm = async values => {
        var token = localStorage.getItem('membre_token');
        await axios.post(API_URI + '/api_member/flightmotif', {
            id: this.state.dataCmd.data.id,
            motif: values.motif,
            action: 'update',
            typeVol: this.state.filghtType,
        }, {
            headers: {
                'Authorization': token
            }
        });

        notification.open({
            message: 'Motif mis à jour',
            description:
                "Nous vous confirmons la mise à jour du motif.",
            icon: <SmileOutlined style={{color: '#108ee9'}}/>,
        });
    }

    sendFormCancel = async () => {
        var token = localStorage.getItem('membre_token');
        await axios.post(API_URI + '/api_member/flightmotif', {
            id: this.state.dataCmd.data.id,
            action: 'cancel',
            typeVol: this.state.filghtType,
        }, {
            headers: {
                'Authorization': token
            }
        });

        notification.open({
            message: 'Motif annulé',
            description:
                "Nous vous confirmons l'annulation du modif.",
            icon: <SmileOutlined style={{color: '#108ee9'}}/>,
        });
    }

    getFormFull = () => {
        return <>
            <Form
                labelCol={{
                    span: 18,
                }}
                wrapperCol={{
                    span: 14,
                }}
                layout="vertical"
                onFinish={this.sendForm}
            >

                <Form.Item
                    label="Merci de nous donner des détails supplémentaires"
                    name={['motif']}

                >
                    <TextArea rows={4} defaultValue={this.state.libTexte.motifData}/>
                </Form.Item>

                <Form.Item>
                    <Button htmlType="submit" type="primary">Valider</Button> <Button danger
                                                                                      onClick={this.sendFormCancel}>Annuler
                    mon
                    motif </Button>
                </Form.Item>
            </Form>
        </>
    }

    onChangeInfosVolRetourAller = async (key) => {
        if (key.length == 0) {
            this.setState({
                checkboxVolInfo: false,
            });
            await this.sendFormCancel();
        } else if (key.length == 1) {
            this.setState({
                checkboxVolInfo: true,
            })
        }
    }

    render() {

        return (
            <>
                <Title level={3}>Informations supplémentaires</Title>
                <Alert
                    message={this.state.libTexte.infos}
                    description="Cliquez ci-dessous si vous êtes concerné."
                    type="info"
                    showIcon
                />
                <br/>
                <Collapse defaultActiveKey={this.state.openColspace} onChange={this.onChangeInfosVolRetourAller}>
                    <Panel header={<div><Checkbox
                        checked={this.state.checkboxVolInfo}></Checkbox> {this.state.libTexte.header}</div>}
                           key="1">
                        <div>

                            {this.state.datasLoad ? this.getFormFull() : ''}

                        </div>
                    </Panel>
                </Collapse>
            </>
        )
    }
}

export default OrderFlightNoNecessary;