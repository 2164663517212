import React from 'react';
import MenuDashboard from "./Menu";
import "./css/dashboard.css";
import logoGLob from "./../../img/logoglob.png"
import ListeCmd from "./ListeCmd";
import {Redirect, Link} from "react-router-dom";
import {
    UserOutlined,
    SettingFilled,
    SmileOutlined,
    SyncOutlined,
    LoadingOutlined,
    SolutionOutlined,
    MessageOutlined, LikeOutlined, StarOutlined, FileTextOutlined, CalendarOutlined
} from '@ant-design/icons';

import {
    Button,
    Typography,
    Space,
    Alert,
    List,
} from 'antd';
import axios from "axios";
import moment from 'moment';
import 'moment-timezone';
import {API_URI} from "../../constant/Config";

const {Title, Text} = Typography;

const IconText = ({icon, text}) => (
    <Space>
        {React.createElement(icon)}
        {text}
    </Space>
);


class DashboardCmdIncompletes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataCMd: false,
        }
    }

    async componentDidMount() {
        let dataCmd = await this.getlisteCmd()
        await this.setState({dataCMd: dataCmd})
    }

    getlisteCmd = async () => {
        var token = localStorage.getItem('membre_token');
        let dataCmd = await axios.post(API_URI + '/api_member/list_order', {
            membre_id: localStorage.getItem('membre_id'),
            filter: 'acompleter'
        }, {
            headers: {
                'Authorization': token
            }
        });

        return dataCmd.data;
    }

    display = () => {
        if (this.state.dataCMd) {

            var dataCMd = this.state.dataCMd.liste;
            const listDataWaiting = [];
            var dataCMdArray = Object.keys(dataCMd).reverse()
            var dataOK = false;
            var dataApi = dataCMdArray.map(function (key) {

                dataOK = true;

                listDataWaiting.push({
                    href: '/order/complete/' + dataCMd[key]['id'],
                    title: <Title level={4}><LoadingOutlined/> {dataCMd[key]['titre']}</Title>,
                    description:
                        dataCMd[key]['cat'] + ' / ' + dataCMd[key]['pays'],
                    content:
                        <Alert
                            message="Nous avons besoin de vous"
                            description="Une toute dernière étape avant la soumission de votre dosser."
                            type="warning"
                            showIcon
                        />,
                    cover: dataCMd[key]['imgProg'],
                    ref: 'Réf. #' + dataCMd[key]['ref'],
                    dateStart: 'Début le ' + moment(dataCMd[key]['dateStart'].date).format('DD/MM/YYYY'),
                    id: dataCMd[key]['id']
                })
            });


            if (dataOK) {
                return <div>
                    <Title level={2}><SmileOutlined/> Finaliser la candidature</Title><List
                    itemLayout="vertical"
                    size="large"

                    dataSource={listDataWaiting}

                    renderItem={item => (
                        <List.Item
                            key={item.title}
                            actions={[
                                <Link to={'/order/complete/' + item.id}><Button type="primary" icon={<LikeOutlined/>}
                                                                                size="large">Cliquez ici pour finaliser
                                    ma candidature</Button></Link>,
                                <IconText icon={FileTextOutlined} text={item.ref} key="list-vertical-star-o"/>,
                                <IconText icon={CalendarOutlined} text={item.dateStart} key="list-vertical-like-o"/>,

                            ]}
                            extra={
                                item.cover ? <img
                                    width={272}
                                    alt="logo"
                                    src={item.cover}
                                /> : null

                            }
                        >
                            <List.Item.Meta
                                title={<a href={item.href}>{item.title}</a>}
                                description={item.description}
                            />
                            {item.content}
                        </List.Item>
                    )}
                />
                </div>
            } else {
                return <div></div>
            }

        } else {
            return <Alert
                message="Aucune inscription en attente"
                description="Vous n'avez pas d'inscription en attente de soumission"
                type="info"
                showIcon
            />
        }
    }


    render() {
        return (
            <div>
                {this.display()}
            </div>
        );
    }
}

export default DashboardCmdIncompletes;



