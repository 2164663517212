import React from 'react';
import {List, Typography} from 'antd';
import {
    FileTwoTone,
} from '@ant-design/icons';
import axios from "axios";
import {API_URI} from "../../constant/Config";

const {Title, Paragraph, Text, Link} = Typography;

const data = [<div><FileTwoTone/> <Link href="https://ant.design" target="_blank">Votre bulletin modifié PDF :
    nomdufichier.pdf</Link></div>,
    <div><FileTwoTone/> <Link href="https://ant.design" target="_blank">Formulaire easyjet PDF : nomdufichier.pdf</Link>
    </div>,];

class OrderFilesDisp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {dataFiles: [], idCmd: this.props.idCmd, dataCmd: this.props.dataCmd};
    }

    componentDidMount() {
        this.loadList();
    }

    loadList = async () => {
        const postData = {
            id: this.state.idCmd,
            typeList: 'documents_mise_a_dispo',
        }
        var token = localStorage.getItem('membre_token');
        const dataList = await axios.post(API_URI + '/api_member/get_file_order', postData, {
            headers: {
                'Authorization': token
            }
        });

        const listeArr = Object.entries(dataList.data.data);

        var arrayListe = [];
        const listeArrReturn = listeArr.map((fileDisp) =>
            arrayListe.push(<div><FileTwoTone/> <Link
                href={"https://crm.globalong.com" + fileDisp[1].personFilescolFullath + fileDisp[1].personFilescolNameFileDrive}
                target="_blank">{fileDisp[1].personFilescolNameFile}</Link>
            </div>)
        );
        this.setState({dataFiles: arrayListe})

    }

    render() {

        // La commande doit être => au statut 6 qui est la validation du premier paiement
        if (this.state.dataFiles.length > 0 && this.state.dataCmd.statut.lib.id > 5) {
            return <div>
                <Text strong>Documents mise à disposition :</Text>
                <List
                    size="small"
                    dataSource={this.state.dataFiles}
                    renderItem={item => <List.Item>{item}</List.Item>}
                />
            </div>
        } else {
            return <div></div>
        }
    }
}

export default OrderFilesDisp;