import React from 'react';

import "./css/ident.css";
import logoGLob from "./../../img/logoglob.png"
import axios from 'axios';
import {API_URI} from "../../constant/Config"
import {Redirect} from "react-router-dom";
import {ArrowLeftOutlined} from '@ant-design/icons';

import {Form, Input, Button, Checkbox, Row, Col, Alert,PageHeader} from 'antd';
import {render} from "react-dom";
import {fas} from "@fortawesome/free-solid-svg-icons";

const layout = {
    labelCol: {span: 5},
    wrapperCol: {span: 19},
};
const tailLayout = {
    wrapperCol: {offset: 5, span: 19},
};


const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
};


export default class Ident extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirection: false,
            message: false,
            urlRedirect: (this.props.urlRedirect) ? this.props.urlRedirect : '/ident/dashboard',
            alreadyIdent: false,
            zoneAff: false,
            destroy:  (this.props.destroy) ? this.props.destroy : false,
        }
    }

    componentDidMount() {
        // appliquer le destroy
        if(this.state.destroy){
            localStorage.removeItem('membre_token');
            localStorage.removeItem('membre_id');
            localStorage.removeItem('membre_nom');
            localStorage.removeItem('membre_prenom');
            localStorage.removeItem('membre_menu');
        }

        // auto connecter
        let idMembre = localStorage.getItem('membre_id');

        if (idMembre) {
            this.setState({redirection: true, urlRedirect: '/ident/dashboard'})
        }else{
            localStorage.removeItem('membre_token');
            localStorage.removeItem('membre_id');
            localStorage.removeItem('membre_nom');
            localStorage.removeItem('membre_prenom');
            localStorage.removeItem('membre_menu');
        }
        this.setState({
            zoneAff: this.formLogin()
        })
    }

    onFinish = async values => {

        await axios.post(API_URI + '/api_member/ident', {
            "email": values.email,
            "password": values.pwd
        })
            .then(res => {

                if (res.data.identStep == 'access') {
                    localStorage.setItem('membre_token', res.data.membre_token);
                    localStorage.setItem('membre_id', res.data.membre_id);
                    localStorage.setItem('membre_nom', res.data.membre_nom);
                    localStorage.setItem('membre_prenom', res.data.membre_prenom);
                    this.setState({redirection: true});
                } else {
                    // pas d'accès
                    var message =
                        <div style={{padding: "10px"}}>
                            <Alert
                                message="Erreur d'accès"
                                description="Merci de vérifier votre email ou mot de passe."
                                type="error"
                                showIcon
                            />
                        </div>

                    this.setState({
                        message: message
                    })
                }

            })
    };

    formPasseLost = (message) => {

        return <div>
            <Row>
                <Col md={24}>

                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{remember: true}}
                        onFinish={this.sendPwdLost}
                        onFinishFailed={onFinishFailed}
                        className="formIdent"
                    >
                        <Form.Item name="email" label="Email"
                                   rules={[{type: 'email', message: 'Veuillez indiquer votre email.'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Envoyer mon nouveau mot de passe
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col md={19} offset={5}>
                    <Button type="link" onClick={() => {
                        this.setState({zoneAff: this.formLogin()})
                    }}><ArrowLeftOutlined/> Retour </Button>
                </Col>
            </Row>
        </div>
    }

    formPwdLost = () => {
        this.setState({zoneAff: this.formPasseLost()})
    }

    sendPwdLost = async (values) => {

        await axios.post(API_URI + '/api_member/sendpwd', {
            "email": values.email,
        })
            .then(res => {

                if (res.data.statut == 0) {
                    var message =
                        <div style={{padding: "10px"}}>
                            <Alert
                                message="Erreur d'accès"
                                description={res.data.msg}
                                type="error"
                                showIcon
                            />
                        </div>
                } else if (res.data.statut == 1) {
                    var message =
                        <div style={{padding: "10px"}}>
                            <Alert
                                message="Mot de passe envoyé"
                                description={res.data.msg}
                                type="success"
                                showIcon
                            />
                        </div>
                }
                this.setState({
                    message: message,
                })

            })


    }


    formLogin = () => {

        return <div>
            <Row>
                <Col md={24}>
                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{remember: true}}
                        onFinish={this.onFinish}
                        onFinishFailed={onFinishFailed}
                        className="formIdent"
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{required: false, message: 'Veuillez indiquer votre email.'}]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Passe"
                            name="pwd"
                            rules={[{required: false, message: 'Votre passe est non renseigné.'}]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Identification
                            </Button>

                            <Button type="link" onClick={this.formPwdLost}>Mot de passe oublié ?</Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col md={19} offset={5}>

                    <a href="/order/step1">Candidater pour un premier projet</a>
                </Col>
            </Row>
        </div>
    }

    goToOrder = () => {
        this.setState({
            urlRedirect: '/order/new',
            redirection:true
        })
    }
    goToSite = () => {
        window.location.href = "https://www.globalong.com";
    }


    render() {

        if (this.state.redirection) {
            //Affichage de la redirection
            return <Redirect to={this.state.urlRedirect}/>;

        } else {

            return (
                <div className="bgLogin">
                    <Row className="fullHeight">


                        <Col lg={8} className="loginScreen">
                            <PageHeader
                                className="site-page-header"
                                onBack={this.goToSite}
                                title="Globalong"
                                subTitle="Revenir sur le site"
                                extra={[
                                    <Button type="primary" size="large" onClick={this.goToOrder}>Candidater pour un projet</Button>,

                                ]}
                            />

                            <Row justify="space-around" align="middle" className="fullHeight">
                                <Col span={24}>
                                    <div style={{textAlign: "center", padding: "10px"}}>
                                        <img src={logoGLob}/></div>

                                    <Row>
                                        <Col lg={24}>
                                            {this.state.message}
                                        </Col>
                                    </Row>

                                    {this.state.zoneAff}

                                </Col>
                            </Row>
                        </Col>
                        <Col span={16}></Col>
                    </Row>
                </div>
            )
        }


    }

}
