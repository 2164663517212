import React from "react";
import {Steps} from 'antd';
import { StickyContainer, Sticky } from 'react-sticky';

const {Step} = Steps;

class FIl extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            step:this.props.step
        }
    }
    render() {
        return (
            <div style={{textAlign: "center"}}>

                    <Steps direction="vertical" current={this.state.step}>
                        <Step title="Etape 1" description="Je choisis mon projet"/>
                        <Step title="Etape 2" description="Compte Globalong"/>
                        <Step title="Etape 3" description="Création de mon dossier"/>
                    </Steps>

            </div>
        )
    }
}

export default FIl;