import React, {Component} from 'react';
import {Card, Select, Input, Typography, Form,Divider} from 'antd';
import axios from "axios";
import {API_URI} from "../../../constant/Config";

const {Option} = Select;
const {Text, Link} = Typography;

class QuestionBloc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSousQuest: false,
            repPers: false,
            defaultValueRep: 'Choisir ma réponse',
            repPersDefaultval: null
        }
    }

    getSousQuest = (idParent) => {
        let data = axios.get(API_URI + '/api_member/liste_sous_questions?parent=' + idParent);
        return data
    }

    async componentDidMount() {

        let sousQuest = await this.getSousQuest(this.props.dataQuestion['Id'])


        // si commande en cour
        if (localStorage.getItem('order_question')) {
            const quest = JSON.parse(localStorage.getItem('order_question'));
            if (quest[this.props.dataQuestion['Id']]) {
                const idSouquest = quest[this.props.dataQuestion['Id']].sousQuestion.id;

                if (quest[this.props.dataQuestion['Id']].sousQuestionPers) {
                    const repPers = quest[this.props.dataQuestion['Id']].sousQuestionPers.rep
                    this.setState({repPers: true, repPersDefaultval: repPers})
                }
                this.setState({defaultValueRep: parseInt(idSouquest)})
            } else {
                await this.creatEmptyQuest();
            }
        } else {
            await this.creatEmptyQuest();
        }

        this.setState({dataSousQuest: sousQuest.data})
        //localStorage.removeItem('order_question');

        //console.log(sousQuest.data)

        //repPersAttendu


    }

    creatEmptyQuest = async () => {
        const quest = JSON.parse(localStorage.getItem('order_question'));
        var obj1 = {}
        obj1[this.props.dataQuestion['Id']] = false;
        const obj3 = {...quest, ...obj1}
        var questJson = JSON.stringify(obj3);
        localStorage.setItem("order_question", questJson);
    }

    selectOption = async (idQuest, event) => {
        var questionStorage = localStorage.getItem('order_question');
        var questionStorageJson = JSON.parse(questionStorage);

        var obj1 = {}
        obj1[this.props.dataQuestion['Id']] = {
            question: this.props.dataQuestion['Question'],
            sousQuestion: {
                id: idQuest,
                reponse: event.children,
                repPerso : this.state.dataSousQuest[idQuest].RepPers
            }
        }

        // affiche le detail de la sous question
        //console.log(this.state.dataSousQuest[idQuest].RepPers)



        const obj3 = {...questionStorageJson, ...obj1}

        var questJson = JSON.stringify(obj3);
        localStorage.setItem("order_question", questJson);

        var sdsdfsf = localStorage.getItem('order_question');


        if (event['data-persrep'] == 1) {
            this.setState({
                repPers: true,
            })
        }
    }

    repPers = (e) => {

        var questionStorage = localStorage.getItem('order_question');
        var questionStorageJson = JSON.parse(questionStorage);


        var obj1 = {}
        obj1[this.props.dataQuestion['Id']] = {
            ...questionStorageJson[this.props.dataQuestion['Id']],
            sousQuestionPers: {
                rep: e.target.value
            }

        }
        const obj3 = {...questionStorageJson, ...obj1}
        var questJson = JSON.stringify(obj3);
        localStorage.setItem("order_question", questJson);

        var sdsdfsf = localStorage.getItem('order_question');
        var sss = JSON.parse(sdsdfsf);


    }

    render() {
        const dataSousQuest = this.state.dataSousQuest
        const id = this.props.dataQuestion['Id'];
        if (dataSousQuest) {
            var liste = Object.keys(dataSousQuest).map(function (key) {
                return <Option
                    data-persrep={dataSousQuest[key]['RepPers']}
                    value={dataSousQuest[key]['Id']}>
                    {dataSousQuest[key]['Question']}
                </Option>
            });

            if (this.state.repPers) {
                var repPersZone = <div>
                    <Form.Item
                        validateStatus="warning"
                        help="Merci de préciser votre réponse"
                    >
                        <Input
                            onChange={this.repPers}
                            placeholder=""
                            defaultValue={this.state.repPersDefaultval}
                        />
                    </Form.Item>
                </div>
            } else {
                var repPersZone = ''
            }

            var validateStatus = '';
            var help = '';
            if (this.props.changeStepSend) {
                if (this.props.changeStepSend[id] === false) {
                    var validateStatus = 'error';
                    var help = 'Cette information est obligatoire';
                }
            }

            return (
                <div>
                    <div>
                        <p>
                            <br />
                            <Text strong>{this.props.dataQuestion['Question']}</Text> <Text type="danger">*</Text>
                        </p>
                        <p>
                            <Form.Item
                                validateStatus={validateStatus}
                                help={help}
                            >
                                <Select onSelect={this.selectOption} rules={[{required: true}]}
                                        defaultValue={this.state.defaultValueRep}
                                        style={{width: "100%"}}>
                                    {liste}
                                </Select>
                            </Form.Item>

                        </p>
                        {repPersZone}

                    </div>
                    <Divider dashed />
                </div>
            );
        } else {
            return <div>Sous question non disponibles</div>
        }
    }
}

export default QuestionBloc;