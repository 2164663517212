import React from 'react';
import "./css/dashboard.css";
import {API_URI} from "../../constant/Config"
import {Typography, Form, Input, Button, Checkbox, Space, DatePicker, Divider, Row, Col, notification} from "antd";
import axios from "axios";
import OrderFlightNoNecessary from "./OrderFlightNoNecessary";
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import moment from 'moment-timezone';
moment.tz.setDefault("Europe/Paris");
//import moment from "moment";


const {RangePicker} = DatePicker;
const {Title,Text} = Typography;

class OrderFlight extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            type: this.props.type,
            dataVols: false,
            dataCmd: this.props.dataCmd,
        }
    }

    async componentDidMount() {
        var token = localStorage.getItem('membre_token');
        const vols = await axios.post(API_URI + '/api_member/get_flights', {id: this.state.id, type: this.state.type}, {
            headers: {
                'Authorization': token
            }
        });




        await this.setState({dataVols: vols})
    }

    onFinish = async values => {
        const postData = {
            ...values,
            id: this.state.id,
            type: this.state.type,
        }
        var token = localStorage.getItem('membre_token');
        console.log(postData)

        const res = await axios.post(API_URI + '/api_member/add_flights', postData, {
            headers: {
                'Authorization': token
            }
        });


        const vols = await axios.post(API_URI + '/api_member/get_flights', {id: this.state.id, type: this.state.type}, {
            headers: {
                'Authorization': token
            }
        });
        await this.setState({dataVols: vols})

        notification.info({
            message: `Notification `,
            description: 'Vol(s) ajouté(s).',
        });
    }

    updateInfosVols = async (values, id) => {
        const postData = {
            ...values,
            id: id,
            idCmd: this.state.dataVols.data.idOrder,
        }
        var token = localStorage.getItem('membre_token');
        await axios.post(API_URI + '/api_member/update_flights', postData, {
            headers: {
                'Authorization': token
            }
        });

        notification.info({
            message: `Notification `,
            description: 'Votre vol est à jour avec les nouvelles informations.',
        });
    }

    delVol = async id => {
        var token = localStorage.getItem('membre_token');
        const res = await axios.post(API_URI + '/api_member/del_flights', {
            id: id,
            idCmd: this.state.dataVols.data.idOrder
        }, {
            headers: {
                'Authorization': token
            }
        });
        const vols = await axios.post(API_URI + '/api_member/get_flights', {id: this.state.id, type: this.state.type}, {
            headers: {
                'Authorization': token
            }
        });
        await this.setState({dataVols: vols})
    }

    bocVolss = () => {
        const datavol = this.state.dataVols;
        const listItems = datavol.data.dataFormat.map((item, index) =>
            <div>
                <Form onFinish={(data, id) => this.updateInfosVols(data, item.idvolEscales)}>
                    <Form.Item
                        label="Numéro du vol"
                        name="volEscalescolNumeroVol"
                        rules={[{required: true, message: 'Information manquantes'}]}
                        initialValue={item.volEscalescolNumeroVol}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Compagnie du vol"
                        name="volEscalescolCompagnieVol"
                        rules={[{required: true, message: 'Information manquantes'}]}
                        initialValue={item.volEscalescolCompagnieVol}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Date et heure de décollage "
                        name="volEscalescolDatetimeDepart"
                        rules={[{required: true, message: 'Information manquantes'}]}
                        initialValue={moment(item.volEscalescolDatetimeDepart)}
                    >
                        <DatePicker
                            showTime={{format: 'HH:mm'}}
                            format="YYYY-MM-DD HH:mm"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Date et heure d'arrivée"
                        name="volEscalescolDatetimeArrivee"
                        rules={[{required: true, message: 'Information manquantes'}]}
                        initialValue={moment(item.volEscalescolDatetimeArrivee)}
                    >
                        <DatePicker
                            showTime={{format: 'HH:mm'}}
                            format="YYYY-MM-DD HH:mm"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Aéroport de provenance"
                        name="volEscalescolAeroportProvenance"
                        rules={[{required: true, message: 'Information manquantes'}]}
                        initialValue={item.volEscalescolAeroportProvenance}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Aéroport d'arrivée"
                        name="volEscalescolAeroportArrivee"
                        rules={[{required: true, message: 'Information manquantes'}]}
                        initialValue={item.volEscalescolAeroportArrivee}
                    >
                        <Input/>
                    </Form.Item>


                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Mettre à jour
                        </Button>

                        <Button type="primary" danger style={{marginLeft: 5}}
                                onClick={(id) => this.delVol(item.idvolEscales)}>
                            Supprimer
                        </Button>
                    </Form.Item>
                </Form>
                <Divider dashed={true}/>
            </div>
        );
        return listItems
    }

    render() {
        return (
            <div>

                <OrderFlightNoNecessary dataCmd={this.state.dataCmd} filghtType={this.state.type} />

                <br />

                <Title level={3}>Ajouter un vol</Title>
                <Form name="dynamic_form_nest_item" onFinish={this.onFinish} autoComplete="off">
                    <Form.List name="vols">
                        {(fields, {add, remove}) => (
                            <div>
                                {fields.map(field => (
                                    <Row>
                                        <Col span={24}>
                                            <Row justify="start">
                                                <Col span={24}>
                                                    <Text strong>Le N° et la compagnie</Text>
                                                    <br />
                                                    <Space align="center">
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'volEscalescolNumeroVol']}
                                                            fieldKey={[field.fieldKey, 'volEscalescolNumeroVol']}
                                                            rules={[{required: true, message: 'Information manquante'}]}
                                                        >
                                                            <Input placeholder="Numéro du vol"/>
                                                        </Form.Item>

                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'volEscalescolCompagnieVol']}
                                                            fieldKey={[field.fieldKey, 'volEscalescolCompagnieVol']}
                                                            rules={[{required: true, message: 'Information manquante'}]}
                                                        >
                                                            <Input placeholder="Compagnie du vol"/>
                                                        </Form.Item>
                                                    </Space>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24}>
                                                    <Text strong>Je pars depuis </Text>
                                                    <br />
                                                    <Space align="center">
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'volEscalescolAeroportProvenance']}
                                                            fieldKey={[field.fieldKey, 'volEscalescolAeroportProvenance']}
                                                            rules={[{required: true, message: 'Information manquante'}]}
                                                        >
                                                            <Input
                                                                placeholder="Aéroport de provenance (dernier aéroport avant arrivée)"/>
                                                        </Form.Item>

                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'volEscalescolDatetimeDepart']}
                                                            fieldKey={[field.fieldKey, 'volEscalescolDatetimeDepart']}
                                                            rules={[{required: true, message: 'Information manquante'}]}
                                                        >
                                                            <DatePicker
                                                                showTime={{format: 'HH:mm'}}
                                                                format="YYYY-MM-DD HH:mm"
                                                                placeholder={['Date et heure de décollage']}
                                                            />
                                                        </Form.Item>
                                                    </Space>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={24}>
                                                    <Text strong>J'arrive à</Text>
                                                    <br />
                                                    <Space align="center">
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'volEscalescolAeroportArrivee']}
                                                            fieldKey={[field.fieldKey, 'volEscalescolAeroportArrivee']}
                                                            rules={[{required: true, message: 'Information manquante'}]}
                                                        >
                                                            <Input placeholder="Aéroport d'arrivée"/>
                                                        </Form.Item>

                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'volEscalescolDatetimeArrivee']}
                                                            fieldKey={[field.fieldKey, 'volEscalescolDatetimeArrivee']}
                                                            rules={[{required: true, message: 'Information manquante'}]}
                                                        >
                                                            <DatePicker
                                                                showTime={{format: 'HH:mm'}}
                                                                format="YYYY-MM-DD HH:mm"
                                                                placeholder={['Date et heure d\'atterissage']}
                                                            />
                                                        </Form.Item>
                                                    </Space>
                                                </Col>
                                            </Row>

                                            <MinusCircleOutlined onClick={() => remove(field.name)}/>
                                        </Col>
                                    </Row>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                        Ajouter un vol
                                    </Button>
                                </Form.Item>
                            </div>
                        )}
                    </Form.List>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Valider mes vols
                        </Button>
                    </Form.Item>
                </Form>
                <Divider/>
                <Title level={3}>Liste de mes vols</Title>
                {(this.state.dataVols) ? this.bocVolss() : 'En chargement...'}
            </div>
        )
    }
}

export default OrderFlight