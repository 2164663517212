import React from "react";
import {Row, Col, Typography, Divider, Switch, Select, DatePicker, Space, List, Button} from 'antd';
import Header from "./inc/Header";
import FIl from "./inc/FIl"
import './css/step.css'
import Recap from "./inc/Recap";
import {Redirect} from "react-router-dom";
import {StickyContainer, Sticky} from 'react-sticky';
import Register from "../member/Register";
import {API_URI} from "../../constant/Config"
import axios from "axios";
import moment from 'moment';
import {FooterApps} from "./inc/FooterApps";

const {Title, Text} = Typography;
const {Option} = Select;

class OrderCheck extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            paramHeader: {},
            dataRecap: false,
            dataQuest: false,
            goToFinish: false,
            retour: false,

        }
    }

    getOrder = (idSejour, idPack, idCat, dateId) => {
        let data = axios.get(API_URI + '/api_member/order_data?idsejour=' + idSejour + '&idpack=' + idPack + '&idcat=' + idCat + '&iddate=' + dateId);
        return data
    }

    getCommandeRecap = async () => {

        const dataPays = JSON.parse(localStorage.getItem('order_pays'))
        const idSejour = localStorage.getItem('order_id_sejour')
        const idCat = localStorage.getItem('order_idcat')
        const dateDepart = localStorage.getItem('order_date_debut')
        const idPack = localStorage.getItem('order_id_pack')
        const codePays = dataPays.codePays

        let dateDebut = localStorage.getItem('order_date_debut');
        let supp = localStorage.getItem('order_supp');

        let order_date_id = localStorage.getItem('order_date_id');
        let order_date_type = localStorage.getItem('order_date_type');
        const dataSejour = await this.getOrder(idSejour, idPack, idCat, order_date_id)
        let membre_id = localStorage.getItem('membre_id')

        //iddate
        let dataResume = await axios.post(API_URI + '/api_member/order-resume', {
            idpack: idPack,
            dateDebut: dateDebut,
            supp: supp,
            dateId: order_date_id,
            typeDate: order_date_type,
            idSejour: idSejour,
            membre_id: membre_id
        });

        //var packLib = JSON.parse()
        var data = [
            'Date de début : ' + moment(dateDepart).format('DD/MM/YYYY'),
            'Pays de destination : ' + dataSejour.data.sejour.libPays,
            'Type du programme : ' + dataSejour.data.cat.lib,
            'Nom du programme : ' + dataSejour.data.sejour.titre,
            'Durée d\'engagement : ' + dataSejour.data.pack.lib,
        ];

        if (dataResume.data[0].suppListe) {
            Object.keys(dataResume.data[0].suppListe).map((value, index) => {
                data.push('Supplément : ' + dataResume.data[0].suppListe[value].lib)
            })
        }


        data.push('Durée totale de votre projet : ' + dataResume.data[0].totalSemaines + ' semaine(s)')

        let libMontant = localStorage.getItem('order_idcat') == 3 ? 'Montant à verser ' : 'Montant du don à verser '
        data.push(libMontant + 'pour ce projet si votre candidature est acceptée : ' + dataResume.data[0].totalSejour + ' €' + dataResume.data[0].messageMembreHonoraire)

        this.setState({dataRecap: data})
    }

    getQuestions = async () => {
        const quest = JSON.parse(localStorage.getItem('order_question'))
        var questData = []
        for (const key in quest) {
            const question = quest[key].question
            const reponse = quest[key].sousQuestion.reponse


            if (typeof quest[key].sousQuestionPers !== 'undefined') {
                var reponsePers = '/ Complément : ' + quest[key].sousQuestionPers.rep
            } else {
                var reponsePers = ''
            }

            const questionFull = "Question : " + question + " / Votre réponse réponse : " + reponse + reponsePers
            questData.push(questionFull)
        }

        this.setState({dataQuest: questData})

    }

    validerCommande = async () => {
        let order_date_id = localStorage.getItem('order_date_id');
        let order_date_type = localStorage.getItem('order_date_type');

        await axios.post(API_URI + '/api_member/creat_order', {
            order_idcat: localStorage.getItem('order_idcat'),
            membre_id: localStorage.getItem('membre_id'),
            order_question: localStorage.getItem('order_question'),
            order_id_sejour: localStorage.getItem('order_id_sejour'),
            order_id_pack: localStorage.getItem('order_id_pack'),
            order_date_debut: localStorage.getItem('order_date_debut'),
            order_accompagnateurs: localStorage.getItem('order_accompagnateurs'),
            order_doc_CASIER_JUDICIAIRE: localStorage.getItem('order_doc_CASIER_JUDICIAIRE'),
            order_doc_LETTRE_PRESENTATION: localStorage.getItem('order_doc_LETTRE_PRESENTATION'),
            order_doc_CV: localStorage.getItem('order_doc_CV'),
            order_supp: localStorage.getItem('order_supp'),
            dateId: order_date_id,
            typeDate: order_date_type,
            order_deja_contact: localStorage.getItem('order_deja_contact'),

        })
            .then(function (response) {
                console.log(response);
                localStorage.setItem('order_log_ref', response.data.ref)
                localStorage.setItem('order_log_id', response.data.idOrder)
            })
            .catch(function (error) {
                console.log(error);
            });

        //console.log(orderCreat)
        // reset de la commande et redirection message de fin
        await this.setState({goToFinish: true})
        await this.deleteSessionOrder()

    }

    deleteSessionOrder = () => {
        localStorage.removeItem('order_id_sejour')
        localStorage.removeItem('order_date_desirate')
        localStorage.removeItem('order_log_ref')
        localStorage.removeItem('order_doc_LETTRE_PRESENTATION')
        localStorage.removeItem('order_idcat')
        localStorage.removeItem('order_doc_CV')
        localStorage.removeItem('order_supp')
        localStorage.removeItem('order_id_pack')
        localStorage.removeItem('order_date_debut')
        //localStorage.removeItem('membre_token')
        localStorage.removeItem('order_doc_CASIER_JUDICIAIRE')
        localStorage.removeItem('order_pays')
        localStorage.removeItem('order_question')
        localStorage.removeItem('order_valStep_1')
        localStorage.removeItem('order_valStep_2')
        localStorage.removeItem('order_valStep_3')
        localStorage.removeItem('order_valStep_4')
        localStorage.removeItem('order_date_type')
        localStorage.removeItem('order_formSite')
        localStorage.removeItem('order_date_id')
        localStorage.removeItem('order_deja_contact');
    }

    componentDidMount() {
        this.getCommandeRecap()

        // desactivé pour la V2 inscription
        //this.getQuestions()

    }

    clicPrev = () => {
        this.setState({'retour': true})
    }

    render() {

        if (this.state.goToFinish) {
            return <Redirect to='/order/success'/>;
        }

        if (this.state.retour) {
            return <Redirect to='/order/step1'/>;
        }


        if (this.state.dataRecap) {
            var data = this.state.dataRecap
        } else {
            var data = [];
        }

        if (this.state.dataQuest) {
            var dataQuest = this.state.dataQuest
        } else {
            var dataQuest = [];
        }

        return (
            <div>
                <Header paramHeader={this.state.paramHeader}/>
                <StickyContainer>
                    <Row>
                        <Col lg={6} id="stepScreen" className="contentStep">
                            <div className="sideBar">
                                <Sticky>
                                    {({
                                          style,
                                          isSticky,
                                          wasSticky,
                                          distanceFromTop,
                                          distanceFromBottom,
                                          calculatedHeight
                                      }) => (
                                        <div style={style}>
                                            <FIl step={3}/>
                                        </div>
                                    )}
                                </Sticky>
                            </div>
                        </Col>
                        <Col lg={18} className="contentStep">

                            <div className="centerTxt">
                                <Title level={2}>Récapitulatif de votre candidature</Title>
                                <Text type="secondary">Cette étape est la dernière et va nous permettre de vous donner
                                    les informations princpales sur votre inscription</Text>
                            </div>
                            <Divider/>
                            <List
                                size="large"
                                header={<div style={{color: "#db3056"}}>Détails de votre projet</div>}
                                bordered
                                dataSource={data}
                                renderItem={item => <List.Item>{item}</List.Item>}
                            />
                            <Divider/>

                            {/*
                            // désactivé pour la V2 de l'inscription
                            <List
                                size="large"
                                header={<div style={{color: "#db3056"}}>Questions et réponses</div>}
                                bordered
                                dataSource={dataQuest}
                                renderItem={item => <List.Item>{item}</List.Item>}
                            />
                            */}


                            <div style={{textAlign: "center"}}>
                                <Button
                                    onClick={this.clicPrev}
                                    type="primary"
                                    style={{marginTop: "10px", marginRight: 10}}
                                    size="large"
                                >
                                    Retour sur l'étape précédente
                                </Button>

                                <Button
                                    onClick={this.validerCommande}
                                    type="primary"
                                    style={{marginTop: "10px"}}
                                    size="large"
                                >
                                    Ouvrir mon dossier de candidature
                                </Button>
                            </div>

                            <Divider dashed/>
                            <FooterApps/>
                        </Col>
                    </Row>
                </StickyContainer>
            </div>
        )
    }

}

export default OrderCheck