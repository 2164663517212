import React, {Component} from 'react';
import {Form, Input, Button, Space, List, Typography, Divider, notification } from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';



class AccompagnateurBloc extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dataAcomp: false,
            accompAdd: false,
        }
    }



    componentDidMount = async () => {
        if (localStorage.getItem('order_accompagnateurs')) {
            const liste = await JSON.parse(localStorage.getItem('order_accompagnateurs'))
            const dataAcc = await this.acompCmd(liste)
            await this.setState({dataAcomp: dataAcc})
        }
    }

    delAccomp =(e,key)=> {

        const liste = JSON.parse(localStorage.getItem('order_accompagnateurs'))
        liste.accompagnateur.splice(key,1)
        var newAcc = liste.accompagnateur


        if (Array.isArray(newAcc) && newAcc.length){
            const dataAcc = this.acompCmd(liste);
            this.setState({dataAcomp: dataAcc});
            localStorage.setItem('order_accompagnateurs',JSON.stringify(liste));
        }else{
            localStorage.removeItem('order_accompagnateurs');
            this.setState({dataAcomp: false});
        }

        //const dataAcc = this.acompCmd(liste)
       // this.setState({dataAcomp: dataAcc})
       // localStorage.setItem('order_accompagnateurs',JSON.stringify(liste))
    }

    acompCmd = (dataAcc) => {

        if (typeof dataAcc != 'undefined') {

            const dataFinal = []
            for (const [key, value] of Object.entries(dataAcc.accompagnateur)) {
                dataFinal.push({
                    key : key,
                    first : value.first,
                    last : value.last,
                    email : value.email,
                })
            }

            return (
                <div>
                    <List
                        header={<div>Vos accompagnateurs</div>}
                        bordered
                        dataSource={dataFinal}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    title={item.first +' '+item.last+' '+item.email}
                                />
                                <div>
                                    <Button data-id={item.key} onClick={(e,id)=>this.delAccomp(e,item.key)} type="primary" danger>
                                    Effacer
                                </Button>
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
            )
        } else {
            return false
        }
    }


    render() {

        const onFinish = values => {
            var accomp = JSON.stringify(values);
            console.log(accomp)
            localStorage.setItem("order_accompagnateurs", accomp);
            notification['success']({
                message: 'Accompagnateur mis à jour',
                description:
                    'Les informations des accompagnateurs ont été mises à jour.',
            });
        };

        if (this.state.dataAcomp) {
            var listeAcc = this.state.dataAcomp
        } else {
            var listeAcc = ''
        }

        return (
            <div>
                {
                    (this.state.dataAcomp)?listeAcc: ""
                }

                <Divider orientation="left">Ajouter une nouvelle personne</Divider>
                <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
                    <Form.List name="accompagnateur">
                        {(fields, {add, remove}) => {
                            return (
                                <div>
                                    {fields.map(field => (
                                        <Space key={field.key} style={{display: 'flex', marginBottom: 8}} align="start">
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'first']}
                                                fieldKey={[field.fieldKey, 'first']}
                                                rules={[{required: true, message: 'Il manque le prénom'}]}
                                            >
                                                <Input placeholder="Prénom"/>
                                            </Form.Item>

                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'last']}
                                                fieldKey={[field.fieldKey, 'last']}
                                                rules={[{required: true, message: 'Il manque le nom'}]}
                                            >
                                                <Input placeholder="Nom"/>
                                            </Form.Item>

                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'email']}
                                                fieldKey={[field.fieldKey, 'email']}
                                                rules={[{required: true, message: 'Il manque le mail'}]}
                                            >
                                                <Input placeholder="Email"/>
                                            </Form.Item>

                                            <MinusCircleOutlined
                                                onClick={() => {
                                                    remove(field.name);
                                                }}
                                            />
                                        </Space>
                                    ))}


                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => {
                                                add();
                                                this.setState({accompAdd:true})
                                            }}
                                            block
                                        >
                                            <PlusOutlined/> Ajouter une personne
                                        </Button>
                                    </Form.Item>
                                </div>
                            );
                        }}
                    </Form.List>

                    <Form.Item>
                        {
                            (this.state.accompAdd)?<Button type="primary" htmlType="submit">
                                Valider mes accompagnateurs
                            </Button>: ""
                        }

                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default AccompagnateurBloc;