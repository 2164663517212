import React from "react";
import {Col, Divider, Row, Switch, Typography, List, Checkbox} from "antd";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlaneDeparture, faCalendarAlt} from '@fortawesome/free-solid-svg-icons'
import './../css/step.css'
import axios from "axios";
import {API_URI} from "../../../constant/Config";

const {Title, Text} = Typography;

class Supp extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dataSup: false,
            resetListeSupplement: true,
            checkList: {},
            idPaysSejour: false,
        }
    }

    async componentDidMount() {
        if (localStorage.getItem('order_supp')) {
            let idSejour = localStorage.getItem('order_id_sejour');
            let idPaysSejour = JSON.parse(localStorage.getItem('order_pays'));
            let order_date_debut = localStorage.getItem('order_date_debut');
            let order_id_pack = localStorage.getItem('order_id_pack');

            const dataSuppFormat = []
            const dataSupp = await this.getDataSupp(idPaysSejour.idPays, order_date_debut)

            const checkList = this.state.checkList;
            await Object.keys(dataSupp).map(function (key) {
                dataSuppFormat.push({
                    lib: dataSupp[key]['lib'] + ' ' + dataSupp[key]['prix'] + ' Euros',
                    id: dataSupp[key]['id'],
                    checked: (checkList[dataSupp[key]['id']]) ? true : false,
                })
            });
            this.setState({
                dataSup: dataSuppFormat,
                resetListeSupplement: false,
                checkList: JSON.parse(localStorage.getItem('order_supp')),
                idSejour: idSejour,
            })
        }
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        //supplements
        if (nextProps.idPaysSejour && nextProps.dateStart) {
            const dataSuppFormat = []
            const dataSupp = await this.getDataSupp(nextProps.idPaysSejour, nextProps.dateStart)

            const checkList = this.state.checkList;
            await Object.keys(dataSupp).map(function (key) {
                dataSuppFormat.push({
                    lib: dataSupp[key]['lib'] + ' ' + dataSupp[key]['prix'] + ' Euros',
                    id: dataSupp[key]['id'],
                    checked: (checkList[dataSupp[key]['id']]) ? true : false,
                })
            });


            await this.setState({
                dataSup: dataSuppFormat,
                resetListeSupplement: false,
            })

        } else {
            if (localStorage.getItem('order_supp')) {

            }else{

                if (!localStorage.getItem('order_formSite')) {
                    await this.setState({
                        resetListeSupplement: true,
                    })
                }

            }

        }
    }

    getDataSupp = async (idPaysSejour, dateStart) => {
        let idSejour = localStorage.getItem('order_id_sejour');
        let data = await axios.get(API_URI + '/api_member/liste_supplement?idpaysejour=' + idPaysSejour + '&dateStart=' + dateStart + '&idsejour=' + idSejour);
        return data.data
    }

    addSupp = (idSupp) => {
        let data = localStorage.getItem('order_supp')
        let dataObj = JSON.parse(data)
        console.log(dataObj)
        let addObj = {
            ...dataObj,
            [idSupp]: {
                id: idSupp
            }
        }
        this.setState({checkList: addObj})
        localStorage.setItem('order_supp', JSON.stringify(addObj))
        this.props.supplements(addObj)

        return true
    }

    delSupp = (idSupp) => {
        let data = localStorage.getItem('order_supp')
        let dataObj = JSON.parse(data)
        delete dataObj[idSupp]
        this.setState({checkList: dataObj})
        localStorage.setItem('order_supp', JSON.stringify(dataObj))
        this.props.supplements(dataObj)
        return true
    }

    onChange = (e) => {

        if (e.target.checked) {
            this.addSupp(e.target.value)
            return true
        } else {
            this.delSupp(e.target.value)
        }
    }

    render() {

        if (this.state.dataSup) {
            var data = this.state.dataSup
        } else {
            var data = [];
        }

        if (this.state.resetListeSupplement) {
            return (<div></div>)
        } else {
            return (

                <div>
                    <Divider dashed/>

                    <div className="centerTxt mb10 mt10">
                        <Title level={2}>Compléments au choix</Title>
                    </div>

                    <Row>
                        <Col span={24}>
                            <List
                                size="small"
                                bordered
                                dataSource={data}
                                renderItem={item =>
                                    <List.Item>
                                        <Checkbox
                                            checked={(this.state.checkList[item.id]) ? true : false}
                                            value={item.id}
                                            onChange={this.onChange}>
                                            {item.lib}
                                        </Checkbox>
                                    </List.Item>}

                            />
                        </Col>
                    </Row>
                </div>
            )
        }

    }
}

export default Supp