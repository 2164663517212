import React, {Component} from 'react';
import {Col, Divider, Row, Typography, Alert, Form, Input, Button, Radio} from "antd";
import {GoogleLogin} from 'react-google-login';
import FacebookAuth from 'react-facebook-auth';
import {Redirect} from "react-router-dom";


const {Title, Text} = Typography;
const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

class MethodeReg extends Component {


    constructor(props) {
        super(props);
        this.state = {
            displayForm: false,
            displayMethode: true,
            redirectionIdent: false,
        }
    }

    responseGoogle = () => {

    }


    MyFacebookButton = ({onClick}) => (
        <button className="loginButonFacebook" onClick={onClick}>
            Créer mon compte avec Facebook
        </button>

    );

    authenticate = (response) => {
        console.log(response);
        // Api call to server so we can validate the token
    };

    clickIdent = () => {
        //this.props.changeMethAction(true)
        this.setState({redirectionIdent:true})
    }

    clickCreat = () => {
        this.props.changeMethAction(true)
    }

    render() {

        if (this.state.redirectionIdent) {
            //Affichage de la redirection
            return <Redirect to='/order/ident'/>;
        }

        return (
            <div>
                <Row>
                    <Col lg={12}>
                        <div className="centerTxt mb10 mt10">
                            <Title level={3}>Je n'ai pas de compte</Title>
                        </div>
                        <Row className="center" style={{marginBottom: "20px"}}>
                            <Col span={24}>
                                <Button onClick={this.clickCreat} type="primary" htmlType="button" size="large" style={{minWidth: "250px"}}>
                                    Créer mon compte
                                </Button>
                            </Col>
                        </Row>
                        {/*
                        <Row className="center">
                            <Col span={24}>
                                <GoogleLogin
                                    clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
                                    buttonText="Créer mon compte avec Google"
                                    onSuccess={this.responseGoogle}
                                    onFailure={this.responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                />
                            </Col>
                        </Row>
                        <Row className="center mt20">
                            <Col span={24}>
                                <div>
                                    <FacebookAuth
                                        appId="1255457351332164"
                                        callback={this.authenticate()}
                                        component={this.MyFacebookButton}
                                    />
                                </div>
                            </Col>
                        </Row>

                        */}

                    </Col>
                    <Col lg={12}>
                        <div className="centerTxt mb10 mt10">
                            <Title level={3}>J'ai déjà un compte</Title>
                        </div>
                        <div className="center">
                            <Button onClick={this.clickIdent} type="primary" htmlType="button" size="large"
                                    style={{minWidth: "250px"}}>
                                S'identifier
                            </Button>
                        </div>

                    </Col>
                </Row>
            </div>
        );
    }
}

export default MethodeReg;