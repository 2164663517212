import React, {Component} from 'react';
import {Col, Row, Typography, Alert} from "antd";
import AccompagnateurBloc from "./AccompagnateurBloc";
const {Title, Text} = Typography;
class Accompagnateur extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                <div className="centerTxt mb10 mt10">
                    <Title level={3}>Est-ce que vous candidatez avec une ou plusieurs personnes ?</Title>

                </div>

                <Row>
                    <Col span={24}>
                        <Alert message="A noter que chaque personne doit envoyer une candidature individuelle. Assurez-vous de candidater pour la même mission, aux mêmes dates." type="info" />

                        <AccompagnateurBloc />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Accompagnateur;