import React from "react";
import './../css/step.css'
import imageDemo from '../../../img/order/zdenek-machacek-XUFMiGkv-60-unsplash.jpg'
import {Row, Col, Typography, Divider, Switch, Select,Space,Card} from 'antd';
import {API_URI} from "../../../constant/Config"
import { RightOutlined,HeartTwoTone,SendOutlined,SmileTwoTone } from '@ant-design/icons';
import * as Scroll from 'react-scroll';

const {Title, Text} = Typography;


class BlockSejour extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            dataSejour: this.props.dataSejour,
            checked: false,
            electedOption: false,
            imgCover: imageDemo,
        }


    }

    checkSejour = async (check, e) => {
        if (check) {
            var scroll = Scroll.animateScroll;
            //scroll.scrollToBottom(); je désactive le bottom car il descend trop bas je calcul le bloc du bas en remontant légèrement
            var scroll = Scroll.animateScroll;
            let pageHeight = document.getElementById("listeSejourBloc").offsetHeight;
            let scrollTo = pageHeight - 300;
            scroll.scrollMore(scrollTo);

            let idSejour = await e.currentTarget.getAttribute("data-catid");
            this.setState({selectedOption: idSejour, checked: true});
            await this.props.selectIdsejour(idSejour);
            this.props.changeHeader({'title': this.state.dataSejour.titre})
        }
    }

    componentDidMount() {
        // gestion de l'image :
        const dataSej = this.state.dataSejour;
        if (typeof dataSej['imgSite']['immprog'] !== 'undefined') {
            this.setState({
                imgCover: API_URI + '/media/cache/my_thumb_350_228/' + dataSej['imgSite']['immprog']['fullPath']
            })
        } else {
            this.setState({
                imgCover: imageDemo
            })
        }

        // checker le sejour par défaut si déjà commande en cour :
        if (localStorage.getItem('order_id_sejour')) {
            if (dataSej['id'] == localStorage.getItem('order_id_sejour')) {
                //this.setState({checked: true})

            }
        }


    }

    componentWillReceiveProps(nextProps, nextContext) {
        const dataSejour = this.state.dataSejour
        if (nextProps.idSelect && nextProps.idSelect == dataSejour.id) {
            this.setState({checked: true})
        } else {
            this.setState({checked: false})
        }


    }

    render() {
        const dataSejour = this.state.dataSejour

        const attrib = dataSejour.attributs;
        var attribReturn = '';


        var tifOptions = Object.keys(attrib).map(function(key) {
            return <div><RightOutlined /> <Text strong>{attrib[key]['lib']}</Text> : {attrib[key]['value']}</div>
        });

        var title = <div><a target="_blank" href={dataSejour.link}><SmileTwoTone twoToneColor="#eb2f96" /> {dataSejour.titre}</a> </div>;


        return (
                <Card title={title} style={{ width: '100%', marginBottom:'10px' }}>
                    <Row>
                        <Col span={10}>
                            <a target="_blank" href={<HeartTwoTone twoToneColor="#eb2f96" /> + dataSejour.link}><img src={this.state.imgCover} className="imgSejour"/></a>
                            <br />
                            <br />
                            <div style={{textAlign:"center"}}>
                                <Switch checked={this.state.checked} onClick={this.checkSejour} data-catid={dataSejour.id}
                                        checkedChildren="Séjour sélectionné"
                                        unCheckedChildren="Sélectionner ce programme"/>
                            </div>

                        </Col>
                        <Col span={14}>
                            {tifOptions}
                        </Col>
                    </Row>
                </Card>


        )
    }
}

export default BlockSejour;