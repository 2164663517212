import React from "react";
import {Row, Col, Typography, Divider, Switch, Select, DatePicker, Space, Layout, Alert} from 'antd';
import Header from "./inc/Header";
import FIl from "./inc/FIl"
import './css/step.css'
import Recap from "./inc/Recap";
import DateOpen from "./dates/DateOpen"
import Supp from "./supplements/Supp";
import Destination from "./sejour/Destination";
import ChoixSejour from "./sejour/ChoixSejour";
import ChoixTypeSejour from "./sejour/ChoixTypeSejour";
import Accompagnateur from "./questions/Accompagnateur";
import {StickyContainer, Sticky} from 'react-sticky';
import axios from 'axios';
import {API_URI} from "../../constant/Config"
import {Redirect} from "react-router-dom";
import {FooterApps} from "./inc/FooterApps";
import ContactTel from "./questions/ContactTel";

const {Title, Text, Paragraph} = Typography;
const {Option} = Select;

class Step1 extends React.Component {

    constructor(props) {
        super(props);
        this.changeCat = this.changeCat.bind(this)
        this.changePays = this.changePays.bind(this)
        this.changePaysManuel = this.changePaysManuel.bind(this)
        this.chageSejour = this.chageSejour.bind(this)
        this.changeDateSTart = this.changeDateSTart.bind(this)
        this.changePack = this.changePack.bind(this)
        this.changeParamHeader = this.changeParamHeader.bind(this)
        this.dateDebut = this.dateDebut.bind(this)
        this.supplements = this.supplements.bind(this)

        this.state = {
            cat: false,
            idPaysSejour: false,
            codePays: false,
            disableSejour: true,
            dataPays: [],
            dataSejours: false,
            idSejour: false,
            dateStart: false,
            dateDebut: false,
            resetListePay: true,
            resetListeSejour: true,
            resetListeDate: true,
            resetListeSupplement: true,
            paramHeader: {},
            dataCart: 0,
            idPack: false,
            supplements: false,
            authNextStep: false,
            blocAcompagnateur: null,
            contactTel: null,
            changePaysState: false,

        }
    }

    async componentDidMount() {

        // creation depuis le site donc la localstorage est géré par la redirection
        if (this.props.match && typeof this.props.match.params.creatformsite != undefined) {
            //await this.fromSite();
            await this.initLoadPaysSejour();
        } else {
            await this.orderOpenCheck();
        }
    }


    /**
     * recreer la pré sélection
     * @returns {Promise<void>}
     */
    fromSite = async () => {
        //this.props.match.params.id


        var paysSaveSession = {
            'idPays': this.props.match.params.idPays,
            'codePays': this.props.match.params.codePays,
        }

        await localStorage.setItem("order_pays", JSON.stringify(paysSaveSession));
        await localStorage.setItem("order_idcat", this.props.match.params.idCat);
        await localStorage.setItem("order_id_sejour", this.props.match.params.idSejour);
        await localStorage.setItem("order_formSite", true);

        await this.initLoadPaysSejour();
    }

    initLoadPaysSejour = async () => {
        await this.changeCat(localStorage.getItem('order_idcat'), true)
        var pays = JSON.parse(localStorage.getItem('order_pays'), true);
        await this.changePays(pays.idPays + '-' + pays.codePays, true)
        await this.chageSejour(localStorage.getItem('order_id_sejour'), true)
        //console.log(this.state.idSejour)
    }


    orderOpenCheck = async () => {
        //Gestion de la cat

        var step = localStorage.getItem('order_valStep_1');
        // var fromSite = localStorage.getItem('order_formSite');

        if (step == 1) {
            await this.changeCat(localStorage.getItem('order_idcat'), true)
            var pays = JSON.parse(localStorage.getItem('order_pays'), true);
            await this.changePays(pays.idPays + '-' + pays.codePays, true)
            await this.chageSejour(localStorage.getItem('order_id_sejour'), true)
            this.setState({authNextStep: true});
            //await this.changeDateSTart('2020-02-20',true)

        } else {
            // nouvelle saisie
            localStorage.removeItem('order_valStep_1');
            localStorage.removeItem('order_idcat');
            localStorage.removeItem('order_pays');
            localStorage.removeItem('order_id_sejour');
            localStorage.removeItem('order_date_desirate');
            localStorage.removeItem('order_date_debut');
            localStorage.removeItem('order_id_pack');
            localStorage.removeItem('order_supp');
            localStorage.removeItem('order_deja_contact');
        }


    }

    async changeCat(idcat, restore = false) {

        await this.setState({
                resetListePay: false,
                resetListeSejour: true,
                resetListeDate: true,
                resetListeSupplement: true,
                idSejour: false,
                idPaysSejour: false,
                codePays: false,
            }
        )

        let dataPays = await this.getPays(idcat);
        await this.setState({cat: idcat, disableSejour: false, dataPays: dataPays.data})
        await localStorage.setItem("order_idcat", idcat);


        if (restore) {

        } else {

            localStorage.removeItem('order_pays');
            localStorage.removeItem('order_id_sejour');
            localStorage.removeItem('order_date_desirate');
            localStorage.removeItem('order_date_debut');
            localStorage.removeItem('order_id_pack');
            localStorage.removeItem('order_supp');
        }

    }

    async changePays(idSelectPays, restore = false) {
        await this.setState({
                resetListePay: false,
                resetListeSejour: false,
                resetListeDate: true,
                resetListeSupplement: true,
                dataSejours: false,
                dateStart: false,
            }
        )

        const idSelectPaysv = idSelectPays.split('-');

        let dataPays = await this.getSejours(idSelectPaysv[0], idSelectPaysv[1]);
        await this.setState({idPaysSejour: idSelectPaysv[0], codePays: idSelectPaysv[1], dataSejours: dataPays.data})
        var paysSaveSession = {
            'idPays': idSelectPaysv[0],
            'codePays': idSelectPaysv[1],
        }

        localStorage.setItem("order_pays", JSON.stringify(paysSaveSession));

        if (restore) {

        } else {
            localStorage.removeItem('order_id_sejour');
            localStorage.removeItem('order_date_desirate');
            localStorage.removeItem('order_date_debut');
            localStorage.removeItem('order_id_pack');
            localStorage.removeItem('order_supp');
        }
    }

    changePaysManuel() {
        this.setState({
            changePaysState: true
        })
    }


    /**
     * Change ou selectionne un séjour
     *
     * @param idSejour
     * @param restore
     */
    chageSejour(idSejour, restore = false) {
        this.setState({
            dateStart: false,
        })
        this.setState({
                resetListePay: false,
                resetListeSejour: false,
                resetListeDate: false,
                resetListeSupplement: true,
                idSejour: idSejour,
            }
        )

        localStorage.setItem("order_id_sejour", idSejour);


        if (restore) {

        } else {
            localStorage.removeItem('order_date_desirate');
            localStorage.removeItem('order_date_debut');
            localStorage.removeItem('order_id_pack');
            localStorage.removeItem('order_supp');
        }

        // afficher les acompagnateurs
        this.setState({blocAcompagnateur: <Accompagnateur/>})
        this.setState({contactTel: <ContactTel/>})


    }

    changeDateSTart(dateStart, restore = false) {
        this.setState({dateStart: dateStart})
    }

    changePack(idPack, restore = false) {
        // reload resume
        this.setState({idPack: idPack})

        // activer le next vu que le pack est selectionné
        //authNextStep
        this.setState({authNextStep: true})
    }


    dateDebut(dateDebut, restore = false) {
        //this.setState({authNextStep: true})
        let dateType = localStorage.getItem('order_date_type');
        if (dateType == 'fixe') {
            this.setState({authNextStep: true})
        }
        this.setState({dateDebut: dateDebut})
    }

    supplements(supplements) {
        this.setState({supplements: supplements})
    }

    getPays = (idcat) => {
        let data = axios.get(API_URI + '/api_member/liste_pays_sejour?idcat=' + idcat);
        return data
    }

    changeParamHeader(paramHeader) {
        this.setState(
            {
                paramHeader: paramHeader
            }
        )
    }

    getSejours = (idPaysSejour, codePays) => {
        let data = axios.get(API_URI + '/api_member/liste_sejour?idpaysejour=' + idPaysSejour + '&idcat=' + this.state.cat + '&codepays=' + codePays);

        return data
    }


    render() {

        return (

            <div>
                <Header paramHeader={this.state.paramHeader}/>
                <Row justify="center" style={{marginTop: "10px"}}>
                    <Col md={12} className="center">
                        <Typography>
                            <Title>En route ! Votre inscription ;){this.props.changeHeader}</Title>
                            <Paragraph>

                            </Paragraph>
                        </Typography>
                        <Divider/>
                    </Col>
                </Row>
                <StickyContainer>
                    <Row>
                        <Col lg={6} id="stepScreen" className="contentStep">

                            <div className="sideBar">
                                <Sticky>
                                    {({
                                          style,
                                          isSticky,
                                          wasSticky,
                                          distanceFromTop,
                                          distanceFromBottom,
                                          calculatedHeight
                                      }) => (
                                        <div style={style}>
                                            <FIl step={0}/>
                                        </div>
                                    )}
                                </Sticky>
                            </div>

                        </Col>
                        <Col lg={12} className="contentStep">

                            <Alert
                                message="Informational Notes"
                                description="Nous ne pouvons étudier qu'une seule candidature par dates et par personne.
Il n’est pas possible de candidater pour plusieurs projets aux mêmes dates.
Si la candidature sur votre premier choix n'est pas retenue, vous pourrez candidater pour d'autres projets, et nous serons là pour vous guider.
Pour toute question, n'hésitez pas à nous contacter. "
                                type="info"
                                showIcon
                            />

                            <br/>
                            <br/>

                            <div className="centerTxt">
                                <Title level={2}>Etape 1/3</Title>
                                <Text type="secondary">Je choisis mon projet, la date de début et la durée </Text>


                            </div>
                            <Divider/>

                            <ChoixTypeSejour onCatChange={this.changeCat} cat={this.state.cat}/>

                            <Divider dashed/>

                            <Destination
                                resetListePay={this.state.resetListePay}
                                onChangePays={this.changePays}
                                changePaysManuel={this.changePaysManuel}
                                dataPays={this.state.dataPays}
                                cat={this.state.cat}
                                activeSejour={this.state.disableSejour}
                            />

                            <Divider dashed/>

                            <ChoixSejour paramHeader={this.changeParamHeader}
                                         resetListeSejour={this.state.resetListeSejour}
                                         dataSejours={this.state.dataSejours}
                                         chageSejour={this.chageSejour}
                                         libSelect={this.state.libChoixPays}
                                         cat={this.state.cat}
                                         changePaysState={this.state.changePaysState}
                            />

                            <Divider dashed/>
                            <DateOpen
                                resetListeDate={this.state.resetListeDate}
                                cat={this.state.cat}
                                idPaysSejour={this.state.idPaysSejour}
                                idSejour={this.state.idSejour}
                                dateStart={this.changeDateSTart}
                                changePack={this.changePack}
                                dateDebut={this.dateDebut}

                            />

                            {this.state.blocAcompagnateur}
                            <Divider dashed style={{margin:0}}/>
                            {this.state.contactTel}
                            <Divider dashed/>


                            <Supp
                                resetListeSupplement={this.state.resetListeSupplement}
                                idPaysSejour={this.state.idPaysSejour}
                                dateStart={this.state.dateStart}
                                supplements={this.supplements}
                            />

                            <Divider dashed/>
                            <FooterApps/>

                        </Col>
                        <Col lg={6} id="RecapScreen" className="contentStep">
                            <div className="sideBar">
                                <div>
                                    <Sticky>
                                        {({
                                              style,
                                              isSticky,
                                              wasSticky,
                                              distanceFromTop,
                                              distanceFromBottom,
                                              calculatedHeight
                                          }) => (
                                            <div style={style}>
                                                <Recap
                                                    construct={true}
                                                    idPack={this.state.idPack}
                                                    dateDebut={this.state.dateDebut}
                                                    supplements={this.state.supplements}
                                                    next="/order/check"
                                                    valStep="1"
                                                    authNextStep={this.state.authNextStep}
                                                />
                                            </div>
                                        )}
                                    </Sticky>


                                </div>

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col id="RecapScreenMobile" span={24} className="center">
                            <Recap
                                construct={true}
                                idPack={this.state.idPack}
                                dateDebut={this.state.dateDebut}
                                supplements={this.state.supplements}
                                next="/order/check"
                                valStep="1"
                                authNextStep={this.state.authNextStep}
                            />
                        </Col>
                    </Row>
                </StickyContainer>
            </div>
        )
    }

}

export default Step1