import React from "react";
import {Col, Row, Select, Typography} from "antd";
import axios from 'axios';
import {API_URI} from "../../../constant/Config"
import * as Scroll from 'react-scroll';

const {Title, Text} = Typography;
const {Option} = Select;


export default class Destination extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dataPays: this.props.libChoixPays,
            valueSelect: 'Choisir',
            cat: false,
        }

    }

    componentDidMount() {


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.cat !== prevProps.cat) {

            this.setState({cat: this.props.cat, valueSelect: "Choisir"})

            if (localStorage.getItem('order_pays')) {
                let pays = JSON.parse(localStorage.getItem('order_pays'));
                let paysVal = pays.idPays + '-' + pays.codePays;
                this.setState({valueSelect: paysVal})
            }

        }
    }

    selectPays = (id) => {
        var scroll    = Scroll.animateScroll;
        scroll.scrollMore(500);

        this.props.onChangePays(id);
        this.setState({valueSelect: id})
        this.props.changePaysManuel();
    }

    render() {

        const dataPaysliste = this.props.dataPays;
        var dataPayslistes = this.props.dataPays;
        var paysAlpha = [];
        var listePays = Object.keys(dataPaysliste).map(function (key) {
            var idSelect = key + '-' + dataPaysliste[key]['codePays']
            paysAlpha.push(
                {
                    key: key,
                    nomPays: dataPaysliste[key]['nomPays'],
                    codePays: dataPaysliste[key]['codePays'],
                }
            )
        });

        function compare(a, b) {
            if (a.nomPays < b.nomPays) {
                return -1;
            }
            if (a.nomPays > b.nomPays) {
                return 1;
            }
            return 0;
        }

        paysAlpha.sort(compare);

        var listePays = paysAlpha.map(paysAlphaItem => {
            var idSelect = paysAlphaItem.key + '-' + paysAlphaItem.codePays
            return <Option value={idSelect}>{paysAlphaItem.nomPays}</Option>
        })

        var listePayss = Object.keys(dataPaysliste).map(function (key) {
            var idSelect = key + '-' + dataPaysliste[key]['codePays']
            return <Option value={idSelect}>{dataPaysliste[key]['nomPays']}</Option>
        });


        if (this.props.resetListePay) {
            return (
                <div>
                </div>
            )
        } else {
            return (
                <div>
                    <Row>
                        <Col span={24} className="center">
                            <div className="centerTxt mb10 mt10">
                                <Title level={2}>Ma destination</Title>
                            </div>
                            <Select

                                onSelect={this.selectPays}
                                disabled={(this.props.resetListePay) ? true : false}
                                style={{width: 250}}
                                value={this.state.valueSelect}

                            >
                                {listePays}
                            </Select>
                        </Col>
                    </Row>
                </div>
            )
        }
    }
}