import React from "react";
import {Row, Col, Typography, Divider, Switch, Select, DatePicker, Space, Button, Alert, Result} from 'antd';
import Header from "../order/inc/Header";
import FIl from "../order/inc/FIl"
import '../order/css/step.css'
import Recap from "../order/inc/Recap";
import {StickyContainer, Sticky} from 'react-sticky';
import Questions from "../order/questions/Questions";
import DocsInscr from "../order/documents/DocsInscr";
import {Redirect, Link} from "react-router-dom";
import axios from 'axios';
import {API_URI} from "../../constant/Config";
import {LikeOutlined, SendOutlined, SmileOutlined, PoweroffOutlined} from "@ant-design/icons";
import {FooterApps} from "../order/inc/FooterApps";

const {Title, Text} = Typography;
const {Option} = Select;

class OrderComplete extends React.Component {

    constructor(props) {
        super(props);

        this.handleStep = this.handleStep.bind(this);

        this.state = {
            paramHeader: {},
            stepValue: false,
            idCmd: this.props.match.params.idCmd,
            alertMsg: false,
            success: false,
            dataCmd: false,
            display: false,
            loading: false,
        }
    }

    async componentDidMount() {


        await localStorage.removeItem('order_question')

        //await localStorage.setItem("order_pays", JSON.stringify(paysSaveSession));
        var dataCmd = await this.getDetailsCmd();
        await this.setState({dataCmd: dataCmd})

        var paysSaveSession = {
            'idPays': dataCmd.paysSejour.id,
            'codePays': dataCmd.paysSejour.codePays,
        }
        //await console.log(paysSaveSession)
        await localStorage.setItem("order_pays", JSON.stringify(paysSaveSession));
        await this.setState({display: true})


        let getJson = await this.getFirstQuestion();
        var count = Object.keys(getJson.data).length;

        //let totalQuest = await JSON.parse(getJson.data);
        //alert(totalQuest)
    }

    getDetailsCmd = async () => {
        var token = localStorage.getItem('membre_token');
        let dataCmd = await axios.post(API_URI + '/api_member/details-order-format', {
            id: this.state.idCmd,
        }, {
            headers: {
                'Authorization': token
            }
        })

        return dataCmd.data.dataResp;
    }

    handleStep(stepValue) {
        this.setState({stepValue: stepValue});
    }

    getFirstQuestion = () => {
        let data = axios.get(API_URI + '/api_member/liste_questions_first');
        return data
    }

    validerCommande = async () => {

        var testForm = this.testForm();
        this.setState({loading: true});

        if (testForm) {
            await axios.post(API_URI + '/api_member/complete_order', {
                idCmd: this.state.idCmd,
                idMembre: localStorage.getItem('membre_id'),
                order_question: localStorage.getItem('order_question'),
                order_accompagnateurs: localStorage.getItem('order_accompagnateurs'),
                order_doc_CASIER_JUDICIAIRE: localStorage.getItem('order_doc_CASIER_JUDICIAIRE'),
                order_doc_LETTRE_PRESENTATION: localStorage.getItem('order_doc_LETTRE_PRESENTATION'),
                order_doc_CV: localStorage.getItem('order_doc_CV'),
            })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });

            this.setState({success: true})
        }

        this.setState({loading:false})
        // reset de la commande et redirection message de fin

        //await this.deleteSessionOrder()

    }

    /**
     * Valide tous le formulaire
     * @returns {boolean}
     */
    testForm() {

        // verification des questions
        var questData = JSON.parse(localStorage.getItem('order_question'));
        var valide = true;

        Object.keys(questData).map((key) => {

                if (questData[key] == false) {
                    valide = false;
                } else {

                    const sousQuestionSaisie = questData[key].sousQuestion['repPerso']

                    // si c'est une reponse a personnaliser
                    if (sousQuestionSaisie) {
                        if (questData[key].sousQuestionPers) {
                            // validé car il y a une sousQuest
                        } else {
                            // pas de reponse inséré alors que obligatoire non validé
                            valide = false;
                        }
                    }
                }
            }
        );

        /**
         * Ne pas demander de document si c'est une inscription dans
         * un programme de langue
         */

        if (this.state.dataCmd.sejour.cat !== 3) {
            var docValidation = {}
            // verification des deux doc obligatoire
            if (!localStorage.getItem('order_doc_CV')) {
                valide = false;
                docValidation = {
                    ...docValidation,
                    order_doc_CV: false
                }
            } else {
                docValidation = {
                    ...docValidation,
                    order_doc_CV: true
                }
            }

            if (!localStorage.getItem('order_doc_LETTRE_PRESENTATION')) {
                valide = false;
                docValidation = {
                    ...docValidation,
                    order_doc_LETTRE_PRESENTATION: false
                }
            } else {
                docValidation = {
                    ...docValidation,
                    order_doc_LETTRE_PRESENTATION: true
                }
            }

        }

        if (valide) {
            this.setState({alertMsg: false})
        } else {
            this.setState({
                alertMsg: <div style={{marginTop: 10}}><Alert
                    message="Incomplet"
                    description="Merci de bien compléter votre formulaire."
                    type="warning"
                    showIcon
                    closable
                /></div>
            })

        }

        return valide;
    }


    render() {

        if (this.state.display) {
            if (this.state.success) {

                var tot = <div>

                    Votre candidature a été déposée et sera étudiée sous peu.
                    <br/><br/>
                    En moyenne, les candidatures sont étudiées en moins de 7 jours ouvrés.
                    <br/><br/>
                    Dans certains cas, les délais peuvent être plus longs, n'hésitez pas à nous contacter si besoin :
                    <br/>
                    <br/>
                    <a href="mailto:info@globalong.com">info@globalong.com</a>

                </div>

                return (
                    <div>
                        <Header paramHeader={this.state.paramHeader}/>
                        <Row>
                            <Col lg={6} id="stepScreen" className="contentStep">

                                <div className="sideBar">

                                </div>

                            </Col>
                            <Col md={24} lg={12} className="contentStep">
                                <Result
                                    icon={<SmileOutlined/>}
                                    title="Féliciations"
                                    subTitle={tot}
                                    extra={<Link to={'/ident/order/' + this.state.idCmd}><Button
                                        type="primary">Accès à mon espace membre</Button></Link>}
                                />,
                            </Col>
                            <Col lg={6} id="RecapScreen" className="contentStep">
                                <div className="sideBar">

                                </div>
                            </Col>
                        </Row>

                        <Divider dashed/>
                        <FooterApps/>


                    </div>
                )

            } else {
                return (
                    <div>
                        <Header paramHeader={this.state.paramHeader}/>
                        <StickyContainer>
                            <Row>
                                <Col lg={5} id="stepScreen" className="contentStep">

                                    <div className="sideBar">

                                    </div>

                                </Col>
                                <Col md={24} lg={14} className="contentStep">

                                    <div className="centerTxt">
                                        <Title level={2}>Nous y sommes presque ^_^</Title>
                                        <Text type="secondary">Après la validation de ce formulaire votre candidature
                                            sera
                                            soumise à Globalong</Text>
                                    </div>
                                    <Divider/>

                                    <Questions changeStepSend={this.state.stepValue}/>

                                    <Divider dashed/>

                                    {this.state.dataCmd.sejour.cat === 3 ? '' :
                                        <DocsInscr changeStepSend={this.state.stepValue}/>}

                                    <Row>
                                        <Col span={24} className="center" style={{marginBottom: 50}}>
                                            {this.state.alertMsg}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={5} id="RecapScreen" className="contentStep">
                                    <div className="sideBar">


                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24} className="center" style={{marginBottom: 50}}>
                                    <Button loading={this.state.loading} onClick={this.validerCommande} type="primary"
                                            icon={<SendOutlined/>} size="large">Soumettre ma candidature</Button>
                                </Col>
                            </Row>

                            <Divider dashed/>
                            <FooterApps/>

                        </StickyContainer>
                    </div>
                )
            }
        } else {
            return <div>Chargement...</div>
        }


    }

}

export default OrderComplete