import React, {Component} from 'react';
import {Col, Row, Switch, Typography, Radio} from "antd";
import axios from 'axios';
import {API_URI} from "../../../constant/Config"
import * as Scroll from 'react-scroll';

const {Title, Text} = Typography;

class ChoixTypeSejour extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: false,
            listCheck: {
                1: {
                    selected: false
                },
                2: {
                    selected: false
                },
                3: {
                    selected: false
                }
            }
        };
    }

    async componentDidMount() {

        if(localStorage.getItem('order_idcat') && localStorage.getItem('order_idcat').length >0){
            let idCat = await localStorage.getItem('order_idcat');
            let objCat = {
                ...this.state.listCheck,
                [idCat] : {
                    selected: true
                }
            }


           await this.setState({listCheck:objCat})
        }

    }


    checkChangeCat = async (check, e) => {
        var scroll    = Scroll.animateScroll;
        scroll.scrollToBottom();

        if (e.currentTarget.id && check) {
            let catId = await e.currentTarget.getAttribute("data-catid");

            await this.props.onCatChange(catId);

            let liste = {
                1: {
                    selected: false
                },
                2: {
                    selected: false
                },
                3: {
                    selected: false
                }
            }

            liste[catId]['selected'] = true
            this.setState({selectedOption: catId, listCheck: liste});
        }
    }

    render() {

        return (
            <div>
                <div className="centerTxt mb10 mt10">
                    <Title level={2}>Je choisis mon type de projet</Title>
                </div>
                <Row className="">
                    <Col span={8} style={{padding: "2px"}}>
                        <div className="centerTxt carType">

                            <Title level={4}>Combiné</Title>
                            <Switch name="gender" id="1" data-catid="1" onClick={this.checkChangeCat}
                                    checked={this.state.listCheck[1]['selected']}/>
                            <br/>
                            <Text>Pour combiner une formation linguistique avec un projet de bénévolat ou un stage</Text>
                        </div>
                    </Col>
                    <Col span={8} style={{padding: "2px"}}>
                        <div className="centerTxt carType">


                            <Title level={4}>Bénévolat</Title>
                            <Switch name="gender" id="2" data-catid="2" onClick={this.checkChangeCat}
                                    checked={this.state.listCheck[2]['selected']}/>
                            <br/>
                            <Text>Pour candidater pour une mission de bénévolat ou un stage</Text>
                        </div>
                    </Col>
                    <Col span={8} style={{padding: "2px"}}>
                        <div className="centerTxt carType">

                            <Title level={4}>Formation linguistique</Title>
                            <Switch id="3" data-catid="3" onClick={this.checkChangeCat}
                                    checked={this.state.listCheck[3]['selected']}/>
                            <br/>
                            <Text>Pour apprendre ou améliorer une langue étrangère</Text>
                        </div>
                    </Col>

                </Row>
            </div>
        );
    }
}

export default ChoixTypeSejour;