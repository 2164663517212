import React from "react";
import {Col, Divider, Row, Steps, Typography, Form, Input, InputNumber, Button, Result, Layout} from "antd";
import MenuDashboard from "./Menu";
import HeaderMember from "./HeaderMember";
import ListeCmd from "./ListeCmd";
import {SmileOutlined} from '@ant-design/icons';
import axios from "axios";
import {API_URI} from "../../constant/Config";
import {FooterApps} from "../order/inc/FooterApps";

const {Title, Text, Link} = Typography;
const {Step} = Steps;
const layout = {
    labelCol: {span: 0},
    wrapperCol: {span: 0},
};

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formZone: this.form(),
            id: localStorage.getItem('membre_id'),
        }
    }

    sendForm = async (values) => {

        await await axios.post(API_URI + '/api_member/contact-member', {id: this.state.id, msg: values.msg});

        this.setState({
            formZone: <Result
                icon={<SmileOutlined/>}
                title="Merci, nous vous confirmons l'envoi de votre message, une réponse vous sera donnée très rapidement !"
                extra={<Button onClick={this.retourForm} type="primary">Retour</Button>}
            />,
        })
    }

    retourForm = () => {
        this.setState({formZone: this.form()})
    }

    form = () => {
        return (
            <Form {...layout} name="nest-messages" onFinish={this.sendForm} validateMessages={validateMessages}>
                <Form.Item name={['msg']} label="">
                    <Input.TextArea rows={10}/>
                </Form.Item>
                <Form.Item wrapperCol={{...layout.wrapperCol, offset: 0}}>
                    <Button type="primary" htmlType="submit">
                        Envoyer ma demande
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    render() {
        return (
            <Layout>
                <HeaderMember/>
                <Layout>
                    <MenuDashboard/>
                    <Layout>
                        <div className="contentBox">
                            <Title>Nous contacter</Title>

                            <Text>Vous pouvez nous envoyer un message grâce au formulaire ci-dessous, nous répondrons
                                par mail dans les meilleurs délais.<br/>
                                Vous pouvez également prendre un rendez-vous téléphonique via ce lien : <a
                                    target="_blank"
                                    href="https://appt.link/meet-with-globalong/">https://appt.link/meet-with-globalong/</a> ou
                                nous appeler au 0483580806 pendant nos horaires d'ouverture.

                                <br/>
                                Si vous êtes en mission, merci de contacter votre coordinateur en priorité pour toute
                                urgence.</Text>
                            <Divider/>
                            {this.state.formZone}
                        </div>
                    </Layout>
                </Layout>
                <Divider dashed/>
                <FooterApps/>
            </Layout>
        );
    }
}

export default Contact;