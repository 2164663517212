import React, {Component} from 'react';
import {Col, Row, Typography, Alert, Switch, Select} from "antd";


const {Title, Text} = Typography;

class ContactTel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            choixSelect: (localStorage.getItem('order_deja_contact')) ? localStorage.getItem('order_deja_contact') : 'null',
        }
    }
    componentDidMount() {

        if(localStorage.getItem('order_deja_contact') && localStorage.getItem('order_deja_contact') === 'non') {
           // this.setState({})
        }

    }

    handleChange = (value) => {

        this.setState({choixSelect: value});

        if (value === 'null') {
            localStorage.removeItem("order_deja_contact");
        } else {
            localStorage.setItem("order_deja_contact", value);
        }

    }

    render() {
        return (
            <div className="center">
                <div className="centerTxt mb10 mt10">
                    <Title level={3}>Avez-vous déjà eu un contact téléphonique avec l'association Globalong ?</Title>
                </div>

                <Row>
                    <Col span={24}>
                        <Select
                            defaultValue={this.state.choixSelect}
                            style={{
                                width: 120,
                            }}
                            onChange={this.handleChange}
                            options={[
                                {
                                    value: 'null',
                                    label: 'Choix',
                                },
                                {
                                    value: 'oui',
                                    label: 'oui',
                                },
                                {
                                    value: 'non',
                                    label: 'non',
                                },
                            ]}
                        />
                        <br/>
                        <br/>
                        {(this.state.choixSelect === 'non') ? <Alert
                            message={<><div>Merci de prendre un rendez-vous téléphonique à votre convenance via ce lien : <a href='https://appt.link/meet-with-globalong/' target='_blank'>https://appt.link/meet-with-globalong/</a></div> <div>En attendant l'échange téléphonique, vous pouvez continuer en appuyant sur « suivant » et déposer votre candidature :)</div></>}
                            type="info"
                        /> : ''}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ContactTel;