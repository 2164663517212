import React from 'react';
import MenuDashboard from "./Menu";
import "./css/dashboard.css";
import logoGLob from "./../../img/logoglob.png"
import ListeCmd from "./ListeCmd";
import {
    UserOutlined,
    SettingFilled,
    SmileOutlined,
    SyncOutlined,
    LoadingOutlined,
    SolutionOutlined,
} from '@ant-design/icons';

import {Form, Input, Button, Checkbox, Row, Col, Typography, Divider, Steps, Layout, Result} from 'antd';
import HeaderMember from "./HeaderMember";

const {Title, Text, Link} = Typography;
const {Step} = Steps;
const {Header, Content, Footer, Sider} = Layout;

class OrderMsg extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: Number(this.props.match.params.id),
            type: this.props.match.params.type,
        }
    }

    componentDidMount() {
        let url = "/ident/order/" + this.state.id;
        this.setState({
            btnEnd: <Button type="primary" key="console" href={url}>
                Revenir sur ma commande
            </Button>
        })
    }

    renderMsgSwitch(type) {

        switch (type) {
            case 'success':
                var result = <Result
                    status="success"
                    title="Versement enregistré"
                    subTitle="Merci nous allons procéder très rapidement à la vérification de votre versement."
                    extra={this.state.btnEnd}
                />
                break;
            case 'error':
                var result = <Result
                    status="warning"
                    title="Versement non accepté"
                    subTitle="Votre versement n'a pas été finalisé, merci de vous rapprocher du support de Globalong ou de votre banque."
                    extra={this.state.btnEnd}
                />
                break;
            default:
                var result = <Result
                    status="error"
                    title="Erreur"
                    subTitle="Nous n'avons pas identifié le retour de la commande, merci de prendre contact avec le support."
                    extra={this.state.btnEnd}
                />

        }

        return result;
    }


    render() {
        //console.log(this.state.type)
        return (
            <Layout>
                <HeaderMember/>
                <Layout>
                    <MenuDashboard/>
                    <div className="contentBox">
                        {this.renderMsgSwitch(this.state.type)}
                    </div>
                </Layout>
            </Layout>
        );
    }

}

export default OrderMsg;



