import React from "react";
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fr';

import {Col, DatePicker, Divider, Row, Select, Space, Switch, Typography} from "antd";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlaneDeparture, faCalendarAlt} from '@fortawesome/free-solid-svg-icons'
import './../css/step.css'
import axios from 'axios';
import {API_URI} from "../../../constant/Config"

moment.locale('fr-fr');

const {Option} = Select;
const {Title, Text} = Typography;

class DateOpen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dataDates: false,
            dataPacks: false,
            dateStringSelect: false,
            resetDatesDisp: true,
            resetPacks: true,
            idPaysSejour: null,
            idSejour: null,
            cat: null,
            defaultValuePack: 'Packs disponibles',
            defaultValueDate: 'Dates disponibles',
            defaultValueDateDesired: '',
            dateSelect: false,
            packSelect: false,
            displayPack : true,
        }

    }

    async componentDidUpdate(prevProps, prevState) {


        if (prevProps.idPaysSejour !== this.state.idPaysSejour) {
            await this.setState({
                idPaysSejour: prevProps.idPaysSejour,
            })

            // await this.selectDate(null, '2021-10-18')

            if (localStorage.getItem('order_id_pack')) {
                let idPack = localStorage.getItem('order_id_pack');
                //this.setState({defaultValuePack: parseInt(idPack), defaultValueDate: '2020-11-09'})
            }

        }

        if ((prevProps.idSejour !== this.state.idSejour)&& !localStorage.getItem('order_formSite')) {
            this.setState({
                idSejour: prevProps.idSejour,
                dataDates: false,
                dataPacks: false,
                defaultValuePack: 'Packs disponibles',
                defaultValueDate: 'Dates disponibles',
                resetPacks: true,
                resetDatesDisp: true,
                dateStringSelect: false,
            })

            this.resetAll();
        }

        if (prevProps.cat !== this.state.cat) {
            this.setState({
                cat: prevProps.cat,
            })
        }

    }

    componentDidMount = async () => {

        // si l'étape a déjà été validée
        if (localStorage.getItem('order_valStep_1')) {
            await this.selectDate(false, localStorage.getItem('order_date_desirate'))
            var order_date_debut_fr = moment(localStorage.getItem('order_date_debut'), 'YYYY-MM-DD').format('DD/MM/YYYY');

            this.resetAll();
            let idSejour = localStorage.getItem('order_id_sejour');
            let idPaysSejour = JSON.parse(localStorage.getItem('order_pays'));
            let order_date_debut = localStorage.getItem('order_date_debut');
            let order_id_pack = localStorage.getItem('order_id_pack');
            let dataDates = await this.getDates(idPaysSejour.idPays, idSejour)
            let dataPacks = await this.getPacks(idPaysSejour.idPays, idSejour)



            await this.setState({
                resetDatesDisp: false,
                resetPacks: false,
                dataDates: dataDates.data,
                dataPacks: dataPacks.data,
                //dateStringSelect: order_date_debut_fr,
                dateSelect: order_date_debut,
                packSelect: parseInt(order_id_pack),
            })

            //alert(this.state.idPaysSejour)
        } else if (localStorage.getItem('order_formSite')) {
            //  this.selectDatePacks(localStorage.getItem('order_formSite'))
            //localStorage.getItem('order_date_desirate')
            let idSejour = localStorage.getItem('order_id_sejour');
            let idPaysSejour = JSON.parse(localStorage.getItem('order_pays'));
            let dateDesirate = localStorage.getItem('order_date_desirate');

            await this.resetAll();
            await this.setState({dateStringSelect: dateDesirate})

            // obtenir les dates
            let dataDates = await this.getDatesWithDate(idPaysSejour.idPays, idSejour, dateDesirate);
            let dataPacks = await this.getPacksFullParam(idPaysSejour.idPays, idSejour);

            // définir si c'est un séjour de type récurrent ou fixe si fixe il faut pas afficher les packs
            let reccMode = Object.values(dataDates.data)[0].recc;
            if(reccMode == 'off'){
                localStorage.setItem('order_date_type','fixe');
                this.setState({displayPack:true})
            }else{
                localStorage.setItem('order_date_type','recc');
                this.setState({displayPack:true})
            }

            await this.setState({
                resetDatesDisp: false,
                resetPacks: false,
                dataDates: dataDates.data,
                dataPacks: dataPacks.data,
                idPaysSejour : idPaysSejour,
                idSejour : idSejour,
            })

            await this.props.dateStart(dateDesirate)

        }
    }
//idpaysejour=' + idPaysSejour
    getDates = (idPaysSejour, idSejour) => {
        let data = axios.get(API_URI + '/api_member/liste_dates_sejour?idpaysejour=' + idPaysSejour + '&dateStart=' + this.state.dateStringSelect + '&idsejour=' + idSejour);

        return data
    }

    getDatesWithDate = (idPaysSejour, idSejour, dateFr) => {
        let data = axios.get(API_URI + '/api_member/liste_dates_sejour?idpaysejour=' + idPaysSejour + '&dateStart=' + dateFr + '&idsejour=' + idSejour);

        return data
    }

    //loadData = ()

    selectDate = async (e, dateString) => {
        await this.resetAll();
        await this.setState({dateStringSelect: dateString})
        let dataDates = await this.getDates(this.state.idPaysSejour, this.state.idSejour)
        let dataPacks = await this.getPacks(this.state.idPaysSejour, this.state.idSejour)
        await this.setState({
            resetDatesDisp: false,
            resetPacks: false,
            dataDates: dataDates.data,
            dataPacks: dataPacks.data,
            dateStringSelect: dateString,
        })
        await this.props.dateStart(dateString)
        localStorage.setItem("order_date_desirate", dateString);
    }

    resetAll = () => {
        this.setState({
            dateSelect: false,
            packSelect: false,
        })
    }

    // gestiond es packs
    getPacks = async (idPaysSejour, idSejour) => {
        let data = await axios.get(API_URI + '/api_member/liste_packs?idpaysejour=' + idPaysSejour + '&idcat=' + this.props.cat + '&idsejour=' + this.props.idSejour);

        return data
    }

    getPacksFullParam = async (idPaysSejour, idSejour) => {
        let data = await axios.get(API_URI + '/api_member/liste_packs?idpaysejour=' + idPaysSejour + '&idcat=' + this.props.cat + '&idsejour=' + idSejour);

        return data
    }

    // mémorisation des données
    selectDatePacks = (value,e) => {
        // Définir displayPack à false si data-recc est 'on', sinon à true
        const dataRecc = e['data-recc'];
        const shouldDisplayPack = dataRecc !== 'off';

       // const idDate = e.currentTarget.getAttribute("data-id");
        localStorage.setItem("order_date_debut", value);
        localStorage.setItem("order_date_id", e["data-id"]);
        this.setState({dateSelect: value})
        this.props.dateDebut(value)

        // Ajouter une exception pour l'Islande
        if(e['data-idpaysejour'] == 196) {
            this.setState({ displayPack : true });
        } else {
            this.setState({ displayPack : shouldDisplayPack });
        }

        if(dataRecc == 'off'){
            localStorage.removeItem('order_id_pack');
            localStorage.setItem('order_date_type','fixe');
        }else{
            localStorage.setItem('order_date_type','recc');
        }
    }

    selectPacks = async (value, e) => {
        // const datalangue = await e.currentTarget.getAttribute("data-langue");
        localStorage.setItem("order_id_pack", value);
        this.setState({packSelect: value})
        //localStorage.setItem("order_id_sejour", value);
        this.props.changePack(value);
    }

    render() {


        const dataDates = this.state.dataDates;

        var listeDates = Object.keys(dataDates).map(function (key) {
            return <Option
                value={dataDates[key]['dateStart']}
                data-id={dataDates[key]['id']}
                data-recc={dataDates[key]['recc']}
                data-idpaysejour={dataDates[key]['idpaysejour']}>
                {dataDates[key]['libFull']}
            </Option>
        });


        const dataPacks = this.state.dataPacks;
        if (this.state.displayPack) {
            var listePack = Object.keys(dataPacks).map(function (key) {
                return <Option value={dataPacks[key]['id']} data-benevolat={dataPacks[key]['benevolat']}
                               data-langue={dataPacks[key]['langue']}>{dataPacks[key]['lib'] + ' ' + dataPacks[key]['prix'] + ' Euros'} </Option>
            });

            var displayePack = <div>
                <Divider dashed/>
                <div className="center" style={{marginTop: "40px"}}>
                    <Title level={2}><FontAwesomeIcon icon={faPlaneDeparture}/>Choisissez la durée de votre engagement</Title>
                    <Text type={"secondary"}>Pour toute autre durée, contactez nous</Text>

                    <br/>
                    <Select
                        placeholder="Select a person"
                        onSelect={this.selectPacks}
                        disabled={(this.state.resetPacks) ? true : false}
                        style={{width: 700}}
                        defaultValue={this.state.defaultValuePack}
                        value={(this.state.packSelect) ? this.state.packSelect : 'Cliquez ici pour choisir la durée en nombre de semaines'}

                    >
                        {listePack}
                    </Select>
                </div>
            </div>

        } else {
            var displayePack = <div></div>;
        }



        if (this.props.resetListeDate) {
            return (<div></div>)
        } else {

            return (
                <div>
                    <Row>
                        <Col span={24} className="centerTxt">
                            <div className="centerTxt mb10 mt10">
                                <Title level={2}>Autour de quelle date souhaiteriez-vous commencer ?</Title>
                            </div>
                            <Space direction="vertical">
                                <DatePicker
                                    showToday={false}
                                    disabled={(this.props.resetListeDate) ? true : false}
                                    format={['DD/MM/YYYY', 'DD/MM/YY']}
                                    onChange={this.selectDate}
                                    //value={(this.state.dateStringSelect) ? moment(this.state.dateStringSelect, 'YYYY-MM-DD') : false}
                                />
                            </Space>
                        </Col>
                    </Row>

                    <Divider dashed/>

                    <div style={{marginTop: "40px"}}>
                        <div className="center">
                            <Title level={2}><FontAwesomeIcon icon={faCalendarAlt}/> Liste des dates les plus proches de votre choix</Title>
                            <Text type={"secondary"}>Merci de sélectionner une date pour pouvoir avancer à l'étape
                                suivante</Text>
                            <br/>

                            <Select onSelect={this.selectDatePacks}
                                    disabled={(this.state.resetDatesDisp) ? true : false}
                                    defaultValue={this.state.defaultValueDate}
                                    style={{width: 500}}
                                    value={(this.state.dateSelect) ? this.state.dateSelect : ''}
                            >
                                {listeDates}
                            </Select>
                        </div>

                        {displayePack}

                    </div>
                </div>
            )
        }

    }
}

export default DateOpen