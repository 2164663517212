import React from "react";
import { Redirect } from "react-router-dom";
import {PageHeader, Row, Col, Typography, Divider,Button} from 'antd';
import '../css/header.css'
import logoGLob from "../../../img/logoglob.png";

const {Title, Paragraph, Text} = Typography;

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            titre: (this.props.paramHeader['title']) ? this.props.paramHeader['title'] : 'Bienvenue !',
            desc: (this.props.paramHeader['desc']) ? this.props.paramHeader['desc'] : 'Votre inscription peut commencer',
            redirect: null,
            idMembre:null,

        }
    }

    componentDidMount() {
        let idMembre = localStorage.getItem('membre_id');
        if(idMembre){
            this.setState({idMembre:idMembre})
        }
    }

    goToAccount = () => {
        localStorage.removeItem("membre_menu");
       this.setState({redirect: '/ident/dashboard'})
    }
    goToSite = () => {
        window.location.href = "https://www.globalong.com";
        //http://localhost:3000/ident
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }

        if (this.state.idMembre) {
            return (
                <div>
                    <PageHeader
                        className="site-page-header"
                        onBack={this.goToSite}
                        title="Globalong"
                        subTitle="Revenir sur le site"
                        extra={[
                            <Button key="3" onClick={this.goToAccount}>Mon compte</Button>,
                            <img style={{width: "150px"}} src={logoGLob}/>,
                        ]}
                    />
                    <Row className="headerImg" justify="space-around" align="middle">
                        <Col span={24}>
                        </Col>
                    </Row>
                </div>
            )
        }else{
            return (
                <div>
                    <PageHeader
                        className="site-page-header"
                        onBack={this.goToSite}
                        title="Globalong"
                        subTitle="Revenir sur le site"
                        extra={[
                            <img style={{width: "150px"}} src={logoGLob}/>
                        ]}
                    />
                    <Row className="headerImg" justify="space-around" align="middle">
                        <Col span={24}>
                        </Col>
                    </Row>
                </div>
            )
        }
    }
}

export default Header