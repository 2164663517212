import React from 'react';

import "./css/dashboard.css";
import logoGLob from "./../../img/logoglob.png"
import {Link, Redirect} from 'react-router-dom';


import {Layout, Menu} from 'antd';
import {
    UserOutlined,
    UnorderedListOutlined,
    HomeOutlined,
    MailOutlined,
    LogoutOutlined,
    QuestionOutlined,

} from '@ant-design/icons';

const {SubMenu} = Menu;
const {Header, Content, Footer, Sider} = Layout;

class Dashboard extends React.Component {

    state = {
        current: (localStorage.getItem('membre_menu')) ? localStorage.getItem('membre_menu') : 'accueil'
    };

    handleClick = e => {
        localStorage.setItem('membre_menu', e.key);
        console.log('click ', e.key);
        this.setState({current: e.key});
    };

    render() {
        const {current} = this.state;

        if (localStorage.getItem('membre_id')) {

        } else {
            return <Redirect to='/ident'/>;
        }

        return (
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={broken => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
            >


                <div>



                    <Menu onClick={this.handleClick} selectedKeys={[current]} mode="inline">
                        <Menu.Item key="accueil" icon={<HomeOutlined/>}>
                            <Link className="btn btn-primary" to="/ident/dashboard">
                                Tableau de bord
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="profil" icon={<UserOutlined/>}>
                            <Link className="btn btn-primary" to="/ident/profil">
                                Mon Profil
                            </Link>

                        </Menu.Item>
                        <Menu.Item key="commande" icon={<UnorderedListOutlined/>}>
                            <Link className="btn btn-primary" to="/ident/order">
                                Mes projets
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="contact" icon={<MailOutlined/>}>
                            <Link className="btn btn-primary" to="/ident/contact">
                                Contact
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="aide" icon={<QuestionOutlined/>}>
                            <a className="btn btn-primary" target="_blank" href="https://tinyurl.com/Globalong-Espace-Membre">
                                Aide
                            </a>
                        </Menu.Item>
                        <Menu.Item key="deco" icon={<LogoutOutlined/>}>
                            <Link className="btn btn-primary" to="/ident/destroy">
                                Déconnexion
                            </Link>
                        </Menu.Item>
                    </Menu>
                </div>
            </Sider>
        );
    }
}


export default Dashboard;
