import React, {Component} from 'react';
import {
    Button,
    DatePicker,
    Form,
    Input,
    Select,
    Typography,
    Checkbox,
    Cascader,
    Divider,
    Result,
    Alert,
    notification
} from "antd";
import axios from "axios";
import {API_URI} from "../../../constant/Config";
import {Redirect} from "react-router-dom";

const {Title, Text, Link} = Typography;
const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};
const tailLayout = {
    wrapperCol: {offset: 0, span: 24},
};


class FormRegister extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dataMembre: {
                goNext: false,
                goSuccess: false,
                member_cv: '',
                member_prenom: '',
                member_nom: '',
                member_mdp: '',
                member_age: '',
                member_nationalite: '',
                member_pays_naissance: '',
                member_email: '',
                member_adresse: '',
                member_ville: '',
                member_cp: '',
                member_pays_permanent: '',
                member_tel1: '',
                member_tel2: '',
                member_conturg_civ: '',
                member_conturg_prenom: '',
                member_conturg_nom: '',
                member_conturg_lienvolontaire: '',
                member_conturg_tel1: '',
                member_conturg_email1: '',
                member_situation: '',
                member_situationdepart: '',
                member_form_nom_etab: '',
                member_niv_etude: '',
                member_specialite: '',
                member_nom_formation: '',
                member_autre_form: '',
                dateNaissance: '',
                alerte: false,
                loading: false,
            }
        }
    }

    selectNiveauLangue = () => {
        var optionsValues = <div><Select style={{width: 300}}>
            <option value="" selected="selected">Votre niveau</option>
            <option value="a">Débutant absolu*</option>
            <option value="b">Élémentaire*</option>
            <option value="c">Intermédiaire</option>
            <option value="d">Intermédiaire supérieur</option>
            <option value="e">Avancé</option>
            <option value="f">Avancé supérieur</option>
            <option value="g">C’est ma langue maternelle</option>
        </Select></div>

        return optionsValues
    }


    onFinish = async values => {

        this.setState({loading: true});
        const testEmail = await axios.post(API_URI + '/api-member/membre-test-exist', values);

        if (testEmail.data.exist) {
            // si le membre existe déjà refuser
            notification.info({
                message: `Notification `,
                description:
                    'Un compte existe déjà avec cette adresse email, merci de vous faire envoyer un nouveau mot de passe via "Mot de passe perdu"'
            });
        } else {
            // si le membre existe pas

            var valPost = {...values, dateNaissance: this.state.dateNaissance}

            // console.log('Success:', values);
            // const res = await axios.post(API_URI + '/api_member/bac_a_sable', valPost);

            const res = await axios.post(API_URI + '/api_member/creat_member', valPost);
            localStorage.setItem('membre_token', res.data.membre_token)
            localStorage.setItem('membre_id', res.data.membre_id)
            localStorage.setItem('membre_nom', res.data.membre_nom)
            localStorage.setItem('membre_prenom', res.data.membre_prenom)
            this.setState({goSuccess: true, alerte: false})
        }
        this.setState({loading: false});

    };

    /**
     * Le but de cette fonction est de récupérer la date string pour palier au problème de la date envoyée -1j
     * mise à jour du state puis envoi au format FR
     *
     * @param date
     * @param dateString
     */
    onChangeDateNaissance = (date, dateString) => {
        //console.log(date, dateString);
        this.setState({dateNaissance: dateString})
        //axios.post(API_URI + '/api_member/bac_a_sable', date);
    };

    finishSubscribe = () => {
        this.setState({loading: true});
        this.setState({goNext: true})
        this.setState({loading: false});
    }

    onFinishFailed = errorInfo => {
        this.setState({loading: true});
        console.log('Failed:', errorInfo);
        this.setState({
            alerte: <div><Alert message="Il manque des informations merci de bien compléter le formulaire."
                                type="warning" showIcon closable/><br/></div>
        })
        this.setState({loading: false});
    };

    testPassword = (password) => {
        this.setState({passeInsert: true});
        var passValue = password.target.value;

        if (passValue.length >= 8) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        if (this.state.goNext) {
            return <Redirect to="/order/check"/>;
        }

        if (this.state.goSuccess) {
            let message = <div>
                Votre espace Globalong vous permet :
                <br/><br/>

                De candidater pour un projet de bénévolat ou de vous inscrire pour une formation linguistique<br/>
                De communiquer avec notre équipe<br/>
                De nous fournir les informations importantes concernant votre projet<br/>
                De télécharger des documents importants à la préparation de votre projet<br/>
                De nous fournir les documents indispensables avant votre arrivée dans le pays d’accueil<br/>
                De verser la cotisation d’adhésion et verser le/les dons<br/>

                <br/><br/>
                En naviguant dans le menu à gauche de votre espace Globalong, vous pouvez modifier votre profil,
                compléter vos informations, voir l’état de votre dossier et de vos versements ou encore nous contacter.
                <br/><br/>
                Pour plus de renseignements, n’hésitez pas à nous contacter à l'adresse <a
                href="mailto:info@globalong.com">info@globalong.com</a>
                <br/>
                Nous pouvons également convenir d'un rendez-vous téléphonique pour en discuter de vive voix. <a
                href="https://appt.link/meet-with-globalong/" target="_blank">Cliquez ici pour voir nos disponibilités</a> et
                prendre rendez-vous.
                <br/>
                <br/>
                <br/>
                Cordialement,<br/>
                <strong>L'équipe Globalong</strong>
                <a href="www.globalong.com" target="_blank">www.globalong.com</a>
            </div>
            return (<div>
                <Result
                    status="success"
                    title="Merci d’avoir créé votre espace Globalong. "
                    subTitle={message}
                    extra={[
                        <Button type="primary" key="console" onClick={this.finishSubscribe}>
                            Retour à l'inscription
                        </Button>
                    ]}
                />
            </div>);
        }

        return (
            <div>
                <div className="centerTxt mb10 mt10">
                    <Title level={3}>Formulaire d'inscription</Title>
                </div>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{remember: true}}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >

                    <Form.Item
                        label="Civilité"
                        name="member_cv"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_cv}
                    >
                        <Select style={{width: 120}}>
                            <option value="mr" selected="selected">Monsieur</option>
                            <option value="mme">Mme.</option>
                            <option value="mr">M.</option>
                        </Select>

                    </Form.Item>

                    <Form.Item
                        label="Prénom"
                        name="member_prenom"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_prenom}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Nom"
                        name="member_nom"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_nom}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Date de naissance"
                        name="member_age"
                        rules={[{required: true, message: 'Date au format JJ/MM/YYYY'}]}
                    >
                        <DatePicker style={{width: "100%"}} format={'DD/MM/YYYY'}
                                    onChange={this.onChangeDateNaissance}/>
                    </Form.Item>

                    <Form.Item
                        label="Nationalité"
                        name="member_nationalite"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_nationalite}
                    >
                        <Select style={{width: "100%"}}>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Afrique du Sud">Afrique du Sud</option>
                            <option value="Aland (Îles)">Aland (Îles)</option>
                            <option value="Albanie">Albanie</option>
                            <option value="Algérie">Algérie</option>
                            <option value="Allemagne">Allemagne</option>
                            <option value="Andorre">Andorre</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antarctique">Antarctique</option>
                            <option value="Antigua et Barbuda">Antigua et Barbuda</option>
                            <option value="Antilles néerlandaises">Antilles néerlandaises</option>
                            <option value="Arabie Saoudite">Arabie Saoudite</option>
                            <option value="Argentine">Argentine</option>
                            <option value="Arménie">Arménie</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australie">Australie</option>
                            <option value="Autriche">Autriche</option>
                            <option value="Azerbaïdjan">Azerbaïdjan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahreïn">Bahreïn</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbade">Barbade</option>
                            <option value="Belgique">Belgique</option>
                            <option value="Belize">Belize</option>
                            <option value="Bénin">Bénin</option>
                            <option value="Bermudes">Bermudes</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Biélorussie">Biélorussie</option>
                            <option value="Bolivie">Bolivie</option>
                            <option value="Bosnie-Herzégovine">Bosnie-Herzégovine</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Bouvet (Île)">Bouvet (Île)</option>
                            <option value="Brésil">Brésil</option>
                            <option value="Brunei">Brunei</option>
                            <option value="Bulgarie">Bulgarie</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Caïmans (Îles)">Caïmans (Îles)</option>
                            <option value="Cambodge">Cambodge</option>
                            <option value="Cameroun">Cameroun</option>
                            <option value="Canada">Canada</option>
                            <option value="Cap Vert">Cap Vert</option>
                            <option value="Chili">Chili</option>
                            <option value="Chine">Chine</option>
                            <option value="Christmas (Îles)">Christmas (Îles)</option>
                            <option value="Chypre">Chypre</option>
                            <option value="Cocos">Cocos</option>
                            <option value="Colombie">Colombie</option>
                            <option value="Comores">Comores</option>
                            <option value="Congo">Congo</option>
                            <option value="Congo, République démocratique du (Congo)">Congo, République démocratique du (Congo)</option>
                            <option value="Cook (Îles)">Cook (Îles)</option>
                            <option value="Corée du Nord">Corée du Nord</option>
                            <option value="Corée du Sud">Corée du Sud</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                            <option value="Croatie">Croatie</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Danemark">Danemark</option>
                            <option value="Dépendances américaines du Pacifique">Dépendances américaines du Pacifique</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominique">Dominique</option>
                            <option value="Égypte">Égypte</option>
                            <option value="Émirats Arabes Unis">Émirats Arabes Unis</option>
                            <option value="Équateur">Équateur</option>
                            <option value="Érythrée">Érythrée</option>
                            <option value="Espagne">Espagne</option>
                            <option value="Estonie">Estonie</option>
                            <option value="États-Unis">États-Unis</option>
                            <option value="Éthiopie">Éthiopie</option>
                            <option value="Faroe (Îles)">Faroe (Îles)</option>
                            <option value="Fidji">Fidji</option>
                            <option value="Finlande">Finlande</option>
                            <option value="France">France</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambie">Gambie</option>
                            <option value="Géorgie">Géorgie</option>
                            <option value="Géorgie du Sud et îles Sandwich du Sud">Géorgie du Sud et îles Sandwich du Sud</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Grèce">Grèce</option>
                            <option value="Grenade">Grenade</option>
                            <option value="Groenland">Groenland</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guernesey">Guernesey</option>
                            <option value="Guinée">Guinée</option>
                            <option value="Guinée-Bissau">Guinée-Bissau</option>
                            <option value="Guinée équatoriale">Guinée équatoriale</option>
                            <option value="Guyane">Guyane</option>
                            <option value="Guyane française">Guyane française</option>
                            <option value="Haïti">Haïti</option>
                            <option value="Heard et McDonald (Îles)">Heard et McDonald (Îles)</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong-Kong">Hong-Kong</option>
                            <option value="Hongrie">Hongrie</option>
                            <option value="Îles vierges britanniques">Îles vierges britanniques</option>
                            <option value="Inde">Inde</option>
                            <option value="Inde du Nord Delhi">Inde du Nord Delhi</option>
                            <option value="Inde du Sud">Inde du Sud</option>
                            <option value="Indonésie">Indonésie</option>
                            <option value="Irak">Irak</option>
                            <option value="Iran">Iran</option>
                            <option value="Irlande">Irlande</option>
                            <option value="Islande">Islande</option>
                            <option value="Israël">Israël</option>
                            <option value="Italie">Italie</option>
                            <option value="Jamaïque">Jamaïque</option>
                            <option value="Japon">Japon</option>
                            <option value="Jersey">Jersey</option>
                            <option value="Jordanie">Jordanie</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kirghizistan">Kirghizistan</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Koweït">Koweït</option>
                            <option value="Laos">Laos</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Lettonie">Lettonie</option>
                            <option value="Liban">Liban</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libye">Libye</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lituanie">Lituanie</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macao">Macao</option>
                            <option value="Macédoine">Macédoine</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malaisie">Malaisie</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malouines (Îles)">Malouines (Îles)</option>
                            <option value="Malte">Malte</option>
                            <option value="Man (Île)">Man (Île)</option>
                            <option value="Mariannes du Nord (Commonwealth des îles)">Mariannes du Nord (Commonwealth des îles)</option>
                            <option value="Maroc">Maroc</option>
                            <option value="Marshall (Îles)">Marshall (Îles)</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Maurice (Île)">Maurice (Île)</option>
                            <option value="Mauritanie">Mauritanie</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexique">Mexique</option>
                            <option value="Micronésie">Micronésie</option>
                            <option value="Moldavie">Moldavie</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolie">Mongolie</option>
                            <option value="Monténégro">Monténégro</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Namibie">Namibie</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Népal">Népal</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigéria">Nigéria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk (Île de)">Norfolk (Île de)</option>
                            <option value="Norvège">Norvège</option>
                            <option value="Nouvelle-Calédonie">Nouvelle-Calédonie</option>
                            <option value="Nouvelle-Zélande">Nouvelle-Zélande</option>
                            <option value="Oman">Oman</option>
                            <option value="Ouganda">Ouganda</option>
                            <option value="Ouzbékistan">Ouzbékistan</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Panama">Panama</option>
                            <option value="Papouasie-Nouvelle Guinée">Papouasie-Nouvelle Guinée</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Pays-Bas">Pays-Bas</option>
                            <option value="Pérou">Pérou</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Pitcairn (Île)">Pitcairn (Île)</option>
                            <option value="Pologne">Pologne</option>
                            <option value="Polynésie française">Polynésie française</option>
                            <option value="Porto Rico">Porto Rico</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Qatar">Qatar</option>
                            <option value="République Centrafricaine">République Centrafricaine</option>
                            <option value="République Dominicaine">République Dominicaine</option>
                            <option value="République Tchèque">République Tchèque</option>
                            <option value="Réunion (Île de la)">Réunion (Île de la)</option>
                            <option value="Roumanie">Roumanie</option>
                            <option value="Royaume-Uni">Royaume-Uni</option>
                            <option value="Russie">Russie</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Sahara oriental">Sahara oriental</option>
                            <option value="Sainte-Hélène">Sainte-Hélène</option>
                            <option value="Sainte-Lucie">Sainte-Lucie</option>
                            <option value="Saint-Kitts-et-Nevis">Saint-Kitts-et-Nevis</option>
                            <option value="Saint-Marin">Saint-Marin</option>
                            <option value="Saint-Pierre-et-Miquelon">Saint-Pierre-et-Miquelon</option>
                            <option value="Saint-Vincent et les Grenadines">Saint-Vincent et les Grenadines</option>
                            <option value="Salvador">Salvador</option>
                            <option value="Samoa">Samoa</option>
                            <option value="Samoa américaines">Samoa américaines</option>
                            <option value="São Tomé et Príncipe">São Tomé et Príncipe</option>
                            <option value="Sénégal">Sénégal</option>
                            <option value="Serbie">Serbie</option>
                            <option value="Serbie et Monténégro">Serbie et Monténégro</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapour">Singapour</option>
                            <option value="Slovaquie">Slovaquie</option>
                            <option value="Slovénie">Slovénie</option>
                            <option value="Solomon">Solomon</option>
                            <option value="Somalie">Somalie</option>
                            <option value="Soudan">Soudan</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Suède">Suède</option>
                            <option value="Suisse">Suisse</option>
                            <option value="Surinam">Surinam</option>
                            <option value="Svalbard et Jan Mayen">Svalbard et Jan Mayen</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Syrie">Syrie</option>
                            <option value="Tadjikistan">Tadjikistan</option>
                            <option value="Taïwan">Taïwan</option>
                            <option value="Tanzanie">Tanzanie</option>
                            <option value="Tchad">Tchad</option>
                            <option value="Territoires britanniques de l'Océan Indien">Territoires britanniques de l'Océan Indien</option>
                            <option value="Territoires d'Outre-mer (France)">Territoires d'Outre-mer (France)</option>
                            <option value="Territoires palestiniens">Territoires palestiniens</option>
                            <option value="Thaïlande">Thaïlande</option>
                            <option value="Thaïlande - Ayutthaya">Thaïlande - Ayutthaya</option>
                            <option value="Thaïlande Chiang Mai/Nong Khai">Thaïlande Chiang Mai/Nong Khai</option>
                            <option value="Timor oriental">Timor oriental</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad et Tobago (Trinidad and Tobago)">Trinidad et Tobago (Trinidad and Tobago)</option>
                            <option value="Tunisie">Tunisie</option>
                            <option value="Turkménistan">Turkménistan</option>
                            <option value="Turks et Caïcos (Îles)">Turks et Caïcos (Îles)</option>
                            <option value="Turquie">Turquie</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Vatican">Vatican</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Vierges américaines (Îles)">Vierges américaines (Îles)</option>
                            <option value="Vietnam">Vietnam</option>
                            <option value="Vietnam Hanoi">Vietnam Hanoi</option>
                            <option value="Vietnam Ho Chi Minh">Vietnam Ho Chi Minh</option>
                            <option value="Wallis et Futuna">Wallis et Futuna</option>
                            <option value="Yémen">Yémen</option>
                            <option value="Zambie">Zambie</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                            <option value="Bolivie - humanitaire">Bolivie - humanitaire</option>
                            <option value="Bolivie - éco-volontariat">Bolivie - éco-volontariat</option>
                            <option value="Equateur - humanitaire">Equateur - humanitaire</option>
                            <option value="Equateur - éco-volontariat">Equateur - éco-volontariat</option>
                            <option value="Iles Galapagos">Iles Galapagos</option>
                            <option value="Sri Lanka – Eléphants">Sri Lanka – Eléphants</option>
                            <option value="Australie – écovolontariat">Australie – écovolontariat</option>
                            <option value="Afrique du Sud - Soins animaliers">Afrique du Sud - Soins animaliers</option>
                            <option value="Afrique du Sud - Faune sauvage">Afrique du Sud - Faune sauvage</option>
                            <option value="Majorque">Majorque</option>
                            <option value="Sri Lanka - Tortues">Sri Lanka - Tortues</option>
                            <option value="Etats-Unis - chevaux">Etats-Unis - chevaux</option>
                            <option value="Inde - Himalaya">Inde - Himalaya</option>
                            <option value="Tanzanie - Zanzibar">Tanzanie - Zanzibar</option>
                            <option value="Thaïlande - animaux">Thaïlande - animaux</option>
                            <option value="Thaïlande - Udon Thani">Thaïlande - Udon Thani</option>
                            <option value="Thaïlande - Bangkok/Chiang Mai">Thaïlande - Bangkok/Chiang Mai</option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Pays de naissance"
                        name="member_pays_naissance"
                        rules={[{required: true, message: 'Please input your username!'}]}
                        initialValue={this.state.dataMembre.member_pays_naissance}
                    >
                        <Select style={{width: "100%"}}>
                            <option value="AF">Afghanistan</option>
                            <option value="ZA">Afrique du Sud</option>
                            <option value="AX">Aland (Îles)</option>
                            <option value="AL">Albanie</option>
                            <option value="DZ">Algérie</option>
                            <option value="DE">Allemagne</option>
                            <option value="AD">Andorre</option>
                            <option value="AO">Angola</option>
                            <option value="AI">Anguilla</option>
                            <option value="AQ">Antarctique</option>
                            <option value="AG">Antigua et Barbuda</option>
                            <option value="AN">Antilles néerlandaises</option>
                            <option value="SA">Arabie Saoudite</option>
                            <option value="AR">Argentine</option>
                            <option value="AM">Arménie</option>
                            <option value="AW">Aruba</option>
                            <option value="AU">Australie</option>
                            <option value="AT">Autriche</option>
                            <option value="AZ">Azerbaïdjan</option>
                            <option value="BS">Bahamas</option>
                            <option value="BH">Bahreïn</option>
                            <option value="BD">Bangladesh</option>
                            <option value="BB">Barbade</option>
                            <option value="BE">Belgique</option>
                            <option value="BZ">Belize</option>
                            <option value="BJ">Bénin</option>
                            <option value="BM">Bermudes</option>
                            <option value="BT">Bhutan</option>
                            <option value="BY">Biélorussie</option>
                            <option value="BO">Bolivie</option>
                            <option value="BA">Bosnie-Herzégovine</option>
                            <option value="BW">Botswana</option>
                            <option value="BV">Bouvet (Île)</option>
                            <option value="BR">Brésil</option>
                            <option value="BN">Brunei</option>
                            <option value="BG">Bulgarie</option>
                            <option value="BF">Burkina Faso</option>
                            <option value="BI">Burundi</option>
                            <option value="KY">Caïmans (Îles)</option>
                            <option value="KH">Cambodge</option>
                            <option value="CM">Cameroun</option>
                            <option value="CA">Canada</option>
                            <option value="CV">Cap Vert</option>
                            <option value="CL">Chili</option>
                            <option value="CN">Chine</option>
                            <option value="CX">Christmas (Îles)</option>
                            <option value="CY">Chypre</option>
                            <option value="CC">Cocos</option>
                            <option value="CO">Colombie</option>
                            <option value="KM">Comores</option>
                            <option value="CG">Congo</option>
                            <option value="CD">Congo, République démocratique du (Congo)</option>
                            <option value="CK">Cook (Îles)</option>
                            <option value="KP">Corée du Nord</option>
                            <option value="KR">Corée du Sud</option>
                            <option value="CR">Costa Rica</option>
                            <option value="CI">Côte d'Ivoire</option>
                            <option value="HR">Croatie</option>
                            <option value="CU">Cuba</option>
                            <option value="DK">Danemark</option>
                            <option value="UM">Dépendances américaines du Pacifique</option>
                            <option value="DJ">Djibouti</option>
                            <option value="DM">Dominique</option>
                            <option value="EG">Égypte</option>
                            <option value="AE">Émirats Arabes Unis</option>
                            <option value="EC">Équateur</option>
                            <option value="ER">Érythrée</option>
                            <option value="ES">Espagne</option>
                            <option value="EE">Estonie</option>
                            <option value="US">États-Unis</option>
                            <option value="ET">Éthiopie</option>
                            <option value="FO">Faroe (Îles)</option>
                            <option value="FJ">Fidji</option>
                            <option value="FI">Finlande</option>
                            <option value="FR">France</option>
                            <option value="GA">Gabon</option>
                            <option value="GM">Gambie</option>
                            <option value="GE">Géorgie</option>
                            <option value="GS">Géorgie du Sud et îles Sandwich du Sud</option>
                            <option value="GH">Ghana</option>
                            <option value="GI">Gibraltar</option>
                            <option value="GR">Grèce</option>
                            <option value="GD">Grenade</option>
                            <option value="GL">Groenland</option>
                            <option value="GP">Guadeloupe</option>
                            <option value="GU">Guam</option>
                            <option value="GT">Guatemala</option>
                            <option value="GG">Guernesey</option>
                            <option value="GN">Guinée</option>
                            <option value="GW">Guinée-Bissau</option>
                            <option value="GQ">Guinée équatoriale</option>
                            <option value="GY">Guyane</option>
                            <option value="GF">Guyane française</option>
                            <option value="HT">Haïti</option>
                            <option value="HM">Heard et McDonald (Îles)</option>
                            <option value="HN">Honduras</option>
                            <option value="HK">Hong-Kong</option>
                            <option value="HU">Hongrie</option>
                            <option value="VG">Îles vierges britanniques</option>
                            <option value="IN">Inde</option>
                            <option value="INN">Inde du Nord Delhi</option>
                            <option value="INS">Inde du Sud</option>
                            <option value="ID">Indonésie</option>
                            <option value="IQ">Irak</option>
                            <option value="IR">Iran</option>
                            <option value="IE">Irlande</option>
                            <option value="IS">Islande</option>
                            <option value="IL">Israël</option>
                            <option value="IT">Italie</option>
                            <option value="JM">Jamaïque</option>
                            <option value="JP">Japon</option>
                            <option value="JE">Jersey</option>
                            <option value="JO">Jordanie</option>
                            <option value="KZ">Kazakhstan</option>
                            <option value="KE">Kenya</option>
                            <option value="KG">Kirghizistan</option>
                            <option value="KI">Kiribati</option>
                            <option value="KW">Koweït</option>
                            <option value="LA">Laos</option>
                            <option value="LS">Lesotho</option>
                            <option value="LV">Lettonie</option>
                            <option value="LB">Liban</option>
                            <option value="LR">Liberia</option>
                            <option value="LY">Libye</option>
                            <option value="LI">Liechtenstein</option>
                            <option value="LT">Lituanie</option>
                            <option value="LU">Luxembourg</option>
                            <option value="MO">Macao</option>
                            <option value="MK">Macédoine</option>
                            <option value="MG">Madagascar</option>
                            <option value="MY">Malaisie</option>
                            <option value="MW">Malawi</option>
                            <option value="MV">Maldives</option>
                            <option value="ML">Mali</option>
                            <option value="FK">Malouines (Îles)</option>
                            <option value="MT">Malte</option>
                            <option value="IM">Man (Île)</option>
                            <option value="MP">Mariannes du Nord (Commonwealth des îles)</option>
                            <option value="MA">Maroc</option>
                            <option value="MH">Marshall (Îles)</option>
                            <option value="MQ">Martinique</option>
                            <option value="MU">Maurice (Île)</option>
                            <option value="MR">Mauritanie</option>
                            <option value="YT">Mayotte</option>
                            <option value="MX">Mexique</option>
                            <option value="FM">Micronésie</option>
                            <option value="MD">Moldavie</option>
                            <option value="MC">Monaco</option>
                            <option value="MN">Mongolie</option>
                            <option value="ME">Monténégro</option>
                            <option value="MS">Montserrat</option>
                            <option value="MZ">Mozambique</option>
                            <option value="MM">Myanmar</option>
                            <option value="NA">Namibie</option>
                            <option value="NR">Nauru</option>
                            <option value="NP">Népal</option>
                            <option value="NI">Nicaragua</option>
                            <option value="NE">Niger</option>
                            <option value="NG">Nigéria</option>
                            <option value="NU">Niue</option>
                            <option value="NF">Norfolk (Île de)</option>
                            <option value="NO">Norvège</option>
                            <option value="NC">Nouvelle-Calédonie</option>
                            <option value="NZ">Nouvelle-Zélande</option>
                            <option value="OM">Oman</option>
                            <option value="UG">Ouganda</option>
                            <option value="UZ">Ouzbékistan</option>
                            <option value="PK">Pakistan</option>
                            <option value="PW">Palau</option>
                            <option value="PA">Panama</option>
                            <option value="PG">Papouasie-Nouvelle Guinée</option>
                            <option value="PY">Paraguay</option>
                            <option value="NL">Pays-Bas</option>
                            <option value="PE">Pérou</option>
                            <option value="PH">Philippines</option>
                            <option value="PN">Pitcairn (Île)</option>
                            <option value="PL">Pologne</option>
                            <option value="PF">Polynésie française</option>
                            <option value="PR">Porto Rico</option>
                            <option value="PT">Portugal</option>
                            <option value="QA">Qatar</option>
                            <option value="CF">République Centrafricaine</option>
                            <option value="DO">République Dominicaine</option>
                            <option value="CZ">République Tchèque</option>
                            <option value="RE">Réunion (Île de la)</option>
                            <option value="RO">Roumanie</option>
                            <option value="GB">Royaume-Uni</option>
                            <option value="RU">Russie</option>
                            <option value="RW">Rwanda</option>
                            <option value="EH">Sahara oriental</option>
                            <option value="SH">Sainte-Hélène</option>
                            <option value="LC">Sainte-Lucie</option>
                            <option value="KN">Saint-Kitts-et-Nevis</option>
                            <option value="SM">Saint-Marin</option>
                            <option value="PM">Saint-Pierre-et-Miquelon</option>
                            <option value="VC">Saint-Vincent et les Grenadines</option>
                            <option value="SV">Salvador</option>
                            <option value="WS">Samoa</option>
                            <option value="AS">Samoa américaines</option>
                            <option value="ST">São Tomé et Príncipe</option>
                            <option value="SN">Sénégal</option>
                            <option value="RS">Serbie</option>
                            <option value="CS">Serbie et Monténégro</option>
                            <option value="SC">Seychelles</option>
                            <option value="SL">Sierra Leone</option>
                            <option value="SG">Singapour</option>
                            <option value="SK">Slovaquie</option>
                            <option value="SI">Slovénie</option>
                            <option value="SB">Solomon</option>
                            <option value="SO">Somalie</option>
                            <option value="SD">Soudan</option>
                            <option value="LK">Sri Lanka</option>
                            <option value="SE">Suède</option>
                            <option value="CH">Suisse</option>
                            <option value="SR">Surinam</option>
                            <option value="SJ">Svalbard et Jan Mayen</option>
                            <option value="SZ">Swaziland</option>
                            <option value="SY">Syrie</option>
                            <option value="TJ">Tadjikistan</option>
                            <option value="TW">Taïwan</option>
                            <option value="TZ">Tanzanie</option>
                            <option value="TD">Tchad</option>
                            <option value="IO">Territoires britanniques de l'Océan Indien</option>
                            <option value="TF">Territoires d'Outre-mer (France)</option>
                            <option value="PS">Territoires palestiniens</option>
                            <option value="TH">Thaïlande</option>
                            <option value="THB">Thaïlande - Ayutthaya</option>
                            <option value="THC">Thaïlande Chiang Mai/Nong Khai</option>
                            <option value="TL">Timor oriental</option>
                            <option value="TG">Togo</option>
                            <option value="TK">Tokelau</option>
                            <option value="TO">Tonga</option>
                            <option value="TT">Trinidad et Tobago (Trinidad and Tobago)</option>
                            <option value="TN">Tunisie</option>
                            <option value="TM">Turkménistan</option>
                            <option value="TC">Turks et Caïcos (Îles)</option>
                            <option value="TR">Turquie</option>
                            <option value="TV">Tuvalu</option>
                            <option value="UA">Ukraine</option>
                            <option value="UY">Uruguay</option>
                            <option value="VU">Vanuatu</option>
                            <option value="VA">Vatican</option>
                            <option value="VE">Venezuela</option>
                            <option value="VI">Vierges américaines (Îles)</option>
                            <option value="VN">Vietnam</option>
                            <option value="VNH">Vietnam Hanoi</option>
                            <option value="VNC">Vietnam Ho Chi Minh</option>
                            <option value="WF">Wallis et Futuna</option>
                            <option value="YE">Yémen</option>
                            <option value="ZM">Zambie</option>
                            <option value="ZW">Zimbabwe</option>
                            <option value="BO1">Bolivie - humanitaire</option>
                            <option value="BO2">Bolivie - éco-volontariat</option>
                            <option value="EC1">Equateur - humanitaire</option>
                            <option value="EC2">Equateur - éco-volontariat</option>
                            <option value="IG1">Iles Galapagos</option>
                            <option value="LKEL">Sri Lanka – Eléphants</option>
                            <option value="AEVO">Australie – écovolontariat</option>
                            <option value="ZA1">Afrique du Sud - Soins animaliers</option>
                            <option value="ZA2">Afrique du Sud - Faune sauvage</option>
                            <option value="IB">Majorque</option>
                            <option value="LKETO">Sri Lanka - Tortues</option>
                            <option value="EUCHV">Etats-Unis - chevaux</option>
                            <option value="IHMAYA">Inde - Himalaya</option>
                            <option value="TZANZI">Tanzanie - Zanzibar</option>
                            <option value="THD">Thaïlande - animaux</option>
                            <option value="THE">Thaïlande - Udon Thani</option>
                            <option value="THBCM">Thaïlande - Bangkok/Chiang Mai</option>
                        </Select>

                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="member_email"
                        rules={[{type: 'email', required: true, message: 'Merci de renseigner un email valide'}]}
                        initialValue={this.state.dataMembre.member_email}

                    >
                        <Input/>
                    </Form.Item>


                    <Form.Item
                        name="member_mdp"
                        label="Mot de passe"

                        rules={[
                            {
                                required: this.state.passeInsert,
                                message: 'Merci de renseigner votre mot de passe.',
                            },
                            (this.state.passeInsert ? ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (value.length > 7) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('Votre mot de passe doit contenir au minimum 8 caractères.'));
                                },
                            }) : false)
                        ]}
                        hasFeedback
                    >
                        <Input.Password onChange={(e) => {
                            this.testPassword(e)
                        }}/>
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label="Confirmer le mot de passe"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: this.state.passeInsert,
                                message: 'Merci de confirmer votre mot de passe.',
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('member_mdp') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('Merci de renseigner le même mot de passe en confirmation.'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>


                    <Divider dashed/>
                    <Title level={4}>Adresse permanente</Title>
                    <Form.Item
                        label="Numéro et nom de rue"
                        name="member_adresse"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_adresse}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Ville"
                        name="member_ville"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_ville}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="CP"
                        name="member_cp"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_cp}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Pays"
                        name="member_pays_permanent"
                        rules={[{required: true, message: 'Please input your username!'}]}
                        initialValue={this.state.dataMembre.member_pays_permanent}
                    >
                        <Select style={{width: "100%"}}>
                            <option value="AF">Afghanistan</option>
                            <option value="ZA">Afrique du Sud</option>
                            <option value="AX">Aland (Îles)</option>
                            <option value="AL">Albanie</option>
                            <option value="DZ">Algérie</option>
                            <option value="DE">Allemagne</option>
                            <option value="AD">Andorre</option>
                            <option value="AO">Angola</option>
                            <option value="AI">Anguilla</option>
                            <option value="AQ">Antarctique</option>
                            <option value="AG">Antigua et Barbuda</option>
                            <option value="AN">Antilles néerlandaises</option>
                            <option value="SA">Arabie Saoudite</option>
                            <option value="AR">Argentine</option>
                            <option value="AM">Arménie</option>
                            <option value="AW">Aruba</option>
                            <option value="AU">Australie</option>
                            <option value="AT">Autriche</option>
                            <option value="AZ">Azerbaïdjan</option>
                            <option value="BS">Bahamas</option>
                            <option value="BH">Bahreïn</option>
                            <option value="BD">Bangladesh</option>
                            <option value="BB">Barbade</option>
                            <option value="BE">Belgique</option>
                            <option value="BZ">Belize</option>
                            <option value="BJ">Bénin</option>
                            <option value="BM">Bermudes</option>
                            <option value="BT">Bhutan</option>
                            <option value="BY">Biélorussie</option>
                            <option value="BO">Bolivie</option>
                            <option value="BA">Bosnie-Herzégovine</option>
                            <option value="BW">Botswana</option>
                            <option value="BV">Bouvet (Île)</option>
                            <option value="BR">Brésil</option>
                            <option value="BN">Brunei</option>
                            <option value="BG">Bulgarie</option>
                            <option value="BF">Burkina Faso</option>
                            <option value="BI">Burundi</option>
                            <option value="KY">Caïmans (Îles)</option>
                            <option value="KH">Cambodge</option>
                            <option value="CM">Cameroun</option>
                            <option value="CA">Canada</option>
                            <option value="CV">Cap Vert</option>
                            <option value="CL">Chili</option>
                            <option value="CN">Chine</option>
                            <option value="CX">Christmas (Îles)</option>
                            <option value="CY">Chypre</option>
                            <option value="CC">Cocos</option>
                            <option value="CO">Colombie</option>
                            <option value="KM">Comores</option>
                            <option value="CG">Congo</option>
                            <option value="CD">Congo, République démocratique du (Congo)</option>
                            <option value="CK">Cook (Îles)</option>
                            <option value="KP">Corée du Nord</option>
                            <option value="KR">Corée du Sud</option>
                            <option value="CR">Costa Rica</option>
                            <option value="CI">Côte d'Ivoire</option>
                            <option value="HR">Croatie</option>
                            <option value="CU">Cuba</option>
                            <option value="DK">Danemark</option>
                            <option value="UM">Dépendances américaines du Pacifique</option>
                            <option value="DJ">Djibouti</option>
                            <option value="DM">Dominique</option>
                            <option value="EG">Égypte</option>
                            <option value="AE">Émirats Arabes Unis</option>
                            <option value="EC">Équateur</option>
                            <option value="ER">Érythrée</option>
                            <option value="ES">Espagne</option>
                            <option value="EE">Estonie</option>
                            <option value="US">États-Unis</option>
                            <option value="ET">Éthiopie</option>
                            <option value="FO">Faroe (Îles)</option>
                            <option value="FJ">Fidji</option>
                            <option value="FI">Finlande</option>
                            <option value="FR">France</option>
                            <option value="GA">Gabon</option>
                            <option value="GM">Gambie</option>
                            <option value="GE">Géorgie</option>
                            <option value="GS">Géorgie du Sud et îles Sandwich du Sud</option>
                            <option value="GH">Ghana</option>
                            <option value="GI">Gibraltar</option>
                            <option value="GR">Grèce</option>
                            <option value="GD">Grenade</option>
                            <option value="GL">Groenland</option>
                            <option value="GP">Guadeloupe</option>
                            <option value="GU">Guam</option>
                            <option value="GT">Guatemala</option>
                            <option value="GG">Guernesey</option>
                            <option value="GN">Guinée</option>
                            <option value="GW">Guinée-Bissau</option>
                            <option value="GQ">Guinée équatoriale</option>
                            <option value="GY">Guyane</option>
                            <option value="GF">Guyane française</option>
                            <option value="HT">Haïti</option>
                            <option value="HM">Heard et McDonald (Îles)</option>
                            <option value="HN">Honduras</option>
                            <option value="HK">Hong-Kong</option>
                            <option value="HU">Hongrie</option>
                            <option value="VG">Îles vierges britanniques</option>
                            <option value="IN">Inde</option>
                            <option value="INN">Inde du Nord Delhi</option>
                            <option value="INS">Inde du Sud</option>
                            <option value="ID">Indonésie</option>
                            <option value="IQ">Irak</option>
                            <option value="IR">Iran</option>
                            <option value="IE">Irlande</option>
                            <option value="IS">Islande</option>
                            <option value="IL">Israël</option>
                            <option value="IT">Italie</option>
                            <option value="JM">Jamaïque</option>
                            <option value="JP">Japon</option>
                            <option value="JE">Jersey</option>
                            <option value="JO">Jordanie</option>
                            <option value="KZ">Kazakhstan</option>
                            <option value="KE">Kenya</option>
                            <option value="KG">Kirghizistan</option>
                            <option value="KI">Kiribati</option>
                            <option value="KW">Koweït</option>
                            <option value="LA">Laos</option>
                            <option value="LS">Lesotho</option>
                            <option value="LV">Lettonie</option>
                            <option value="LB">Liban</option>
                            <option value="LR">Liberia</option>
                            <option value="LY">Libye</option>
                            <option value="LI">Liechtenstein</option>
                            <option value="LT">Lituanie</option>
                            <option value="LU">Luxembourg</option>
                            <option value="MO">Macao</option>
                            <option value="MK">Macédoine</option>
                            <option value="MG">Madagascar</option>
                            <option value="MY">Malaisie</option>
                            <option value="MW">Malawi</option>
                            <option value="MV">Maldives</option>
                            <option value="ML">Mali</option>
                            <option value="FK">Malouines (Îles)</option>
                            <option value="MT">Malte</option>
                            <option value="IM">Man (Île)</option>
                            <option value="MP">Mariannes du Nord (Commonwealth des îles)</option>
                            <option value="MA">Maroc</option>
                            <option value="MH">Marshall (Îles)</option>
                            <option value="MQ">Martinique</option>
                            <option value="MU">Maurice (Île)</option>
                            <option value="MR">Mauritanie</option>
                            <option value="YT">Mayotte</option>
                            <option value="MX">Mexique</option>
                            <option value="FM">Micronésie</option>
                            <option value="MD">Moldavie</option>
                            <option value="MC">Monaco</option>
                            <option value="MN">Mongolie</option>
                            <option value="ME">Monténégro</option>
                            <option value="MS">Montserrat</option>
                            <option value="MZ">Mozambique</option>
                            <option value="MM">Myanmar</option>
                            <option value="NA">Namibie</option>
                            <option value="NR">Nauru</option>
                            <option value="NP">Népal</option>
                            <option value="NI">Nicaragua</option>
                            <option value="NE">Niger</option>
                            <option value="NG">Nigéria</option>
                            <option value="NU">Niue</option>
                            <option value="NF">Norfolk (Île de)</option>
                            <option value="NO">Norvège</option>
                            <option value="NC">Nouvelle-Calédonie</option>
                            <option value="NZ">Nouvelle-Zélande</option>
                            <option value="OM">Oman</option>
                            <option value="UG">Ouganda</option>
                            <option value="UZ">Ouzbékistan</option>
                            <option value="PK">Pakistan</option>
                            <option value="PW">Palau</option>
                            <option value="PA">Panama</option>
                            <option value="PG">Papouasie-Nouvelle Guinée</option>
                            <option value="PY">Paraguay</option>
                            <option value="NL">Pays-Bas</option>
                            <option value="PE">Pérou</option>
                            <option value="PH">Philippines</option>
                            <option value="PN">Pitcairn (Île)</option>
                            <option value="PL">Pologne</option>
                            <option value="PF">Polynésie française</option>
                            <option value="PR">Porto Rico</option>
                            <option value="PT">Portugal</option>
                            <option value="QA">Qatar</option>
                            <option value="CF">République Centrafricaine</option>
                            <option value="DO">République Dominicaine</option>
                            <option value="CZ">République Tchèque</option>
                            <option value="RE">Réunion (Île de la)</option>
                            <option value="RO">Roumanie</option>
                            <option value="GB">Royaume-Uni</option>
                            <option value="RU">Russie</option>
                            <option value="RW">Rwanda</option>
                            <option value="EH">Sahara oriental</option>
                            <option value="SH">Sainte-Hélène</option>
                            <option value="LC">Sainte-Lucie</option>
                            <option value="KN">Saint-Kitts-et-Nevis</option>
                            <option value="SM">Saint-Marin</option>
                            <option value="PM">Saint-Pierre-et-Miquelon</option>
                            <option value="VC">Saint-Vincent et les Grenadines</option>
                            <option value="SV">Salvador</option>
                            <option value="WS">Samoa</option>
                            <option value="AS">Samoa américaines</option>
                            <option value="ST">São Tomé et Príncipe</option>
                            <option value="SN">Sénégal</option>
                            <option value="RS">Serbie</option>
                            <option value="CS">Serbie et Monténégro</option>
                            <option value="SC">Seychelles</option>
                            <option value="SL">Sierra Leone</option>
                            <option value="SG">Singapour</option>
                            <option value="SK">Slovaquie</option>
                            <option value="SI">Slovénie</option>
                            <option value="SB">Solomon</option>
                            <option value="SO">Somalie</option>
                            <option value="SD">Soudan</option>
                            <option value="LK">Sri Lanka</option>
                            <option value="SE">Suède</option>
                            <option value="CH">Suisse</option>
                            <option value="SR">Surinam</option>
                            <option value="SJ">Svalbard et Jan Mayen</option>
                            <option value="SZ">Swaziland</option>
                            <option value="SY">Syrie</option>
                            <option value="TJ">Tadjikistan</option>
                            <option value="TW">Taïwan</option>
                            <option value="TZ">Tanzanie</option>
                            <option value="TD">Tchad</option>
                            <option value="IO">Territoires britanniques de l'Océan Indien</option>
                            <option value="TF">Territoires d'Outre-mer (France)</option>
                            <option value="PS">Territoires palestiniens</option>
                            <option value="TH">Thaïlande</option>
                            <option value="THB">Thaïlande - Ayutthaya</option>
                            <option value="THC">Thaïlande Chiang Mai/Nong Khai</option>
                            <option value="TL">Timor oriental</option>
                            <option value="TG">Togo</option>
                            <option value="TK">Tokelau</option>
                            <option value="TO">Tonga</option>
                            <option value="TT">Trinidad et Tobago (Trinidad and Tobago)</option>
                            <option value="TN">Tunisie</option>
                            <option value="TM">Turkménistan</option>
                            <option value="TC">Turks et Caïcos (Îles)</option>
                            <option value="TR">Turquie</option>
                            <option value="TV">Tuvalu</option>
                            <option value="UA">Ukraine</option>
                            <option value="UY">Uruguay</option>
                            <option value="VU">Vanuatu</option>
                            <option value="VA">Vatican</option>
                            <option value="VE">Venezuela</option>
                            <option value="VI">Vierges américaines (Îles)</option>
                            <option value="VN">Vietnam</option>
                            <option value="VNH">Vietnam Hanoi</option>
                            <option value="VNC">Vietnam Ho Chi Minh</option>
                            <option value="WF">Wallis et Futuna</option>
                            <option value="YE">Yémen</option>
                            <option value="ZM">Zambie</option>
                            <option value="ZW">Zimbabwe</option>
                            <option value="BO1">Bolivie - humanitaire</option>
                            <option value="BO2">Bolivie - éco-volontariat</option>
                            <option value="EC1">Equateur - humanitaire</option>
                            <option value="EC2">Equateur - éco-volontariat</option>
                            <option value="IG1">Iles Galapagos</option>
                            <option value="LKEL">Sri Lanka – Eléphants</option>
                            <option value="AEVO">Australie – écovolontariat</option>
                            <option value="ZA1">Afrique du Sud - Soins animaliers</option>
                            <option value="ZA2">Afrique du Sud - Faune sauvage</option>
                            <option value="IB">Majorque</option>
                            <option value="LKETO">Sri Lanka - Tortues</option>
                            <option value="EUCHV">Etats-Unis - chevaux</option>
                            <option value="IHMAYA">Inde - Himalaya</option>
                            <option value="TZANZI">Tanzanie - Zanzibar</option>
                            <option value="THD">Thaïlande - animaux</option>
                            <option value="THE">Thaïlande - Udon Thani</option>
                            <option value="THBCM">Thaïlande - Bangkok/Chiang Mai</option>
                        </Select>

                    </Form.Item>

                    <Form.Item label="Téléphone portable">
                        <Input.Group compact={true}>


                            <Form.Item

                                name="member_tel1_prefix"
                                rules={[{required: true, message: 'Indicatif du téléphone obligatoire'}]}
                            >
                                <Select style={{minWidth: "250px"}}>
                                    <option data-countrycode="FR" value="+33">France (+33)</option>
                                    <option data-countrycode="CA" value="+1">Canada (+1)</option>
                                    <option data-countrycode="DE" value="+49">Germany (+49)</option>
                                    <option data-countrycode="CH" value="+41">Switzerland (+41)</option>
                                    <option data-countrycode="ES" value="+34">Spain (+34)</option>
                                    <option data-countrycode="GB" value="+44">UK (+44)</option>


                                    <option data-countrycode="DZ" value="+213">Algeria (+213)</option>
                                    <option data-countrycode="AD" value="+376">Andorra (+376)</option>
                                    <option data-countrycode="AO" value="+244">Angola (+244)</option>
                                    <option data-countrycode="AI" value="+1264">Anguilla (+1264)</option>
                                    <option data-countrycode="AG" value="+1268">Antigua &amp; Barbuda (+1268)</option>
                                    <option data-countrycode="AR" value="+54">Argentina (+54)</option>
                                    <option data-countrycode="AM" value="+374">Armenia (+374)</option>
                                    <option data-countrycode="AW" value="+297">Aruba (+297)</option>
                                    <option data-countrycode="AU" value="+61">Australia (+61)</option>
                                    <option data-countrycode="AT" value="+43">Austria (+43)</option>
                                    <option data-countrycode="AZ" value="+994">Azerbaijan (+994)</option>
                                    <option data-countrycode="BS" value="+1242">Bahamas (+1242)</option>
                                    <option data-countrycode="BH" value="+973">Bahrain (+973)</option>
                                    <option data-countrycode="BD" value="+880">Bangladesh (+880)</option>
                                    <option data-countrycode="BB" value="+1246">Barbados (+1246)</option>
                                    <option data-countrycode="BY" value="+375">Belarus (+375)</option>
                                    <option data-countrycode="BE" value="+32">Belgium (+32)</option>
                                    <option data-countrycode="BZ" value="+501">Belize (+501)</option>
                                    <option data-countrycode="BJ" value="+229">Benin (+229)</option>
                                    <option data-countrycode="BM" value="+1441">Bermuda (+1441)</option>
                                    <option data-countrycode="BT" value="+975">Bhutan (+975)</option>
                                    <option data-countrycode="BO" value="+591">Bolivia (+591)</option>
                                    <option data-countrycode="BA" value="+387">Bosnia Herzegovina (+387)</option>
                                    <option data-countrycode="BW" value="+267">Botswana (+267)</option>
                                    <option data-countrycode="BR" value="+55">Brazil (+55)</option>
                                    <option data-countrycode="BN" value="+673">Brunei (+673)</option>
                                    <option data-countrycode="BG" value="+359">Bulgaria (+359)</option>
                                    <option data-countrycode="BF" value="+226">Burkina Faso (+226)</option>
                                    <option data-countrycode="BI" value="+257">Burundi (+257)</option>
                                    <option data-countrycode="KH" value="+855">Cambodia (+855)</option>
                                    <option data-countrycode="CM" value="+237">Cameroon (+237)</option>
                                    <option data-countrycode="CV" value="+238">Cape Verde Islands (+238)</option>
                                    <option data-countrycode="KY" value="+1345">Cayman Islands (+1345)</option>
                                    <option data-countrycode="CF" value="+236">Central African Republic (+236)</option>
                                    <option data-countrycode="CL" value="+56">Chile (+56)</option>
                                    <option data-countrycode="CN" value="+86">China (+86)</option>
                                    <option data-countrycode="CO" value="+57">Colombia (+57)</option>
                                    <option data-countrycode="KM" value="+269">Comoros (+269)</option>
                                    <option data-countrycode="CG" value="+242">Congo (+242)</option>
                                    <option data-countrycode="CK" value="+682">Cook Islands (+682)</option>
                                    <option data-countrycode="CR" value="+506">Costa Rica (+506)</option>
                                    <option data-countrycode="HR" value="+385">Croatia (+385)</option>
                                    <option data-countrycode="CU" value="+53">Cuba (+53)</option>
                                    <option data-countrycode="CY" value="+90392">Cyprus North (+90392)</option>
                                    <option data-countrycode="CY" value="+357">Cyprus South (+357)</option>
                                    <option data-countrycode="CZ" value="+42">Czech Republic (+42)</option>
                                    <option data-countrycode="DK" value="+45">Denmark (+45)</option>
                                    <option data-countrycode="DJ" value="+253">Djibouti (+253)</option>
                                    <option data-countrycode="DM" value="+1809">Dominica (+1809)</option>
                                    <option data-countrycode="EC" value="+593">Ecuador (+593)</option>
                                    <option data-countrycode="EG" value="+20">Egypt (+20)</option>
                                    <option data-countrycode="SV" value="+503">El Salvador (+503)</option>
                                    <option data-countrycode="GQ" value="+240">Equatorial Guinea (+240)</option>
                                    <option data-countrycode="ER" value="+291">Eritrea (+291)</option>
                                    <option data-countrycode="EE" value="+372">Estonia (+372)</option>
                                    <option data-countrycode="ET" value="+251">Ethiopia (+251)</option>
                                    <option data-countrycode="FK" value="+500">Falkland Islands (+500)</option>
                                    <option data-countrycode="FO" value="+298">Faroe Islands (+298)</option>
                                    <option data-countrycode="FJ" value="+679">Fiji (+679)</option>
                                    <option data-countrycode="FI" value="+358">Finland (+358)</option>
                                    <option data-countrycode="FR" value="+33">France (+33)</option>
                                    <option data-countrycode="GF" value="+594">French Guiana (+594)</option>
                                    <option data-countrycode="PF" value="+689">French Polynesia (+689)</option>
                                    <option data-countrycode="GA" value="+241">Gabon (+241)</option>
                                    <option data-countrycode="GM" value="+220">Gambia (+220)</option>
                                    <option data-countrycode="GE" value="+7880">Georgia (+7880)</option>
                                    <option data-countrycode="GH" value="+233">Ghana (+233)</option>
                                    <option data-countrycode="GI" value="+350">Gibraltar (+350)</option>
                                    <option data-countrycode="GR" value="+30">Greece (+30)</option>
                                    <option data-countrycode="GL" value="+299">Greenland (+299)</option>
                                    <option data-countrycode="GD" value="+1473">Grenada (+1473)</option>
                                    <option data-countrycode="GP" value="+590">Guadeloupe (+590)</option>
                                    <option data-countrycode="GU" value="+671">Guam (+671)</option>
                                    <option data-countrycode="GT" value="+502">Guatemala (+502)</option>
                                    <option data-countrycode="GN" value="+224">Guinea (+224)</option>
                                    <option data-countrycode="GW" value="+245">Guinea - Bissau (+245)</option>
                                    <option data-countrycode="GY" value="+592">Guyana (+592)</option>
                                    <option data-countrycode="HT" value="+509">Haiti (+509)</option>
                                    <option data-countrycode="HN" value="+504">Honduras (+504)</option>
                                    <option data-countrycode="HK" value="+852">Hong Kong (+852)</option>
                                    <option data-countrycode="HU" value="+36">Hungary (+36)</option>
                                    <option data-countrycode="IS" value="+354">Iceland (+354)</option>
                                    <option data-countrycode="IN" value="+91">India (+91)</option>
                                    <option data-countrycode="ID" value="+62">Indonesia (+62)</option>
                                    <option data-countrycode="IR" value="+98">Iran (+98)</option>
                                    <option data-countrycode="IQ" value="+964">Iraq (+964)</option>
                                    <option data-countrycode="IE" value="+353">Ireland (+353)</option>
                                    <option data-countrycode="IL" value="+972">Israel (+972)</option>
                                    <option data-countrycode="IT" value="+39">Italy (+39)</option>
                                    <option data-countrycode="JM" value="+1876">Jamaica (+1876)</option>
                                    <option data-countrycode="JP" value="+81">Japan (+81)</option>
                                    <option data-countrycode="JO" value="+962">Jordan (+962)</option>
                                    <option data-countrycode="KZ" value="+77">Kazakhstan (+7)</option>
                                    <option data-countrycode="KE" value="+254">Kenya (+254)</option>
                                    <option data-countrycode="KI" value="+686">Kiribati (+686)</option>
                                    <option data-countrycode="KP" value="+850">Korea North (+850)</option>
                                    <option data-countrycode="KR" value="+82">Korea South (+82)</option>
                                    <option data-countrycode="KW" value="+965">Kuwait (+965)</option>
                                    <option data-countrycode="KG" value="+996">Kyrgyzstan (+996)</option>
                                    <option data-countrycode="LA" value="+856">Laos (+856)</option>
                                    <option data-countrycode="LV" value="+371">Latvia (+371)</option>
                                    <option data-countrycode="LB" value="+961">Lebanon (+961)</option>
                                    <option data-countrycode="LS" value="+266">Lesotho (+266)</option>
                                    <option data-countrycode="LR" value="+231">Liberia (+231)</option>
                                    <option data-countrycode="LY" value="+218">Libya (+218)</option>
                                    <option data-countrycode="LI" value="+417">Liechtenstein (+417)</option>
                                    <option data-countrycode="LT" value="+370">Lithuania (+370)</option>
                                    <option data-countrycode="LU" value="+352">Luxembourg (+352)</option>
                                    <option data-countrycode="MO" value="+853">Macao (+853)</option>
                                    <option data-countrycode="MK" value="+389">Macedonia (+389)</option>
                                    <option data-countrycode="MG" value="+261">Madagascar (+261)</option>
                                    <option data-countrycode="MW" value="+265">Malawi (+265)</option>
                                    <option data-countrycode="MY" value="+60">Malaysia (+60)</option>
                                    <option data-countrycode="MV" value="+960">Maldives (+960)</option>
                                    <option data-countrycode="ML" value="+223">Mali (+223)</option>
                                    <option data-countrycode="MT" value="+356">Malta (+356)</option>
                                    <option data-countrycode="MH" value="+692">Marshall Islands (+692)</option>
                                    <option data-countrycode="MQ" value="+596">Martinique (+596)</option>
                                    <option data-countrycode="MR" value="+222">Mauritania (+222)</option>
                                    <option data-countrycode="YT" value="+269">Mayotte (+269)</option>
                                    <option data-countrycode="MX" value="+52">Mexico (+52)</option>
                                    <option data-countrycode="FM" value="+691">Micronesia (+691)</option>
                                    <option data-countrycode="MD" value="+373">Moldova (+373)</option>
                                    <option data-countrycode="MC" value="+377">Monaco (+377)</option>
                                    <option data-countrycode="MN" value="+976">Mongolia (+976)</option>
                                    <option data-countrycode="MS" value="+1664">Montserrat (+1664)</option>
                                    <option data-countrycode="MA" value="+212">Morocco (+212)</option>
                                    <option data-countrycode="MZ" value="+258">Mozambique (+258)</option>
                                    <option data-countrycode="MN" value="+95">Myanmar (+95)</option>
                                    <option data-countrycode="NA" value="+264">Namibia (+264)</option>
                                    <option data-countrycode="NR" value="+674">Nauru (+674)</option>
                                    <option data-countrycode="NP" value="+977">Nepal (+977)</option>
                                    <option data-countrycode="NL" value="+31">Netherlands (+31)</option>
                                    <option data-countrycode="NC" value="+687">New Caledonia (+687)</option>
                                    <option data-countrycode="NZ" value="+64">New Zealand (+64)</option>
                                    <option data-countrycode="NI" value="+505">Nicaragua (+505)</option>
                                    <option data-countrycode="NE" value="+227">Niger (+227)</option>
                                    <option data-countrycode="NG" value="+234">Nigeria (+234)</option>
                                    <option data-countrycode="NU" value="+683">Niue (+683)</option>
                                    <option data-countrycode="NF" value="+672">Norfolk Islands (+672)</option>
                                    <option data-countrycode="NP" value="+670">Northern Marianas (+670)</option>
                                    <option data-countrycode="NO" value="+47">Norway (+47)</option>
                                    <option data-countrycode="OM" value="+968">Oman (+968)</option>
                                    <option data-countrycode="PW" value="+680">Palau (+680)</option>
                                    <option data-countrycode="PA" value="+507">Panama (+507)</option>
                                    <option data-countrycode="PG" value="+675">Papua New Guinea (+675)</option>
                                    <option data-countrycode="PY" value="+595">Paraguay (+595)</option>
                                    <option data-countrycode="PE" value="+51">Peru (+51)</option>
                                    <option data-countrycode="PH" value="+63">Philippines (+63)</option>
                                    <option data-countrycode="PL" value="+48">Poland (+48)</option>
                                    <option data-countrycode="PT" value="+351">Portugal (+351)</option>
                                    <option data-countrycode="PR" value="+1787">Puerto Rico (+1787)</option>
                                    <option data-countrycode="QA" value="+974">Qatar (+974)</option>
                                    <option data-countrycode="RE" value="+262">Reunion (+262)</option>
                                    <option data-countrycode="RO" value="+40">Romania (+40)</option>
                                    <option data-countrycode="RU" value="+7">Russia (+7)</option>
                                    <option data-countrycode="RW" value="+250">Rwanda (+250)</option>
                                    <option data-countrycode="SM" value="+378">San Marino (+378)</option>
                                    <option data-countrycode="ST" value="+239">Sao Tome &amp; Principe (+239)</option>
                                    <option data-countrycode="SA" value="+966">Saudi Arabia (+966)</option>
                                    <option data-countrycode="SN" value="+221">Senegal (+221)</option>
                                    <option data-countrycode="CS" value="+381">Serbia (+381)</option>
                                    <option data-countrycode="SC" value="+248">Seychelles (+248)</option>
                                    <option data-countrycode="SL" value="+232">Sierra Leone (+232)</option>
                                    <option data-countrycode="SG" value="+65">Singapore (+65)</option>
                                    <option data-countrycode="SK" value="+421">Slovak Republic (+421)</option>
                                    <option data-countrycode="SI" value="+386">Slovenia (+386)</option>
                                    <option data-countrycode="SB" value="+677">Solomon Islands (+677)</option>
                                    <option data-countrycode="SO" value="+252">Somalia (+252)</option>
                                    <option data-countrycode="ZA" value="+27">South Africa (+27)</option>
                                    <option data-countrycode="LK" value="+94">Sri Lanka (+94)</option>
                                    <option data-countrycode="SH" value="+290">St. Helena (+290)</option>
                                    <option data-countrycode="KN" value="+1869">St. Kitts (+1869)</option>
                                    <option data-countrycode="SC" value="+1758">St. Lucia (+1758)</option>
                                    <option data-countrycode="SD" value="+249">Sudan (+249)</option>
                                    <option data-countrycode="SR" value="+597">Suriname (+597)</option>
                                    <option data-countrycode="SZ" value="+268">Swaziland (+268)</option>
                                    <option data-countrycode="SE" value="+46">Sweden (+46)</option>
                                    <option data-countrycode="SI" value="+963">Syria (+963)</option>
                                    <option data-countrycode="TW" value="+886">Taiwan (+886)</option>
                                    <option data-countrycode="TJ" value="+992">Tajikstan (+992)</option>
                                    <option data-countrycode="TH" value="+66">Thailand (+66)</option>
                                    <option data-countrycode="TG" value="+228">Togo (+228)</option>
                                    <option data-countrycode="TO" value="+676">Tonga (+676)</option>
                                    <option data-countrycode="TT" value="+1868">Trinidad &amp; Tobago (+1868)</option>
                                    <option data-countrycode="TN" value="+216">Tunisia (+216)</option>
                                    <option data-countrycode="TR" value="+90">Turkey (+90)</option>
                                    <option data-countrycode="TM" value="+993">Turkmenistan (+993)</option>
                                    <option data-countrycode="TC" value="+1649">Turks &amp; Caicos Islands (+1649)
                                    </option>
                                    <option data-countrycode="TV" value="+688">Tuvalu (+688)</option>
                                    <option data-countrycode="UG" value="+256">Uganda (+256)</option>
                                    <option data-countrycode="GB" value="+44">UK (+44)</option>
                                    <option data-countrycode="UA" value="+380">Ukraine (+380)</option>
                                    <option data-countrycode="AE" value="+971">United Arab Emirates (+971)</option>
                                    <option data-countrycode="UY" value="+598">Uruguay (+598)</option>
                                    <option data-countrycode="US" value="+1">USA (+1)</option>
                                    <option data-countrycode="UZ" value="+998">Uzbekistan (+998)</option>
                                    <option data-countrycode="VU" value="+678">Vanuatu (+678)</option>
                                    <option data-countrycode="VA" value="+379">Vatican City (+379)</option>
                                    <option data-countrycode="VE" value="+58">Venezuela (+58)</option>
                                    <option data-countrycode="VN" value="+84">Vietnam (+84)</option>
                                    <option data-countrycode="VG" value="+1284">Virgin Islands - British (+1284)
                                    </option>
                                    <option data-countrycode="VI" value="+1340">Virgin Islands - US (+1340)</option>
                                    <option data-countrycode="WF" value="+681">Wallis &amp; Futuna (+681)</option>
                                    <option data-countrycode="YE" value="+969">Yemen (North)(+969)</option>
                                    <option data-countrycode="YE" value="+967">Yemen (South)(+967)</option>
                                    <option data-countrycode="ZM" value="+260">Zambia (+260)</option>
                                    <option data-countrycode="ZW" value="+263">Zimbabwe (+263)</option>

                                </Select>

                            </Form.Item>

                            <Form.Item
                                name="member_tel1"
                                rules={[{required: true, message: 'Merci de compléter cette information'}]}
                                initialValue={this.state.dataMembre.member_tel1}

                            >
                                <Input placeholder="Exemple : 06XXXXXXXX" style={{minWidth: "350px"}}/>
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>

                    <Form.Item label="Téléphone fixe">
                        <Input.Group compact={true}>

                            <Form.Item
                                name="member_tel2_prefix"
                                rules={[{required: true, message: 'Indicatif du téléphone obligatoire'}]}
                            >
                                <Select style={{minWidth: "250px"}}>
                                    <option data-countrycode="FR" value="+33">France (+33)</option>
                                    <option data-countrycode="CA" value="+1">Canada (+1)</option>
                                    <option data-countrycode="DE" value="+49">Germany (+49)</option>
                                    <option data-countrycode="CH" value="+41">Switzerland (+41)</option>
                                    <option data-countrycode="ES" value="+34">Spain (+34)</option>
                                    <option data-countrycode="GB" value="+44">UK (+44)</option>


                                    <option data-countrycode="DZ" value="+213">Algeria (+213)</option>
                                    <option data-countrycode="AD" value="+376">Andorra (+376)</option>
                                    <option data-countrycode="AO" value="+244">Angola (+244)</option>
                                    <option data-countrycode="AI" value="+1264">Anguilla (+1264)</option>
                                    <option data-countrycode="AG" value="+1268">Antigua &amp; Barbuda (+1268)</option>
                                    <option data-countrycode="AR" value="+54">Argentina (+54)</option>
                                    <option data-countrycode="AM" value="+374">Armenia (+374)</option>
                                    <option data-countrycode="AW" value="+297">Aruba (+297)</option>
                                    <option data-countrycode="AU" value="+61">Australia (+61)</option>
                                    <option data-countrycode="AT" value="+43">Austria (+43)</option>
                                    <option data-countrycode="AZ" value="+994">Azerbaijan (+994)</option>
                                    <option data-countrycode="BS" value="+1242">Bahamas (+1242)</option>
                                    <option data-countrycode="BH" value="+973">Bahrain (+973)</option>
                                    <option data-countrycode="BD" value="+880">Bangladesh (+880)</option>
                                    <option data-countrycode="BB" value="+1246">Barbados (+1246)</option>
                                    <option data-countrycode="BY" value="+375">Belarus (+375)</option>
                                    <option data-countrycode="BE" value="+32">Belgium (+32)</option>
                                    <option data-countrycode="BZ" value="+501">Belize (+501)</option>
                                    <option data-countrycode="BJ" value="+229">Benin (+229)</option>
                                    <option data-countrycode="BM" value="+1441">Bermuda (+1441)</option>
                                    <option data-countrycode="BT" value="+975">Bhutan (+975)</option>
                                    <option data-countrycode="BO" value="+591">Bolivia (+591)</option>
                                    <option data-countrycode="BA" value="+387">Bosnia Herzegovina (+387)</option>
                                    <option data-countrycode="BW" value="+267">Botswana (+267)</option>
                                    <option data-countrycode="BR" value="+55">Brazil (+55)</option>
                                    <option data-countrycode="BN" value="+673">Brunei (+673)</option>
                                    <option data-countrycode="BG" value="+359">Bulgaria (+359)</option>
                                    <option data-countrycode="BF" value="+226">Burkina Faso (+226)</option>
                                    <option data-countrycode="BI" value="+257">Burundi (+257)</option>
                                    <option data-countrycode="KH" value="+855">Cambodia (+855)</option>
                                    <option data-countrycode="CM" value="+237">Cameroon (+237)</option>
                                    <option data-countrycode="CV" value="+238">Cape Verde Islands (+238)</option>
                                    <option data-countrycode="KY" value="+1345">Cayman Islands (+1345)</option>
                                    <option data-countrycode="CF" value="+236">Central African Republic (+236)</option>
                                    <option data-countrycode="CL" value="+56">Chile (+56)</option>
                                    <option data-countrycode="CN" value="+86">China (+86)</option>
                                    <option data-countrycode="CO" value="+57">Colombia (+57)</option>
                                    <option data-countrycode="KM" value="+269">Comoros (+269)</option>
                                    <option data-countrycode="CG" value="+242">Congo (+242)</option>
                                    <option data-countrycode="CK" value="+682">Cook Islands (+682)</option>
                                    <option data-countrycode="CR" value="+506">Costa Rica (+506)</option>
                                    <option data-countrycode="HR" value="+385">Croatia (+385)</option>
                                    <option data-countrycode="CU" value="+53">Cuba (+53)</option>
                                    <option data-countrycode="CY" value="+90392">Cyprus North (+90392)</option>
                                    <option data-countrycode="CY" value="+357">Cyprus South (+357)</option>
                                    <option data-countrycode="CZ" value="+42">Czech Republic (+42)</option>
                                    <option data-countrycode="DK" value="+45">Denmark (+45)</option>
                                    <option data-countrycode="DJ" value="+253">Djibouti (+253)</option>
                                    <option data-countrycode="DM" value="+1809">Dominica (+1809)</option>
                                    <option data-countrycode="EC" value="+593">Ecuador (+593)</option>
                                    <option data-countrycode="EG" value="+20">Egypt (+20)</option>
                                    <option data-countrycode="SV" value="+503">El Salvador (+503)</option>
                                    <option data-countrycode="GQ" value="+240">Equatorial Guinea (+240)</option>
                                    <option data-countrycode="ER" value="+291">Eritrea (+291)</option>
                                    <option data-countrycode="EE" value="+372">Estonia (+372)</option>
                                    <option data-countrycode="ET" value="+251">Ethiopia (+251)</option>
                                    <option data-countrycode="FK" value="+500">Falkland Islands (+500)</option>
                                    <option data-countrycode="FO" value="+298">Faroe Islands (+298)</option>
                                    <option data-countrycode="FJ" value="+679">Fiji (+679)</option>
                                    <option data-countrycode="FI" value="+358">Finland (+358)</option>
                                    <option data-countrycode="FR" value="+33">France (+33)</option>
                                    <option data-countrycode="GF" value="+594">French Guiana (+594)</option>
                                    <option data-countrycode="PF" value="+689">French Polynesia (+689)</option>
                                    <option data-countrycode="GA" value="+241">Gabon (+241)</option>
                                    <option data-countrycode="GM" value="+220">Gambia (+220)</option>
                                    <option data-countrycode="GE" value="+7880">Georgia (+7880)</option>
                                    <option data-countrycode="GH" value="+233">Ghana (+233)</option>
                                    <option data-countrycode="GI" value="+350">Gibraltar (+350)</option>
                                    <option data-countrycode="GR" value="+30">Greece (+30)</option>
                                    <option data-countrycode="GL" value="+299">Greenland (+299)</option>
                                    <option data-countrycode="GD" value="+1473">Grenada (+1473)</option>
                                    <option data-countrycode="GP" value="+590">Guadeloupe (+590)</option>
                                    <option data-countrycode="GU" value="+671">Guam (+671)</option>
                                    <option data-countrycode="GT" value="+502">Guatemala (+502)</option>
                                    <option data-countrycode="GN" value="+224">Guinea (+224)</option>
                                    <option data-countrycode="GW" value="+245">Guinea - Bissau (+245)</option>
                                    <option data-countrycode="GY" value="+592">Guyana (+592)</option>
                                    <option data-countrycode="HT" value="+509">Haiti (+509)</option>
                                    <option data-countrycode="HN" value="+504">Honduras (+504)</option>
                                    <option data-countrycode="HK" value="+852">Hong Kong (+852)</option>
                                    <option data-countrycode="HU" value="+36">Hungary (+36)</option>
                                    <option data-countrycode="IS" value="+354">Iceland (+354)</option>
                                    <option data-countrycode="IN" value="+91">India (+91)</option>
                                    <option data-countrycode="ID" value="+62">Indonesia (+62)</option>
                                    <option data-countrycode="IR" value="+98">Iran (+98)</option>
                                    <option data-countrycode="IQ" value="+964">Iraq (+964)</option>
                                    <option data-countrycode="IE" value="+353">Ireland (+353)</option>
                                    <option data-countrycode="IL" value="+972">Israel (+972)</option>
                                    <option data-countrycode="IT" value="+39">Italy (+39)</option>
                                    <option data-countrycode="JM" value="+1876">Jamaica (+1876)</option>
                                    <option data-countrycode="JP" value="+81">Japan (+81)</option>
                                    <option data-countrycode="JO" value="+962">Jordan (+962)</option>
                                    <option data-countrycode="KZ" value="+77">Kazakhstan (+7)</option>
                                    <option data-countrycode="KE" value="+254">Kenya (+254)</option>
                                    <option data-countrycode="KI" value="+686">Kiribati (+686)</option>
                                    <option data-countrycode="KP" value="+850">Korea North (+850)</option>
                                    <option data-countrycode="KR" value="+82">Korea South (+82)</option>
                                    <option data-countrycode="KW" value="+965">Kuwait (+965)</option>
                                    <option data-countrycode="KG" value="+996">Kyrgyzstan (+996)</option>
                                    <option data-countrycode="LA" value="+856">Laos (+856)</option>
                                    <option data-countrycode="LV" value="+371">Latvia (+371)</option>
                                    <option data-countrycode="LB" value="+961">Lebanon (+961)</option>
                                    <option data-countrycode="LS" value="+266">Lesotho (+266)</option>
                                    <option data-countrycode="LR" value="+231">Liberia (+231)</option>
                                    <option data-countrycode="LY" value="+218">Libya (+218)</option>
                                    <option data-countrycode="LI" value="+417">Liechtenstein (+417)</option>
                                    <option data-countrycode="LT" value="+370">Lithuania (+370)</option>
                                    <option data-countrycode="LU" value="+352">Luxembourg (+352)</option>
                                    <option data-countrycode="MO" value="+853">Macao (+853)</option>
                                    <option data-countrycode="MK" value="+389">Macedonia (+389)</option>
                                    <option data-countrycode="MG" value="+261">Madagascar (+261)</option>
                                    <option data-countrycode="MW" value="+265">Malawi (+265)</option>
                                    <option data-countrycode="MY" value="+60">Malaysia (+60)</option>
                                    <option data-countrycode="MV" value="+960">Maldives (+960)</option>
                                    <option data-countrycode="ML" value="+223">Mali (+223)</option>
                                    <option data-countrycode="MT" value="+356">Malta (+356)</option>
                                    <option data-countrycode="MH" value="+692">Marshall Islands (+692)</option>
                                    <option data-countrycode="MQ" value="+596">Martinique (+596)</option>
                                    <option data-countrycode="MR" value="+222">Mauritania (+222)</option>
                                    <option data-countrycode="YT" value="+269">Mayotte (+269)</option>
                                    <option data-countrycode="MX" value="+52">Mexico (+52)</option>
                                    <option data-countrycode="FM" value="+691">Micronesia (+691)</option>
                                    <option data-countrycode="MD" value="+373">Moldova (+373)</option>
                                    <option data-countrycode="MC" value="+377">Monaco (+377)</option>
                                    <option data-countrycode="MN" value="+976">Mongolia (+976)</option>
                                    <option data-countrycode="MS" value="+1664">Montserrat (+1664)</option>
                                    <option data-countrycode="MA" value="+212">Morocco (+212)</option>
                                    <option data-countrycode="MZ" value="+258">Mozambique (+258)</option>
                                    <option data-countrycode="MN" value="+95">Myanmar (+95)</option>
                                    <option data-countrycode="NA" value="+264">Namibia (+264)</option>
                                    <option data-countrycode="NR" value="+674">Nauru (+674)</option>
                                    <option data-countrycode="NP" value="+977">Nepal (+977)</option>
                                    <option data-countrycode="NL" value="+31">Netherlands (+31)</option>
                                    <option data-countrycode="NC" value="+687">New Caledonia (+687)</option>
                                    <option data-countrycode="NZ" value="+64">New Zealand (+64)</option>
                                    <option data-countrycode="NI" value="+505">Nicaragua (+505)</option>
                                    <option data-countrycode="NE" value="+227">Niger (+227)</option>
                                    <option data-countrycode="NG" value="+234">Nigeria (+234)</option>
                                    <option data-countrycode="NU" value="+683">Niue (+683)</option>
                                    <option data-countrycode="NF" value="+672">Norfolk Islands (+672)</option>
                                    <option data-countrycode="NP" value="+670">Northern Marianas (+670)</option>
                                    <option data-countrycode="NO" value="+47">Norway (+47)</option>
                                    <option data-countrycode="OM" value="+968">Oman (+968)</option>
                                    <option data-countrycode="PW" value="+680">Palau (+680)</option>
                                    <option data-countrycode="PA" value="+507">Panama (+507)</option>
                                    <option data-countrycode="PG" value="+675">Papua New Guinea (+675)</option>
                                    <option data-countrycode="PY" value="+595">Paraguay (+595)</option>
                                    <option data-countrycode="PE" value="+51">Peru (+51)</option>
                                    <option data-countrycode="PH" value="+63">Philippines (+63)</option>
                                    <option data-countrycode="PL" value="+48">Poland (+48)</option>
                                    <option data-countrycode="PT" value="+351">Portugal (+351)</option>
                                    <option data-countrycode="PR" value="+1787">Puerto Rico (+1787)</option>
                                    <option data-countrycode="QA" value="+974">Qatar (+974)</option>
                                    <option data-countrycode="RE" value="+262">Reunion (+262)</option>
                                    <option data-countrycode="RO" value="+40">Romania (+40)</option>
                                    <option data-countrycode="RU" value="+7">Russia (+7)</option>
                                    <option data-countrycode="RW" value="+250">Rwanda (+250)</option>
                                    <option data-countrycode="SM" value="+378">San Marino (+378)</option>
                                    <option data-countrycode="ST" value="+239">Sao Tome &amp; Principe (+239)</option>
                                    <option data-countrycode="SA" value="+966">Saudi Arabia (+966)</option>
                                    <option data-countrycode="SN" value="+221">Senegal (+221)</option>
                                    <option data-countrycode="CS" value="+381">Serbia (+381)</option>
                                    <option data-countrycode="SC" value="+248">Seychelles (+248)</option>
                                    <option data-countrycode="SL" value="+232">Sierra Leone (+232)</option>
                                    <option data-countrycode="SG" value="+65">Singapore (+65)</option>
                                    <option data-countrycode="SK" value="+421">Slovak Republic (+421)</option>
                                    <option data-countrycode="SI" value="+386">Slovenia (+386)</option>
                                    <option data-countrycode="SB" value="+677">Solomon Islands (+677)</option>
                                    <option data-countrycode="SO" value="+252">Somalia (+252)</option>
                                    <option data-countrycode="ZA" value="+27">South Africa (+27)</option>
                                    <option data-countrycode="LK" value="+94">Sri Lanka (+94)</option>
                                    <option data-countrycode="SH" value="+290">St. Helena (+290)</option>
                                    <option data-countrycode="KN" value="+1869">St. Kitts (+1869)</option>
                                    <option data-countrycode="SC" value="+1758">St. Lucia (+1758)</option>
                                    <option data-countrycode="SD" value="+249">Sudan (+249)</option>
                                    <option data-countrycode="SR" value="+597">Suriname (+597)</option>
                                    <option data-countrycode="SZ" value="+268">Swaziland (+268)</option>
                                    <option data-countrycode="SE" value="+46">Sweden (+46)</option>
                                    <option data-countrycode="SI" value="+963">Syria (+963)</option>
                                    <option data-countrycode="TW" value="+886">Taiwan (+886)</option>
                                    <option data-countrycode="TJ" value="+992">Tajikstan (+992)</option>
                                    <option data-countrycode="TH" value="+66">Thailand (+66)</option>
                                    <option data-countrycode="TG" value="+228">Togo (+228)</option>
                                    <option data-countrycode="TO" value="+676">Tonga (+676)</option>
                                    <option data-countrycode="TT" value="+1868">Trinidad &amp; Tobago (+1868)</option>
                                    <option data-countrycode="TN" value="+216">Tunisia (+216)</option>
                                    <option data-countrycode="TR" value="+90">Turkey (+90)</option>
                                    <option data-countrycode="TM" value="+993">Turkmenistan (+993)</option>
                                    <option data-countrycode="TC" value="+1649">Turks &amp; Caicos Islands (+1649)
                                    </option>
                                    <option data-countrycode="TV" value="+688">Tuvalu (+688)</option>
                                    <option data-countrycode="UG" value="+256">Uganda (+256)</option>
                                    <option data-countrycode="GB" value="+44">UK (+44)</option>
                                    <option data-countrycode="UA" value="+380">Ukraine (+380)</option>
                                    <option data-countrycode="AE" value="+971">United Arab Emirates (+971)</option>
                                    <option data-countrycode="UY" value="+598">Uruguay (+598)</option>
                                    <option data-countrycode="US" value="+1">USA (+1)</option>
                                    <option data-countrycode="UZ" value="+998">Uzbekistan (+998)</option>
                                    <option data-countrycode="VU" value="+678">Vanuatu (+678)</option>
                                    <option data-countrycode="VA" value="+379">Vatican City (+379)</option>
                                    <option data-countrycode="VE" value="+58">Venezuela (+58)</option>
                                    <option data-countrycode="VN" value="+84">Vietnam (+84)</option>
                                    <option data-countrycode="VG" value="+1284">Virgin Islands - British (+1284)
                                    </option>
                                    <option data-countrycode="VI" value="+1340">Virgin Islands - US (+1340)</option>
                                    <option data-countrycode="WF" value="+681">Wallis &amp; Futuna (+681)</option>
                                    <option data-countrycode="YE" value="+969">Yemen (North)(+969)</option>
                                    <option data-countrycode="YE" value="+967">Yemen (South)(+967)</option>
                                    <option data-countrycode="ZM" value="+260">Zambia (+260)</option>
                                    <option data-countrycode="ZW" value="+263">Zimbabwe (+263)</option>

                                </Select>

                            </Form.Item>

                            <Form.Item
                                rules={[{required: true, message: 'Merci de compléter cette information'}]}
                                name="member_tel2"
                                initialValue={this.state.dataMembre.member_tel2}
                            >
                                <Input placeholder="Exemple : 06XXXXXXXX" style={{minWidth: "350px"}}/>
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>


                    <Divider dashed/>
                    <Title level={4}>Personne à contacter en cas d’urgence</Title>

                    <Form.Item
                        label="Civilité"
                        name="member_conturg_civ"
                        rules={[{required: true, message: 'Please input your username!'}]}
                        initialValue={this.state.dataMembre.member_conturg_civ}
                    >
                        <Select style={{width: 120}}>
                            <option value="mr" selected="selected">Monsieur</option>
                            <option value="mme">Mme.</option>
                            <option value="mr">M.</option>
                        </Select>

                    </Form.Item>

                    <Form.Item
                        label="Prénom"
                        name="member_conturg_prenom"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_conturg_prenom}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Nom"
                        name="member_conturg_nom"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_conturg_nom}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Lien avec le volontaire"
                        name="member_conturg_lienvolontaire"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_conturg_lienvolontaire}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Téléphone en cas d’urgence"
                        name="member_conturg_tel1"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_conturg_tel1}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Email en cas d’urgence"
                        name="member_conturg_email1"
                        rules={[{type: 'email', required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_conturg_email1}
                    >
                        <Input/>
                    </Form.Item>


                    <Divider dashed/>
                    <Title level={4}>Votre situation professionnelle</Title>

                    <Form.Item
                        label="Votre situation professionnelle actuelle"
                        name="member_situation"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_situation}
                    >
                        <Select>
                            <option value="Autre (à préciser)">Autre (à préciser ci-dessous)</option>
                            <option value="Agriculteur / Agricultrice">Agriculteur / Agricultrice</option>
                            <option value="Artisan(e)">Artisan(e)</option>
                            <option value="Artiste / Professionnel(le) de la culture">Artiste / Professionnel(le) de la culture</option>
                            <option value="Cadre / Dirigeant(e)">Cadre / Dirigeant(e)</option>
                            <option value="Chercheur / Scientifique">Chercheur / Scientifique</option>
                            <option value="Commerçant(e)">Commerçant(e)</option>
                            <option value="Employé(e) de bureau / Administratif">Employé(e) de bureau / Administratif</option>
                            <option value="Enseignant(e) / Formateur(trice)">Enseignant(e) / Formateur(trice)</option>
                            <option value="Étudiant(e)">Étudiant(e)</option>
                            <option value="Fonctionnaire / Salarié(e) du secteur public">Fonctionnaire / Salarié(e) du secteur public</option>
                            <option value="Journaliste / Reporter">Journaliste / Reporter</option>
                            <option value="Personnel de la restauration rapide / Restauration collective">Personnel de la restauration rapide / Restauration collective</option>
                            <option value="Personnel de la sécurité (police, gendarmerie, sécurité privée)">Personnel de la sécurité (police, gendarmerie, sécurité privée)</option>
                            <option value="Personnel des forces armées">Personnel des forces armées</option>
                            <option value="Profession libérale">Profession libérale</option>
                            <option value="Professionnel(le) de la santé">Professionnel(le) de la santé</option>
                            <option value="Professionnel(le) du secteur de l’environnement (écologiste, ingénieur en environnement)">Professionnel(le) du secteur de l’environnement (écologiste, ingénieur en environnement)</option>
                            <option value="Professionnel(le) du secteur énergétique (ingénieur, technicien)">Professionnel(le) du secteur énergétique (ingénieur, technicien)</option>
                            <option value="Professionnel(le) du secteur immobilier (agent immobilier, gestionnaire de biens)">Professionnel(le) du secteur immobilier (agent immobilier, gestionnaire de biens)</option>
                            <option value="Professionnel(le) du secteur informatique (développeur, administrateur systèmes)">Professionnel(le) du secteur informatique (développeur, administrateur systèmes)</option>
                            <option value="Professionnel(le) du secteur juridique (juge, procureur, avocat)">Professionnel(le) du secteur juridique (juge, procureur, avocat)</option>
                            <option value="Professionnel(le) du secteur social (assistante sociale, éducateur spécialisé)">Professionnel(le) du secteur social (assistante sociale, éducateur spécialisé)</option>
                            <option value="Professionnel(le) du secteur sportif (entraîneur, athlète, préparateur physique)">Professionnel(le) du secteur sportif (entraîneur, athlète, préparateur physique)</option>
                            <option value="Professionnel(le) du secteur des transports (chauffeur, pilote, conducteur de train)">Professionnel(le) du secteur des transports (chauffeur, pilote, conducteur de train)</option>
                            <option value="Professionnel(le) du secteur du tourisme (guide touristique, agent de voyage)">Professionnel(le) du secteur du tourisme (guide touristique, agent de voyage)</option>
                            <option value="Retraité(e)">Retraité(e)</option>
                            <option value="Salarié(e) du secteur privé">Salarié(e) du secteur privé</option>
                            <option value="Sans emploi / En recherche d'emploi">Sans emploi / En recherche d'emploi</option>
                            <option value="Technicien(ne) / Ouvrier(e) qualifié(e)">Technicien(ne) / Ouvrier(e) qualifié(e)</option>
                            <option value="Travailleur indépendant / Auto-entrepreneur">Travailleur indépendant / Auto-entrepreneur</option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Si vous ne trouvez pas votre situation professionnelle actuelle sur la liste ci-dessus, merci de nous la préciser"
                        name="member_situation_autre"
                        initialValue={this.state.dataMembre.member_situation_autre}
                    >
                        <Input/>
                    </Form.Item>


                    <Form.Item
                        label="Votre situation au moment du départ (si différente)"
                        name="member_situationdepart"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_situationdepart}
                    >
                        <Select>
                            <option value="Autre (à préciser)">Autre (à préciser ci-dessous)</option>
                            <option value="Agriculteur / Agricultrice">Agriculteur / Agricultrice</option>
                            <option value="Artisan(e)">Artisan(e)</option>
                            <option value="Artiste / Professionnel(le) de la culture">Artiste / Professionnel(le) de la culture</option>
                            <option value="Cadre / Dirigeant(e)">Cadre / Dirigeant(e)</option>
                            <option value="Chercheur / Scientifique">Chercheur / Scientifique</option>
                            <option value="Commerçant(e)">Commerçant(e)</option>
                            <option value="Employé(e) de bureau / Administratif">Employé(e) de bureau / Administratif</option>
                            <option value="Enseignant(e) / Formateur(trice)">Enseignant(e) / Formateur(trice)</option>
                            <option value="Étudiant(e)">Étudiant(e)</option>
                            <option value="Fonctionnaire / Salarié(e) du secteur public">Fonctionnaire / Salarié(e) du secteur public</option>
                            <option value="Journaliste / Reporter">Journaliste / Reporter</option>
                            <option value="Personnel de la restauration rapide / Restauration collective">Personnel de la restauration rapide / Restauration collective</option>
                            <option value="Personnel de la sécurité (police, gendarmerie, sécurité privée)">Personnel de la sécurité (police, gendarmerie, sécurité privée)</option>
                            <option value="Personnel des forces armées">Personnel des forces armées</option>
                            <option value="Profession libérale">Profession libérale</option>
                            <option value="Professionnel(le) de la santé">Professionnel(le) de la santé</option>
                            <option value="Professionnel(le) du secteur de l’environnement (écologiste, ingénieur en environnement)">Professionnel(le) du secteur de l’environnement (écologiste, ingénieur en environnement)</option>
                            <option value="Professionnel(le) du secteur énergétique (ingénieur, technicien)">Professionnel(le) du secteur énergétique (ingénieur, technicien)</option>
                            <option value="Professionnel(le) du secteur immobilier (agent immobilier, gestionnaire de biens)">Professionnel(le) du secteur immobilier (agent immobilier, gestionnaire de biens)</option>
                            <option value="Professionnel(le) du secteur informatique (développeur, administrateur systèmes)">Professionnel(le) du secteur informatique (développeur, administrateur systèmes)</option>
                            <option value="Professionnel(le) du secteur juridique (juge, procureur, avocat)">Professionnel(le) du secteur juridique (juge, procureur, avocat)</option>
                            <option value="Professionnel(le) du secteur social (assistante sociale, éducateur spécialisé)">Professionnel(le) du secteur social (assistante sociale, éducateur spécialisé)</option>
                            <option value="Professionnel(le) du secteur sportif (entraîneur, athlète, préparateur physique)">Professionnel(le) du secteur sportif (entraîneur, athlète, préparateur physique)</option>
                            <option value="Professionnel(le) du secteur des transports (chauffeur, pilote, conducteur de train)">Professionnel(le) du secteur des transports (chauffeur, pilote, conducteur de train)</option>
                            <option value="Professionnel(le) du secteur du tourisme (guide touristique, agent de voyage)">Professionnel(le) du secteur du tourisme (guide touristique, agent de voyage)</option>
                            <option value="Retraité(e)">Retraité(e)</option>
                            <option value="Salarié(e) du secteur privé">Salarié(e) du secteur privé</option>
                            <option value="Sans emploi / En recherche d'emploi">Sans emploi / En recherche d'emploi</option>
                            <option value="Technicien(ne) / Ouvrier(e) qualifié(e)">Technicien(ne) / Ouvrier(e) qualifié(e)</option>
                            <option value="Travailleur indépendant / Auto-entrepreneur">Travailleur indépendant / Auto-entrepreneur</option>
                        </Select>

                    </Form.Item>

                    <Form.Item
                        label="Si vous ne trouvez pas votre situation professionnelle au moment du départ sur la liste ci-dessus, merci de nous la préciser"
                        name="member_situationdepart_autre"
                        initialValue={this.state.dataMembre.member_situation_autre}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={<div>J'ai lu et j'accepte les <a target="_blank"
                                                                href="https://www.globalong.com/conditions-de-vente.html">conditions
                            générales</a> sans réserve, j'ai pris connaissance des <a target="_blank"
                                                                                      href="https://www.globalong.com/mentions-legales.html">mentions
                            légales</a> et je m'engage à respecter le <a target="_blank"
                                                                         href="https://www.globalong.com/blog/wp-content/uploads/2016/08/Re%CC%80glement-inte%CC%81rieur-des-volontaires-Globalong.pdf"> règlement
                            intérieur</a></div>}
                        name="conditionsdv"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                    >
                        <Select style={{width: 120}}>
                            <option value="" selected="selected"></option>
                            <option value="oui">Oui, j'accepte</option>
                        </Select>

                    </Form.Item>

                    {/*
                    <Title level={4}>Dernière formation suivie</Title>

                    <Form.Item
                        label="Nom de l’établissement"
                        name="member_form_nom_etab"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_form_nom_etab}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Niveau d'étude"
                        name="member_niv_etude"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_niv_etude}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Spécialité"
                        name="member_specialite"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_specialite}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Nom de la formation"
                        name="member_nom_formation"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_nom_formation}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Autres formations/compétences"
                        name="member_autre_form"
                        rules={[{required: true, message: 'Merci de compléter cette information'}]}
                        initialValue={this.state.dataMembre.member_autre_form}
                    >
                        <Input/>
                    </Form.Item>
                    */}

                    {this.state.alerte}

                    <Form.Item {...tailLayout}>
                        <Button loading={this.state.loading} type="primary" htmlType="submit">
                            Valider mes informations
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default FormRegister;