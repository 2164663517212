import React from 'react';
import MenuDashboard from "./Menu";
import "./css/dashboard.css";
import HeaderMember from "./HeaderMember";
import logoGLob from "./../../img/logoglob.png"
import ListeCmd from "./ListeCmd";
import {
    UserOutlined,
    SettingFilled,
    SmileOutlined,
    SyncOutlined,
    LoadingOutlined,
    SolutionOutlined,
} from '@ant-design/icons';

import {Form, Input, Button, Checkbox, Row, Col, Typography, Divider, Steps, Layout, Tabs} from 'antd';
import {FooterApps} from "../order/inc/FooterApps";

const {Title, Text, Link} = Typography;
const {Step} = Steps;
const {TabPane} = Tabs;

export default function Order() {
    return (
        <Layout>
            <HeaderMember/>
            <Layout>
                <MenuDashboard/>
                <div className="contentBox">
                    <Title>Liste de mes projets</Title>
                    <Text>Vous trouverez ci-dessous vos candidatures, futurs projets, projets en cours, anciens projets et projets annulés. </Text>
                    <Divider/>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Candidatures" key="1">
                            <ListeCmd filter="encours" title="Mes candidatures"/>
                        </TabPane>
                        <TabPane tab="Mes projets en cours" key="2">
                            <ListeCmd filter="valider" title="Mes projets validés"/>
                        </TabPane>
                        <TabPane tab="Projets terminés" key="3">
                            <ListeCmd filter="projettermine" title="Projets terminés"/>
                        </TabPane>
                        <TabPane tab="Candidatures/projets annulées" key="4">
                            <ListeCmd filter="annulee" title="Candidatures/projets annulées"/>
                        </TabPane>

                    </Tabs>


                </div>
            </Layout>
            <Divider dashed/>
            <FooterApps/>
        </Layout>
    );
}



