import React, {Component} from 'react';
import {Space, Typography} from 'antd';

const {Text} = Typography;

export class FooterApps extends Component {

    render() {
        return (
            <div style={{textAlign: 'center'}}>
                <Text type="secondary" >
                    Copyright 2022 - Globalong -
                    <a target="_blank" href="https://www.globalong.com/vie-privee.html">Vie privée</a> | <a target="_blank" href="https://www.globalong.com/conditions-de-vente.html">Conditions générales</a> | <a target="_blank" href="https://www.globalong.com/mentions-legales.html">Mentions légales</a> | <a target="_blank" href="https://www.globalong.com/securite-des-transactions.html">Sécurité des transactions</a>
                </Text>
                <br />
                <br />
            </div>
        )
    }

}
