import React from "react";
import {List, Typography, Divider, Button, Alert} from 'antd';
import {Redirect, Link} from "react-router-dom";
import axios from "axios";
import {API_URI} from "../../../constant/Config";
import {
    InfoCircleOutlined
} from '@ant-design/icons';

const {Title} = Typography;


class Recap extends React.Component {
    constructor(props) {
        super(props);

        this.handleStep2 = this.handleStep2.bind(this);

        this.state = {
            nextStep: false,
            prevStep: false,
            dataCart: this.props.dataCart,
            valStep: this.props.valStep,
            idPack: false,
            dateDebut: false,
            supplements: false,
            dataListe: ['Merci de faire votre choix'],
            total: 0,
            authNextStep: this.props.authNextStep,
            alertMsg: '',
            urlNext: (this.props.next) ? this.props.next : '',
            urlPrev: (this.props.prev) ? this.props.prev : '',
            cat: localStorage.getItem('order_idcat'),
            libTotal: '',
            dataResume: false,
        }

    }

    componentDidMount() {

        if (this.props.construct == false) {
            this.loadData();
        }


        // si le membre n'est pas authentifié
        if (this.state.valStep == 1 && !localStorage.getItem('membre_id')) {
            this.setState({urlNext: '/order/step3/'})
        }

    }

    async componentDidUpdate(prevProps, prevState) {

        if (this.props.construct) {
            if (prevProps.idPack !== this.state.idPack) {
                this.setState({idPack: prevProps.idPack})
                this.loadData();
                console.log('pack')
            }

            if (prevProps.dateDebut !== this.state.dateDebut) {
                this.setState({dateDebut: prevProps.dateDebut})
                this.loadData();
                console.log('date')
            }

            if (prevProps.supplements !== this.state.supplements) {
                this.setState({supplements: prevProps.supplements})
                this.loadData();
                console.log('sup')
            }
        }

    }

    loadData = async () => {


        let idPack = localStorage.getItem('order_id_pack');
        let dateDebut = localStorage.getItem('order_date_debut');
        let supp = localStorage.getItem('order_supp');
        let typeDate = localStorage.getItem('order_date_type');
        let dateId = localStorage.getItem('order_date_id');
        let idSejour = localStorage.getItem('order_id_sejour')
        let membre_id = localStorage.getItem('membre_id')

        let data = await axios.post(API_URI + '/api_member/order-resume', {
            idpack: idPack,
            dateDebut: dateDebut,
            typeDate: typeDate,
            supp: supp,
            dateId: dateId,
            idSejour: idSejour,
            membre_id: membre_id
        });

        var listeSupp = [];

        if (data.data[0].libPack) {
            listeSupp.push(data.data[0].libPack)
        }

        if (data.data[0].suppListe) {
            var suppListe = data.data[0].suppListe;
            // listeSupp.push('suup')
            if (suppListe.length == 0) {
                //console.log(' vide')
            } else {
                var listeDates = Object.keys(suppListe).map(function (key) {
                    //return <Option value={suppListe[key]['dateStart']}>{suppListe[key]['libFull']}</Option>
                    listeSupp.push(suppListe[key]['lib'])
                });
            }


        }

        if (localStorage.getItem('order_idcat') == 3) {
            this.setState({libTotal: 'Montant à verser :'});
        } else {
            this.setState({libTotal: 'Montant du don à verser pour ce projet :'});
        }

        this.setState({
            total: data.data[0].totalSejour,
            dataResume: data.data[0],
            loading: false,
        })


        listeSupp.push(this.state.libTotal + data.data[0].totalSejour + ' €')
        listeSupp.push(data.data[0].messageMembreHonoraire)
        listeSupp.push(<div>Soit après déduction d'impôt : {this.state.dataResume.reductionLib} € <a target="_blank"
                                                                                                     href={data.data[0].linkInfoReduction}><InfoCircleOutlined/></a>
        </div>)

        this.setState({
            dataListe: listeSupp,
        })

    }


    clicNext = (event) => {


        if (this.state.valStep == 2) {
            const valide = this.step2Validate(event);
            if (valide) {
                localStorage.setItem('order_valStep_' + this.state.valStep, this.state.valStep)
                this.setState({'nextStep': true})
            }
        } else if (this.state.valStep == 1) {
            const valide = this.step1Validate(event);
            if (valide) {
                localStorage.setItem('order_valStep_' + this.state.valStep, this.state.valStep)
                this.setState({'nextStep': true})
            }
        } else {
            if (this.props.authNextStep) {
                localStorage.setItem('order_valStep_' + this.state.valStep, this.state.valStep)
                this.setState({'nextStep': true})
            } else {
                // tester le form
                this.stepValidateDefault(event);
            }
        }


    }

    clicPrev = () => {
        this.setState({'prevStep': true})
    }

    stepValidateDefault(event) {
        if (this.props.authNextStep) {
            this.setState({alertMsg: ''})
        } else {
            this.setState({
                alertMsg: <div style={{marginTop: 10}}><Alert
                    message="Incomplet"
                    description="Merci de bien compléter votre formulaire."
                    type="warning"
                    showIcon
                    closable
                /></div>
            })

            event.preventDefault();

        }
    }

    handleStep2(values) {
        this.props.stepChange(values);
    }

    step1Validate(event) {
        var valide = true;

        if (!localStorage.getItem('order_date_debut')) {
            valide = false;
        }

        // le paramètre excludeDateRules permet de ne pas tenir compte du pack notament pour l'Islande
        if (!localStorage.getItem('order_id_pack') && this.state.dataResume.excludePackCheck === false) {
            valide = false;
        }

        if (!localStorage.getItem('order_deja_contact')) {
            valide = false;
        }

        if (valide) {
            this.setState({alertMsg: ''})
        } else {
            this.setState({
                alertMsg: <div style={{marginTop: 10}}><Alert
                    message="Incomplet"
                    description="Merci de bien compléter votre formulaire."
                    type="warning"
                    showIcon
                    closable
                /></div>
            })
            event.preventDefault();
        }
        return valide;
    }

    step2Validate(event) {

        // verification des questions
        var questData = JSON.parse(localStorage.getItem('order_question'));
        var valide = true;
        Object.keys(questData).map((key) => {
                if (questData[key] == false) {
                    valide = false;
                    //console.log(questData[key])
                }
            }
        );
        //console.log(questData)

        var docValidation = {}
        // verification des deux doc obligatoire
        if (!localStorage.getItem('order_doc_CV')) {
            valide = false;
            docValidation = {
                ...docValidation,
                order_doc_CV: false
            }
        } else {
            docValidation = {
                ...docValidation,
                order_doc_CV: true
            }
        }

        if (!localStorage.getItem('order_doc_LETTRE_PRESENTATION')) {
            valide = false;
            docValidation = {
                ...docValidation,
                order_doc_LETTRE_PRESENTATION: false
            }
        } else {
            docValidation = {
                ...docValidation,
                order_doc_LETTRE_PRESENTATION: true
            }
        }

        if (valide) {
            this.setState({alertMsg: ''})
        } else {
            this.setState({
                alertMsg: <div style={{marginTop: 10}}><Alert
                    message="Incomplet"
                    description="Merci de bien compléter votre formulaire."
                    type="warning"
                    showIcon
                    closable
                /></div>
            })
            event.preventDefault();
        }


        // mise à jour de l'état
        this.props.stepChange({'questions': questData, 'docValidation': docValidation})
        return valide;
    }

    render() {


        return (
            <div style={{textAlign: "center"}}>


                <div>{this.state.prixPack}</div>
                <List
                    header={<div><Title level={4}>Votre récapitulatif</Title></div>}
                    footer={<div></div>}
                    bordered
                    dataSource={this.state.dataListe}
                    renderItem={item => (
                        <List.Item>
                            {item}
                        </List.Item>
                    )}
                />
                {this.state.alertMsg}


                {(this.props.prev) ?
                    <Link to={this.state.urlPrev} onClick={this.clicPrev}><Button type="primary" style={{
                        marginTop: "10px",
                        marginRight: '10px'
                    }}
                                                                                  size="large">Précédent</Button></Link> : ''}

                {(this.props.next) ?
                    <Link to={this.state.urlNext} onClick={this.clicNext}><Button type="primary"
                                                                                  style={{marginTop: "10px"}}
                                                                                  size="large">Suivant</Button></Link> : ''}


            </div>
        )
    }
}

export default Recap;