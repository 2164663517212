import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import Ident from "./components/member/Ident";
import Dashboard from "./components/member/Dashboard";
import Profil from "./components/member/Profil";
import Step1 from "./components/order/Step1";
import Step2 from "./components/order/Step2";
import Home from "./components/Home";
import Step3 from "./components/order/Step3";
import InscrTermine from "./components/order/InscrTermine";
import OrderCheck from "./components/order/orderCheck";
import OrderFromSite from "./components/order/orderFromSite";
import ListeCmd from "./components/member/ListeCmd";
import OrderMsg from "./components/member/OrderMsg";
import Order from "./components/member/Order";
import OrderDetail from "./components/member/OrderDetail";
import Contact from "./components/member/Contact";
import NewOrder from "./components/order/NewOrder";
import OrderComplete from "./components/member/OrderComplete";

export default function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Ident/>
                </Route>

                <Route exact path="/ident">
                    <Ident/>
                </Route>

                <Route exact path="/ident/destroy">
                    <Ident destroy={true}/>
                </Route>

                <Route exact path="/ident/dashboard">
                    <Dashboard/>
                </Route>
                <Route exact path="/ident/profil">
                    <Profil/>
                </Route>

                <Route exact path="/ident/order">
                    <Order/>
                </Route>

                <Route exact path="/ident/order/:id" component={OrderDetail}/>
                <Route exact path="/ident/order/msg/:id/:type" component={OrderMsg}/>

                <Route exact path="/order/ident">
                    <Ident urlRedirect='/order/check'/>
                </Route>

                <Route exact path="/order/new">
                    <NewOrder/>
                </Route>

                <Route exact path="/order/step1">
                    <Step1/>
                </Route>

                <Route exact path="/order/step2">
                    <Step2/>
                </Route>

                <Route exact path="/order/complete/:idCmd"
                       component={OrderComplete}/>

                <Route exact path="/order/site/:creatformsite/:idCat/:idPays/:codePays/:idSejour/:orderDateDesirate"
                       component={OrderFromSite}/>

                <Route exact path="/order/step1/:creatformsite" component={Step1}/>

                <Route exact path="/order/step3">
                    <Step3/>
                </Route>

                <Route exact path="/order/check">
                    <OrderCheck/>
                </Route>

                <Route exact path="/order/success">
                    <InscrTermine/>
                </Route>

                <Route exact path="/ident/contact">
                    <Contact/>
                </Route>

            </Switch>
        </Router>
    );
}
