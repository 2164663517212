import React from "react";
import {Row, Col, Typography, Divider, Switch, Select, DatePicker, Space,Layout} from 'antd';
import Header from "./inc/Header";
import FIl from "./inc/FIl"
import './css/step.css'
import Recap from "./inc/Recap";
import DateOpen from "./dates/DateOpen"
import Supp from "./supplements/Supp";
import Destination from "./sejour/Destination";
import ChoixSejour from "./sejour/ChoixSejour";
import ChoixTypeSejour from "./sejour/ChoixTypeSejour";
import {StickyContainer, Sticky} from 'react-sticky';
import axios from 'axios';
import {API_URI} from "../../constant/Config"
import {Redirect} from "react-router-dom";

const {Title, Text, Paragraph} = Typography;
const {Option} = Select;

class NewOrder extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
        }
    }

    deleteSessionOrder = () => {
        localStorage.removeItem('order_id_sejour')
        localStorage.removeItem('order_date_desirate')
        localStorage.removeItem('order_log_ref')
        localStorage.removeItem('order_doc_LETTRE_PRESENTATION')
        localStorage.removeItem('order_idcat')
        localStorage.removeItem('order_doc_CV')
        localStorage.removeItem('order_supp')
        localStorage.removeItem('order_id_pack')
        localStorage.removeItem('order_date_debut')
        localStorage.removeItem('order_doc_CASIER_JUDICIAIRE')
        localStorage.removeItem('order_pays')
        localStorage.removeItem('order_question')
        localStorage.removeItem('order_valStep_1')
        localStorage.removeItem('order_valStep_2')
        localStorage.removeItem('order_valStep_3')
        localStorage.removeItem('order_valStep_4')
        localStorage.removeItem('order_date_type')
        localStorage.removeItem('order_formSite')
        localStorage.removeItem('order_date_id')
        localStorage.removeItem('order_deja_contact');
    }

    async componentDidMount() {
       await this.deleteSessionOrder();

       await this.setState({
           redirect:true
       })

    }

    render() {
        if (this.state.redirect) {
            return <Redirect to='/order/step1'/>;
        }else{
            return <div></div>
        }
    }

}

export default NewOrder;
