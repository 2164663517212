import React, {Component} from 'react';
import {Col, Row, Typography} from "antd";
import QuestionBloc from "./QuestionBloc";
import axios from "axios";
import {API_URI} from "../../../constant/Config";
import BlockSejour from "../sejour/BlockSejour";


const {Title, Text} = Typography;

class Questions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dataFirstQuestion: false,
        }
    }

    getFirstQuestion = () => {
        let data = axios.get(API_URI + '/api_member/liste_questions_first');
        return data
    }

    async componentDidMount() {

        let firstQuestion = await this.getFirstQuestion()
        this.setState({
            dataFirstQuestion: firstQuestion.data,
        })

    }

    render() {
        if(typeof this.props.changeStepSend.questions !== 'undefined'){
            var changeStepSend = this.props.changeStepSend.questions
        }else{
            var changeStepSend = false
        }

        let firstQuestion = this.state.dataFirstQuestion

        if (firstQuestion) {

            var liste = Object.keys(firstQuestion).map(function (key) {
                return <QuestionBloc changeStepSend={changeStepSend}  dataQuestion={firstQuestion[key]}/>
            });

            return (
                <div>
                    <div className="centerTxt mb10 mt10">
                        <Title level={3}>Merci de répondre aux questions</Title>
                    </div>
                    <Row>
                        <Col span={24}>
                            {liste}
                        </Col>
                    </Row>
                </div>
            )
        } else {
            return <div>En chargement....</div>
        }
    }
}

export default Questions;