import React from 'react';
import "./css/dashboard.css";
import {API_URI} from "../../constant/Config"
import {Typography, Form, Input, Button, Checkbox, DatePicker,message} from "antd";
import axios from "axios";
import moment from 'moment';

const {Title, Text} = Typography;
const layoutForm = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
}
const tailLayout = {
    wrapperCol: {offset: 0, span: 24},
};

class OrderBlocDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            dataCmd: false,
        }
    }

    async componentDidMount() {
        const dataCmd = await this.getDetailsCmd()
        await this.setState({dataCmd: dataCmd})
    }

    getDetailsCmd = async () => {
        var token = localStorage.getItem('membre_token');
        let dataCmd = await axios.post(API_URI + '/api_member/details_order', {
            id: this.state.id,
        },{
            headers: {
                'Authorization': token
            }
        });
        return dataCmd.data;
    }

    onFinish = async values => {

        const postData  = {
            ...values,
            id: this.state.id,
        }
        var token = localStorage.getItem('membre_token');
        const res = await axios.post(API_URI + '/api_member/update_details_order', postData, {
            headers: {
                'Authorization': token
            }
        });
        message.success('Votre profil est à jour, merci.', 5);
    };

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };


    render() {
        if (this.state.dataCmd) {
            return (
                <di>
                    <Form
                        {...layoutForm}
                        name="basic"
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Form.Item
                            label="N° de passeport"
                            name="passeportnum"
                            rules={[{required: true, message: 'Merci de compléter cette information'}]}
                            initialValue={this.state.dataCmd.data.passeportnum}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Lieu de délivrance du passeport"
                            name="passeportlieu"
                            rules={[{required: true, message: 'Merci de compléter cette information'}]}
                            initialValue={this.state.dataCmd.data.passeportlieu}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Nom(s) et prénom(s) exacts sur le passeport"
                            name="nompasseport"
                            rules={[{required: true, message: 'Merci de compléter cette information'}]}
                            initialValue={this.state.dataCmd.data.nompasseport}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Date de délivrance du passeport"
                            name="passeportdatedelivr"
                            rules={[{required: true, message: 'Merci de compléter cette information'}]}
                            //initialValue={(this.state.dataCmd.passeportdatedelivr)?moment(this.state.dataCmd.passeportdatedelivr, 'YYYY-MM-DD'):null}
                            //initialValue={(this.state.dataCmd.passeportdatedelivr)?moment(this.state.dataCmd.passeportdatedelivr+'T01:00:00', 'YYYY-MM-DDTHH:mm:ss'):null}
                            //initialValue={(this.state.dataCmd.passeportdatedelivr)?moment(this.state.dataCmd.passeportdatedelivr+'T01:00:00', 'YYYY-MM-DDTHH:mm:ss'):null}

                            initialValue={(this.state.dataCmd.datesOrder.passeportdatedelivr)?moment.unix(this.state.dataCmd.datesOrder.passeportdatedelivr):null}
                        >
                            <DatePicker format={'DD/MM/YYYY'} />
                        </Form.Item>

                        <Form.Item
                            label="Date d'expiration du passeport"
                            name="passeportexp"
                            rules={[{required: true, message: 'Merci de compléter cette information'}]}
                            //initialValue={(this.state.dataCmd.data.passeportexp)?moment(this.state.dataCmd.data.passeportexp+'T01:00:00', 'YYYY-MM-DDTHH:mm:ss'):null}

                            initialValue={(this.state.dataCmd.datesOrder.passeportexp)?moment.unix(this.state.dataCmd.datesOrder.passeportexp):null}
                        >
                            <DatePicker format={'DD/MM/YYYY'} />
                        </Form.Item>

                        <Form.Item
                            label="N° de visa"
                            name="visa"
                            rules={[{required: false, message: 'Merci de compléter cette information'}]}
                            initialValue={this.state.dataCmd.data.visa}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Compagnie d'assurance voyage"
                            name="compagnieassurance"
                            rules={[{required: true, message: 'Merci de compléter cette information'}]}
                            initialValue={this.state.dataCmd.data.compagnieassurance}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="N° de contrat d'assurance voyage"
                            name="numpoliceassurance"
                            rules={[{required: true, message: 'Merci de compléter cette information'}]}
                            initialValue={this.state.dataCmd.data.numpoliceassurance}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Notes complémentaires"
                            name="gpCommandescolDatereportNote"
                            rules={[{required: false, message: 'Merci de compléter cette information'}]}
                            initialValue={this.state.dataCmd.data.gpCommandescolDatereportNote}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Valider mes informations
                            </Button>
                        </Form.Item>
                    </Form>
                </di>
            )
        } else {
            return (<div>Chargement des données...</div>)
        }
    }
}

export default OrderBlocDetails