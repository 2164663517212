import React from 'react';
import {List, Typography} from 'antd';
import {
    FileTwoTone,
} from '@ant-design/icons';
import axios from "axios";
import {API_URI} from "../../constant/Config";

const {Title, Paragraph, Text, Link} = Typography;

class OrderFilesDispMission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {dataFiles: [], idCmd: this.props.idCmd, dataCmd: this.props.dataCmd};
    }

    componentDidMount() {
        this.loadList();
    }

    loadList = async () => {
        const dataList = await axios.get(API_URI + '/api_member/order-list-files-disp?ids=' + this.state.idCmd);
        const listeArr = Object.entries(dataList.data.liste);
        var arrayListe = [];
        const listeArrReturn = listeArr.map((fileDisp) =>
            arrayListe.push(<div><FileTwoTone/> <Link href={fileDisp[1].link}
                                                      target="_blank">{fileDisp[1].lib} : {fileDisp[1].value}</Link>
            </div>)
        );
        this.setState({dataFiles: arrayListe})
    }

    render() {

        // La commande doit être => au statut 6 qui est la validation du premier paiement
        if (this.state.dataFiles.length > 0 && this.state.dataCmd.statut.lib.id > 5) {
            return <div>
                <Text strong>Documents de la mission :</Text>
                <List
                    size="small"
                    dataSource={this.state.dataFiles}
                    renderItem={item => <List.Item>{item}</List.Item>}
                />
            </div>
        } else {
            return <div></div>
        }
    }
}

export default OrderFilesDispMission;