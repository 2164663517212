import React from "react";
import { Redirect } from "react-router-dom";
import {PageHeader, Row, Col, Typography, Divider,Button,Layout} from 'antd';
import logoGLob from "../../img/logoglob.png";
import '../order/css/header.css'
const { Header, Footer, Sider, Content } = Layout;
const {Title, Paragraph, Text} = Typography;


class HeaderMember extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
        }
    }

    goToOrder = () => {
        this.setState({redirect: '/order/new'})
    }
    goToSite = () => {
        window.location.href = "https://www.globalong.com";
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }else {
            return (
                <PageHeader
                    className="site-page-header"
                    onBack={this.goToSite}
                    title="Globalong"
                    subTitle="Revenir sur le site"
                    extra={[
                        <Button type="primary" size="large" onClick={this.goToOrder}>Candidater pour un projet</Button>,
                        <img style={{width: "150px"}} src={logoGLob}/>
                    ]}
                />
            )
        }
    }
}
export default HeaderMember;
