import React, {Component} from 'react';
import {Col, Row, Typography, Alert} from "antd";
import DocBloc from "./DocBloc";
import axios from "axios";
import {API_URI} from "../../../constant/Config";

const {Title, Text} = Typography;

class DocsInscr extends Component {

    constructor(props) {
        super(props);
        this.state = {
            langueDoc: false
        }
    }

    async componentDidMount() {
        let orderPays = JSON.parse(localStorage.getItem('order_pays'))
        const paramPost = {idPaysSejour: orderPays.idPays};
        const response = await axios.post(API_URI + '/api-member/attributs-pays-langues', paramPost);
        //  this.setState({langueDoc: response.liste[0].lib});
        if (response.data.liste[0]) {
            var langue = "Vos documents doivent être rédigés en : " + response.data.liste[0].lib;
            this.setState({
                langueDoc: <Alert
                    message="Langue des documents"
                    description={langue}
                    type="warning"
                    showIcon
                />
            });
        }
    }


    render() {
        var messageErreur = ''
        if (typeof this.props.changeStepSend.docValidation !== 'undefined') {
            var messageErreur = <Alert
                message="Documents manquants"
                description="Le CV et la lettre de présentation sont obligatoires"
                type="error"
                showIcon
            />
        }

        return (
            <div>
                <div className="centerTxt mb10 mt10">
                    <Title level={3}>Documents nécessaires</Title>
                    {messageErreur}
                </div>
                <Row>
                    <Col span={24}>
                        Pour participer à un programme de bénévolat, de volontariat international où à un stage à
                        l'étranger, vous devez constituer un dossier de candidature en déposant les documents ci-dessus.
                        <br/><br/>
                        Votre dossier doit être constitué de :
                        <br/><br/>
                        1. Votre CV
                        <br/><br/>
                        2. Une lettre de présentation dans laquelle vous répondrez aux questions suivantes :
                        <ul>
                            <li>* Pourquoi souhaitez-vous faire du bénévolat ?</li>
                            <li>* Pourquoi avez-vous choisi ce pays et cette mission ?</li>
                            <li>* Quelles qualifications ou expériences en rapport avec la mission avez-vous ?</li>
                            <li>* Qu'attendez-vous de la mission ?</li>
                            <li>* Quand souhaitez-vous commencer ?</li>
                            <li>* Combien de semaines souhaitez-vous partir ?</li>
                        </ul>

                        3. Un extrait de casier judiciaire. Pour les ressortissants français, il est possible de le
                        demander en ligne ici. (<a target="_blank" href="https://casier-judiciaire.justice.gouv.fr/pages/accueil.xhtml">https://casier-judiciaire.justice.gouv.fr/pages/accueil.xhtml</a> )
                        <br/><br/>
                        La lettre et le CV doivent impérativement être rédigés :
                        <ul>
                            <li>- en français pour le Togo, le Maroc, Madagascar et le Sénégal</li>
                            <li>- en espagnol pour les pays hispanophones (ou en anglais si vous êtes débutant en
                                espagnol)
                            </li>
                            <li>- en anglais pour toutes les autres destinations</li>
                        </ul>
                        <br/>
                    </Col>
                </Row>
                <Row justify="space-around" align="middle">
                    <Col span={8}>
                        <DocBloc typeDoc="CV" lib="CV"/>
                    </Col>
                    <Col span={8}>
                        <DocBloc typeDoc="LETTRE_PRESENTATION" lib="Lettre de présentation"/>
                    </Col>
                    <Col span={8}>
                        <DocBloc typeDoc="CASIER_JUDICIAIRE" lib="Casier judiciaire"/>
                    </Col>
                </Row>

                {this.state.langueDoc}
                < br/>
                <Alert
                    message="Information casier judiciaire"
                    description="Le casier judiciaire n'est pas indispensable pour cette étape du dépôt de votre candidature, mais devra être fourni avant le début de votre projet. Les autres documents (CV et Lettre de présentation) sont obligatoires."
                    type="info"
                    showIcon
                />
            </div>
        );
    }
}

export default DocsInscr;