import React from 'react';
import MenuDashboard from "./Menu";
import "./css/dashboard.css";
import HeaderMember from "./HeaderMember";
import logoGLob from "./../../img/logoglob.png"
import ListeCmd from "./ListeCmd";
import {
    UserOutlined,
    SettingFilled,
    SmileOutlined,
    SyncOutlined,
    LoadingOutlined,
    SolutionOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
    BrowserRouter as Router, Switch, Route, Link, useRouteMatch, useParams,
} from "react-router-dom";
import {
    Form, Input, Button, Checkbox, Row, Col, Typography, Divider, Steps, Tabs, Layout, Result, Badge, Alert
} from 'antd';
import OrderBlocDetails from "./OrderBlocDetails";
import axios from "axios";
import {API_URI} from "../../constant/Config";
import OrderFlight from "./OrderFlight";
import Order from "./Order";
import OrderFiles from "./OrderFiles";
import OrderDashboard from "./OrderDashboard";
import OrderTransactions from "./OrderTransactions";
import moment from "moment";
import {FooterApps} from "../order/inc/FooterApps";
import OrderFilesDispMission from "./OrderFilesDispMission";
import OrderFilesDisp from "./OrderFilesDisp";

const {Title, Text} = Typography;
const {Step} = Steps;
const {TabPane} = Tabs;

class OrderDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: Number(this.props.match.params.id), dataCmd: false, dataCmdSejour: false, listeFilesOrder: false
        }
    }

    async componentDidMount() {
        await this.getFilesOrder();
        const dataCmd = await this.getDetailsCmd()
        await this.setState({dataCmd: dataCmd})
    }

    getDetailsCmd = async () => {
        var token = localStorage.getItem('membre_token');
        let dataCmd = await axios.post(API_URI + '/api_member/details_order', {
            id: this.state.id,
        }, {
            headers: {
                'Authorization': token
            }
        })

        return dataCmd.data;
    }

    getFilesOrder = async () => {
        const listeFiles = await axios.get(API_URI + '/api_member/order-list-doc?ids=' + this.state.id);

        this.setState({listeFilesOrder: listeFiles.data})
    }

    infoNationalite = (dataCmd) => {
        if (dataCmd.membre.paysNaissance != 'FR') {
            return <Alert
                message="Règles de visa et d'immigration en vigueur"
                description="Les règles de visa et d'immigration en vigueur varient selon votre nationalité. Veuillez effectuer les démarches pour prendre connaissance des règles qui s'appliquent à votre situation. Vous pouvez également nous contacter pour en savoir plus."
                type="info"
                showIcon
            />
        } else {
            return null
        }
    }

    render() {
        if (this.state.dataCmd && this.state.listeFilesOrder) {
            if (this.state.dataCmd.data.gpCommandescolStatut === 11) {
                return (<Layout>
                    <HeaderMember/>
                    <Layout>
                        <MenuDashboard/>
                        <Layout>
                            <div className="contentBox">
                                <div className="contentBox">
                                    <Title>Mon projet : {this.state.dataCmd.sejour.pays}</Title>
                                    <Divider/>

                                    <Result
                                        title="Merci de compléter votre candidature"
                                        extra={<Link to={'/order/complete/' + this.state.id}><Button type="primary"
                                                                                                     key="console">
                                            Compléter ma candidature
                                        </Button></Link>}
                                    />

                                </div>
                            </div>
                        </Layout>
                    </Layout>
                </Layout>)
            } else {

                var suppData = this.state.dataCmd.supplements
                var retourSupp = Object.keys(suppData).map(function (key) {
                    return <div>Supplément : {suppData[key].libFull}</div>
                });


                return (<Layout>
                    <HeaderMember/>
                    <Layout>
                        <MenuDashboard/>
                        <Layout>
                            <div className="contentBox">
                                <div className="contentBox">
                                    <Title>Mon projet : {this.state.dataCmd.sejour.pays}</Title>
                                    <Divider/>
                                    <Row>
                                        <Col xs={24} sm={4} md={6} lg={8} xl={10}>
                                            <Text strong>Type de programme
                                                : </Text> {this.state.dataCmd.sejour.pays} <br/>
                                            <Text strong>Projet : </Text> {this.state.dataCmd.nomProjet}<br/>
                                            <Text strong>Statut du dossier de candidature
                                                :</Text> {this.state.dataCmd.statut.lib.lib}
                                            {retourSupp}
                                            <OrderFilesDispMission idCmd={this.state.id} dataCmd={this.state.dataCmd} />

                                        </Col>
                                        <Col xs={24} sm={4} md={6} lg={8} xl={10}>
                                            <Text strong>Date de début : </Text> <Text
                                            keyboard> {this.state.dataCmd.date_debut}</Text><br/>
                                            <Text strong>Date de fin : </Text> {this.state.dataCmd.date_fin}<br/>
                                            <Text strong>{(this.state.dataCmd.data.idCat != 3) ? 'Don de solidarité à verser' : 'Montant'} : </Text> {this.state.dataCmd.data.totalht} €<br/>
                                            <Text strong>Montant restant à verser : </Text> {this.state.dataCmd.soldeApayer} €<br/>
                                            <Text strong>Date limite de versement : </Text> {this.state.dataCmd.dateLimitePaiement}<br/>
                                            <OrderFilesDisp idCmd={this.state.id} dataCmd={this.state.dataCmd} />
                                        </Col>
                                    </Row>
                                    <br/>
                                    {this.infoNationalite(this.state.dataCmd)}

                                    <Divider/>
                                    <Tabs defaultActiveKey="5">
                                        <TabPane tab="Statut" key="5">
                                            <OrderDashboard id={this.state.id}/>
                                        </TabPane>
                                        <TabPane tab="Mes données" key="1">
                                            <OrderBlocDetails id={this.state.id}/>
                                        </TabPane>
                                        <TabPane tab={(this.state.listeFilesOrder.envoiComplet == false) ?
                                            <div><ExclamationCircleOutlined style={{fontSize: 16, color: "red"}}/>Mes
                                                documents </div> : 'Mes documents'} key="2">
                                            <OrderFiles id={this.state.id}/>
                                        </TabPane>
                                        <TabPane tab="Mes versements" key="6">
                                            <OrderTransactions id={this.state.id}/>
                                        </TabPane>
                                        <TabPane tab="Vol(s) aller" key="3">
                                            <OrderFlight id={this.state.id} type={0} dataCmd={this.state.dataCmd}/>
                                        </TabPane>
                                        <TabPane tab="Vol(s) retour" key="4">
                                            <OrderFlight id={this.state.id} type={1} dataCmd={this.state.dataCmd}/>
                                        </TabPane>

                                    </Tabs>
                                </div>
                            </div>
                        </Layout>
                    </Layout>
                    <Divider dashed/>
                    <FooterApps/>
                </Layout>)
            }

        } else {
            return (<Layout>
                <MenuDashboard/>
                <Layout>
                    <div className="contentBox">Chargement...</div>
                </Layout>
            </Layout>)
        }

    }
}

export default OrderDetail


