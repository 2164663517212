import React from "react";
import {List, Typography, Divider, Button, Alert} from 'antd';
import {Redirect} from "react-router-dom";
import axios from "axios";
import {API_URI} from "../../constant/Config";
import Moment from 'react-moment';
import 'moment-timezone';
import moment from "moment";

const {Title} = Typography;


class OrderFromSite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        }

    }

    async componentDidMount() {
        await this.resetOrder();
        await this.fromSite();
        await this.setState({redirect: true})
    }

    fromSite = async () => {
        //this.props.match.params.id


        var paysSaveSession = {
            'idPays': this.props.match.params.idPays,
            'codePays': this.props.match.params.codePays,
        }

        await localStorage.setItem("order_pays", JSON.stringify(paysSaveSession));
        await localStorage.setItem("order_idcat", this.props.match.params.idCat);
        await localStorage.setItem("order_id_sejour", this.props.match.params.idSejour);
        await localStorage.setItem("order_formSite", true);
        await localStorage.setItem("order_date_desirate", moment(this.props.match.params.orderDateDesirate).format('DD/MM/YYYY'));

        //orderDateDesirate

        //console.log(moment('2013-09-10').format('DD/MM/YYYY'))

    }

    resetOrder = async () => {
        await localStorage.removeItem('order_valStep_1');
        await localStorage.removeItem('order_idcat');
        await localStorage.removeItem('order_pays');
        await localStorage.removeItem('order_id_sejour');
        await localStorage.removeItem('order_date_desirate');
        await localStorage.removeItem('order_date_debut');
        await localStorage.removeItem('order_id_pack');
        await localStorage.removeItem('order_supp');
        await localStorage.removeItem('order_deja_contact');
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to="/order/step1/creatformsite"/>;
        } else {
            return (
                <div>Erreur veuillez contacter le support info@globalong.com</div>
            )
        }
    }
}

export default OrderFromSite;