import React from 'react';
import "./css/dashboard.css";
import {API_URI} from "../../constant/Config"
import axios from "axios";
import {Upload, Button, Select, Alert, message, Row, Col, Typography, Divider, Badge, Space, Spin, Tooltip} from 'antd';
import {UploadOutlined, ClockCircleOutlined, CheckCircleTwoTone, ExclamationOutlined} from '@ant-design/icons';

const {Option} = Select;
const {Title, Text} = Typography;

class OrderFiles extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            idCmd: this.props.id,
            idCatFile: false,
            listeFilesDefaut: false,
            loadUpload: false,
            messageAlerteError: '',
            listeFiles: [],
            zoneListe: '',
        }
    }

    async componentDidMount() {
        await this.loadListe();
        await this.getLIsteFileSejour();

        await this.listeDocDisplay();


    }

    getLIsteFileSejour = async () => {
        const listeFiles = await axios.get(API_URI + '/api_member/order-list-doc?ids=' + this.state.idCmd);

        this.setState({listeFiles: listeFiles.data.liste})
    }

    loadListe = async () => {
        const liste = await this.getFiles();

        const dataLst = liste.data.data;
        var listReturn = []
        await dataLst.map((liste) =>
            listReturn.push({
                uid: liste.idpersonFiles,
                name: liste.filesCatIdfilesCat.filesCatcolLib + ' : ' + liste.personFilescolNameFile,
                status: 'done',
                response: '', // custom error message to show
                url: 'https://crm.globalong.com/content/person/' + liste.personFilescolNameFileDrive,
                idCat: liste.filesCatIdfilesCat.idfilesCat,
                pointage: liste.personFilescolPointage,
            })
        )


        await this.setState({
            listeFilesDefaut: listReturn,
            loadUpload: true,
        })
    }

    removeFile = async (dataFile) => {


        //await this.setState({listeFilesDefaut: dataFile.fileList, messageAlerteError: ''})
        var token = localStorage.getItem('membre_token');
        const postData = {id: dataFile.uid, idCmd: this.state.idCmd}
        var reponseDel = await axios.post(API_URI + '/api_member/del_file_order', postData, {
            headers: {
                'Authorization': token
            }
        });

        if (reponseDel.data.response == 'no') {
            message.error(reponseDel.data.msg, 10);
            return false;

        }

    }

    uploadFile = async (dataFile) => {


        if (dataFile.file.status == 'uploading') {
            await this.setState({listeFilesDefaut: dataFile.fileList, messageAlerteError: ''})
            const loading = <Spin tip="Chargement...">
                <Alert
                    message="Mise à jour de vos documents"
                    description="Nous vérifions la bonne réception de vos documents."
                    type="info"
                />
            </Spin>;

            this.setState({zoneListe: loading})
        }

        if (dataFile.file.status == 'done') {

            const postData = {
                nameReel: dataFile.file.response.namefile,
                nameOnDrive: dataFile.file.name,
                selectCatIdFile: this.state.idCatFile,
                idCmd: this.state.idCmd,
            }
            var token = localStorage.getItem('membre_token');
            await axios.post(API_URI + '/api_member/add_file_order', postData, {
                headers: {
                    'Authorization': token
                }
            });
            await this.loadListe()
            await message.success('Merci votre fichier a bien été chargé.', 5);

            // maj de la col de droite sur les fichiers manquants


            this.listeDocDisplay();

        } else if (dataFile.file.status == 'removed') {
            await this.setState({listeFilesDefaut: dataFile.fileList, messageAlerteError: ''})

            // maj de la col de droite sur les fichiers manquants
            this.listeDocDisplay();
        }

    }

    getFiles = async () => {
        const postData = {
            id: this.state.idCmd,
            typeList: 'mes_documents',
        }
        var token = localStorage.getItem('membre_token');
        const res = await axios.post(API_URI + '/api_member/get_file_order', postData, {
            headers: {
                'Authorization': token
            }
        });
        return res
    }

    selectCatFileChange = (value) => {

        this.setState({
            idCatFile: value,
        })
    }

    listeDocDisplay = async () => {


        await this.getLIsteFileSejour();

        // fichier demandé pour le séjour
        var listeDefaut = this.state.listeFiles;

        // fichier chargé
        var listeLoad = this.state.listeFilesDefaut

        // cat idlib ok getAttributscolVal
        var idCatOk = [];


        for (const [key, value] of Object.entries(listeLoad)) {
            idCatOk.push(value.idCat)
        }


        const listeFileSelect = Object.keys(this.state.listeFiles).map(function (key) {
            if (idCatOk.includes(parseInt(listeDefaut[key]['getAttributscolVal']))) {
                //pointage

                if (listeDefaut[key]['pointage'] == 1) {
                    var textLib = <Tooltip title="Votre document est validé"><Text type="success"><CheckCircleTwoTone
                        twoToneColor="#52c41a"/> {listeDefaut[key]['lib']}</Text></Tooltip>;
                    var colorBadge = 'green';

                } else {
                    console.log(listeDefaut[key]['pointage'])
                    var textLib = <Tooltip title="Votre document est en attente"><Text
                        type="success"><ClockCircleOutlined twoToneColor="#52c41a"/> {listeDefaut[key]['lib']}
                    </Text></Tooltip>;
                    var colorBadge = 'blue';
                }

            } else {
                // ExclamationOutlined ClockCircleOutlined
                var textLib = <Tooltip title="Merci de charger le document"><Text
                    type="danger"><ExclamationOutlined/> {listeDefaut[key]['lib']}</Text></Tooltip>;
                var colorBadge = 'red';
            }
            return (
                <div>
                    <Badge
                        color={colorBadge}
                        text={textLib}
                    />
                    <br/>
                </div>

            )
        });

        this.setState({zoneListe: listeFileSelect})

    }

    testFileExiste = () => {

        var testFile = true

        // fichier demandé pour le séjour
        var listeDefaut = this.state.listeFiles;

        var cat = this.state.idCatFile

        // fichier chargé
        var listeLoad = this.state.listeFilesDefaut

        // cat idlib ok getAttributscolVal
        var idCatOk = [];

        for (const [key, value] of Object.entries(listeLoad)) {
            idCatOk.push(value.idCat)
        }

        //idCatFile
        const listeFileSelect = Object.keys(this.state.listeFiles).map(function (key) {
            if (idCatOk.includes(parseInt(listeDefaut[key]['getAttributscolVal'])) && listeDefaut[key]['getAttributscolVal'] == cat) {
                //console.log(listeDefaut[key]['getAttributscolVal'], 'jkojlkjkj')
                testFile = false

            }
        })

        return testFile

    }

    beforeUpload = (file) => {

        var allowUpload = true;

        var testFile = this.testFileExiste()

        if(!testFile){
            const alerteMessage = <div style={{marginBottom: 15}}>
                <Alert
                    message="Le fihier est déjà chargé"
                    description="Si vous souhaitez le charger à nouveau, il faudra le supprimer"
                    type="warning"
                    showIcon
                    closable
                />
            </div>

            this.setState({
                messageAlerteError: alerteMessage
            })

            allowUpload = false;
        }

        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (!isJpgOrPng) {
            message.error('Il faut que le fichier soit au format PDF,JPEG,PNG, DOC ou DOCX (format Word)');
            allowUpload = false;
        }
        const isLt2M = file.size / 1024 / 1024 < 35;

        if (!isLt2M) {
            message.error('Ne doit pas dépasser 35Mo !');
            allowUpload = false;
        }


        if (!this.state.idCatFile) {
            const alerteMessage = <div style={{marginBottom: 15}}>
                <Alert
                    message="Type de document non précisé"
                    description="Merci de préciser le type de document"
                    type="warning"
                    showIcon
                    closable
                />
            </div>

            this.setState({
                messageAlerteError: alerteMessage
            })

            allowUpload = false;
        }

        return allowUpload;
    }


    render() {


        if (this.state.loadUpload == false) {
            return (<div>Chargement...</div>)
        } else {
            var liste = this.state.listeFilesDefaut
            var listeFilesDate = this.state.listeFiles;

            const listeFileSelect = Object.keys(this.state.listeFiles).map(function (key) {
                return <Option
                    value={listeFilesDate[key]['getAttributscolVal']}>{listeFilesDate[key]['lib']}</Option>
            });

            return (
                <div>
                    <Row>
                        <Col span={24} xs={24} sm={24} md={10} lg={10}>
                            <Title level={3}>Charger vos documents</Title>
                            <div>
                                {this.state.messageAlerteError}
                            </div>

                            <Select
                                showSearch
                                style={{width: 500}}
                                placeholder="Type de document"
                                onChange={this.selectCatFileChange}
                            >
                                {listeFileSelect}
                                <Option value="12">Convention de stage</Option>
                                <Option value="17">Billet d'avion</Option>
                                <Option value="18">Déclaration reçu fiscal </Option>
                                <Option value="21">Visa</Option>
                                <Option value="1">Autre</Option>
                            </Select>

                            <Upload
                                action='https://crm.globalong.com/api/upload_react_interface.php'
                                onChange={this.uploadFile}
                                fileList={liste}
                                onRemove={this.removeFile}
                                beforeUpload={this.beforeUpload}
                            >
                                <Button icon={<UploadOutlined/>}>Ajouter</Button>
                            </Upload>

                        </Col>
                        <Col span={24} xs={24} sm={24} md={24} lg={24}>
                            <br />
                            <br />
                            <Title level={3}>Etat des documents déposés</Title>

                            {this.state.zoneListe}
                            <br/>
                            <br/>
                            <Divider orientation="left" plain>
                                Légende sur les états
                            </Divider>
                            <ExclamationOutlined/> Document non chargé<br/>
                            <ClockCircleOutlined twoToneColor="#52c41a"/> Document en attente de validation par
                            Globalong<br/>
                            <CheckCircleTwoTone twoToneColor="#52c41a"/> Document validé<br/>

                        </Col>
                    </Row>
                </div>
            )
        }
    }
}

export default OrderFiles