import React from 'react';

import {Link} from 'react-router-dom';
import {Divider} from "antd";



class Home extends React.Component {


    render() {


        return (
            <div>
                <h1>Retour sur le site Globalong</h1>
                <Divider />
                <a href="https://www.globalong.com">https://www.globalong.com</a>
            </div>

        );
    }
}


export default Home;
